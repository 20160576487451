<template>
  <div
    class="casual-users"
    :class="[loggedUser.role === 'SuperAdmin' && 'casual-users-super-admin']"
  >
    <div class="d-flex justify-space-between">
      <div class="d-flex">
        <h4 class="settings-h4">
          Licenses Usage: {{ activeCasuals.length }} /
          {{ casualsLicense.numOfAllowed }}
        </h4>

        <div class="pos-relative ml-10">
          <OnlineHelp
            page="casualUsers"
            section="licenses"
            title="Casual Accounts Licenses"
          />
        </div>
      </div>

      <div
        @mouseover="isShowMessage = true"
        @mouseleave="isShowMessage = false"
      >
        <Transition name="slide-fade">
          <span v-if="isShowMessage" class="mx-2 red--text fs-1r">{{
            message
          }}</span>
        </Transition>
        <Button
          :clickAction="saveCasualPage"
          :disabled="isSaveCasualUsersButtonDisabled"
          class="mx-2"
          text="Save"
        />
        <Button
          :clickAction="discardCasualChanges"
          class="mx-2"
          btnType="grey"
          text="Cancel"
        />
      </div>
    </div>
    <v-divider class="my-2"></v-divider>
    <div class="casual-users-main-content pa-2">
      <!--LICENSES TABLE -->
      <LicensesTable
        :freezedUsers="freezedUsers"
        :changes="changes"
        :casualsLicense="casualsLicense"
        :activeCasuals="activeCasuals"
        :addNewCasualUser="addNewCasualUser"
        :archivedCasuals="archivedCasuals"
      />

      <!--ALL CASUALS-->
      <AllCasualsTable
        :editCasualInActiveCasuals="editCasualInActiveCasuals"
        :changes="changes"
        :casualsLicense="casualsLicense"
        :activeCasuals="activeCasuals"
        :archivedCasuals="archivedCasuals"
        :deleteNewCasual="deleteNewCasual"
      />

      <!--ARCHIVED CASUALS-->
      <ArchivedCasuals
        :archivedCasuals="archivedCasuals"
        :computedOrgUsers="computedOrgUsers"
        :activeCasuals="activeCasuals"
        :activateCasual="activateCasual"
        :changes="changes"
        :deleteCasual="deleteCasual"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { api } from '@/config'
import { confirmDialog } from '@/utils'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Childrens
import AllCasualsTable from './Childrens/AllCasualsTable/AllCasualsTable.vue'
import LicensesTable from './Childrens/LicensesTable/LicensesTable.vue'
import ArchivedCasuals from './Childrens/ArchivedCasuals/ArchivedCasuals.vue'

//Component files
import { srartCasualUsersPageData } from './CasualUsers'
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'CasualUsers',
  data() {
    return {
      ...JSON.parse(JSON.stringify(srartCasualUsersPageData)),
    }
  },
  watch: {
    users: {
      handler: function (n) {
        if (!this.freezedUsers.length) {
          this.freezedUsers = JSON.parse(JSON.stringify(n))
        }
        this.checkCasualUsers()
      },
      deep: true,
    },

    changes: {
      handler: function (n) {
        this.checkCasualUsers()
      },
      deep: true,
    },
  },
  components: {
    LicensesTable,
    Button,
    AllCasualsTable,
    ArchivedCasuals,
    OnlineHelp,
  },
  computed: {
    ...mapGetters([
      'currentOrganization',
      'users',
      'isInMiddleOfEditing',
      'loggedUser',
    ]),

    computedOrgUsers() {
      return this.users
        .filter(user => {
          if (user.status !== 0) return false
          else if (!user.numOfAllowedCasuals) return false

          //caculate if the user can have more casual accounts
          let counter = 0
          this.activeCasuals.forEach(cas =>
            cas.addedBy.userId === user.userId ? counter++ : ''
          )
          if (counter >= user.numOfAllowedCasuals) return false

          return true
        })
        .map(el => ({
          email: el.email,
          name: el.name,
          userId: el.userId,
        }))
        .sort((a, b) => {
          a.email.toLowerCase() - b.email.toLowerCase()
        })
    },
  },

  methods: {
    ...mapMutations([
      'SET_USER',
      'SET_USERS',
      'SET_PROCESSING',
      'SET_IS_IN_MIDDLE_OF_EDITING',
      'SET_NOTIFICATION',
    ]),
    ...mapActions(['GET_SINGLE_ORGANIZATION']),
    addNewCasualUser(newCasual, bindTo) {
      const newUser = {
        ...newCasual,
        name: newCasual.firstName + ' ' + newCasual.lastName,
        organizationId: this.currentOrganization.organizationId,
        boundedUsers: [],
        isArchived: false,
      }

      //add the registered by selected
      newUser.addedBy = bindTo

      //bound the user to the casual
      newUser.boundedUsers.push(bindTo)

      const newUsers = this.users.map(user => {
        if (user.userId === newUser.addedBy.userId) {
          const { name, email } = newUser
          const newObj = {
            ...user,
          }
          newObj.boundedCasuals = [...newObj.boundedCasuals, { name, email }]
          return newObj
        }
        return user
      })
      this.SET_USERS(newUsers)
      this.changes.casuals.updatedCasuals.push(newUser)
      this.activeCasuals.push(newUser)
      this.activeCasuals = this.activeCasuals
      .sort((a, b) => {
          a.email.toLowerCase() - b.email.toLowerCase()
        })
    },
    deleteCasual(casual) {
      const text = 'Are you sure you want to delete this casual account?'
      const thenFunc = () => {
        //add the user id to the parent obj
        if (casual.casualUserId)
          this.changes.casuals.deletedCasuals.push(casual.casualUserId)
        else this.changes.casuals.deletedCasuals.push(casual.email)

        //remove from the ui
        this.archivedCasuals = this.archivedCasuals.filter(
          cas => cas.email !== casual.email
        )
      }

      confirmDialog(this, text, 'Delete', 'Cancel', thenFunc)
    },
    deleteNewCasual(newCasual) {
      const text = 'Are you sure you want to delete this casual account?'

      const thenFunc = () => {
        //delete from the ui
        this.activeCasuals = this.activeCasuals.filter(
          casual => newCasual.email !== casual.email
        )

        //delete from the changes obj
        this.changes.casuals.updatedCasuals =
          this.changes.casuals.updatedCasuals.filter(
            casual => casual.email !== newCasual.email
          )
      }
      confirmDialog(this, text, 'Delete', 'Cancel', thenFunc)
    },
    editCasualInActiveCasuals(newCasual) {
      this.activeCasuals = this.activeCasuals
        .map(casual => {
          if (casual.email === newCasual.email) {
            const newObj = {
              ...casual,
              phoneNumber: newCasual.phoneNumber,
              name: newCasual.name,
            }
            if (newCasual.isArchived) {
              this.archivedCasuals.push(newObj)
              return undefined
            }
            return newObj
          }
          return casual
        })
        .filter(el => el !== undefined)
    },

    activateCasual(editedCasual, bindedUser) {
      const activeCasual = {
        ...editedCasual,
        boundedUsers: [],
      }
      activeCasual.isArchived = false
      activeCasual.addedBy = bindedUser
      activeCasual.boundedUsers.push(bindedUser)

      //remove the casual form the archved list
      this.archivedCasuals = this.archivedCasuals.filter(
        casual => casual.email !== activeCasual.email
      )

      //add the casual to the active casuals list
      this.activeCasuals.push(activeCasual)

      //remove the casual from the changes if he is already there
      this.changes.casuals.updatedCasuals =
        this.changes.casuals.updatedCasuals.filter(
          cas => cas.email === activeCasual.email
        )

      //add the change to the updated casuals
      this.changes.casuals.updatedCasuals.push(activeCasual)

      //add to the users vuex the new bounded users of the casual
      const newArr = this.users.map(regUser => {
        if (regUser.userId === activeCasual.addedBy.userId) {
          const newObj = {
            ...regUser,
          }

          newObj.boundedCasuals.push({
            name: activeCasual.name,
            email: activeCasual.email,
            userId: activeCasual.casualUserId,
          })

          return newObj
        }

        return regUser
      })

      this.SET_USERS(newArr)

      if (editedCasual.addedBy.userId === this.loggedUser.userId) {
        const newCasual = { email: editedCasual.email, name: editedCasual.name }
        if (editedCasual.casualUserId)
          newCasual.casualUserId = editedCasual.casualUserId
        this.loggedUser.boundedCasuals.push(newCasual)
      }
    },

    async saveCasualPage() {
      try {
        this.SET_PROCESSING(true)
        const orgId = this.currentOrganization.organizationId

        //check if the added users is not deleted
        const deletedEmails = []
        this.changes.casuals.deletedCasuals =
          this.changes.casuals.deletedCasuals
            .map(el => {
              if (typeof el === 'string') {
                deletedEmails.push(el)
                return undefined
              }
              return el
            })
            .filter(el => el !== undefined)

        this.changes.casuals.updatedCasuals =
          this.changes.casuals.updatedCasuals.filter(
            casual => !deletedEmails.includes(casual.email)
          )

        const res = await api.post(`users/updateCasuals/${orgId}`, this.changes)
        if (res.status !== 200) throw Error

        //if the user is host admin then re-get the casual users list
        if (this.loggedUser.role === 'Admin') {
          const userRes = await api.get(`users/${this.loggedUser.userId}`)
          this.SET_USER({ ...userRes.data })
        }

        this.SET_NOTIFICATION({
          type: 'success',
          text: 'Casual users page was edited successfully',
        })

        this.isSaveButtonClicked = true
        this.SET_IS_IN_MIDDLE_OF_EDITING(false)

        //restart settings
        await this.getCasualsStartPage()
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
    discardCasualChanges() {
      this.SET_USERS(this.freezedUsers)
      this.$router.replace('/')
    },

    checkCasualUsers() {
      this.message = ''
      this.isSaveCasualUsersButtonDisabled = true

      //if there are no changes
      const baseChangesObj = `{"registeredLicenses":[],"casuals":{"updatedCasuals":[],"deletedCasuals":[]}}`

      if (JSON.stringify(this.changes) === baseChangesObj) {
        this.SET_IS_IN_MIDDLE_OF_EDITING(false)
        return
      } else {
        this.SET_IS_IN_MIDDLE_OF_EDITING(true)
      }

      //if there are more casual users than num of allowed minus the deleted ones
      if (
        this.activeCasuals.length - this.changes.casuals.deletedCasuals.length >
        this.casualsLicense.numOfAllowed
      ) {
        this.message = `Casual users list is bigger than number of allowed`
        return
      }

      //if total licenses are bigger than the host limit
      let totalLicenses = 0
      this.users.forEach(user => (totalLicenses += user.numOfAllowedCasuals))

      if (totalLicenses > this.casualsLicense.numOfAllowed) {
        this.message = `Total license usege exceed the allocated amount`
        return
      }

      //if some of user's licenses are smaller than the user's casuals
      const isSomeLicesesAreSmallerThanUsage = this.users.some(user => {
        let counterOfThisUsersCasuals = 0
        this.activeCasuals.forEach(casual =>
          casual.addedBy.userId === user.userId
            ? counterOfThisUsersCasuals++
            : ''
        )
        return counterOfThisUsersCasuals > user.numOfAllowedCasuals
      })

      if (isSomeLicesesAreSmallerThanUsage) {
        this.message = `License amount must be bigger than current usage`
        return
      }

      this.message = ''
      this.isSaveCasualUsersButtonDisabled = false
    },

    async getCasualsStartPage() {
      //restart the start page
      Object.keys(srartCasualUsersPageData).forEach(key => {
        this[key] = JSON.parse(JSON.stringify(srartCasualUsersPageData))[key]
      })

      this.$emit('changeTab', 'tab-casual')

      this.SET_PROCESSING(true)
      try {
        const { organizationId } = this.$route.params
        const res = await api.get(
          `users/casual/${
            this.currentOrganization.organizationId ||
            organizationId ||
            this.loggedUser.organizationId
          }`
        )
        if (res.status !== 200) throw Error

        //make the two arrays
        const activeCasuals = []
        const archivedCasuals = []

        res.data.casualUsers.forEach(casual => {
          if (casual.isArchived) archivedCasuals.push(casual)
          else activeCasuals.push(casual)
        })

        this.activeCasuals = activeCasuals
        this.archivedCasuals = archivedCasuals

        this.freezedUsers = JSON.parse(JSON.stringify(this.users))

        //if there is no current orgnaization get him from the server - BANDAGE
        if (!this.currentOrganization.allowedLicenses) {
          await this.GET_SINGLE_ORGANIZATION(this.$route.params.organizationId)
        }
        this.casualsLicense = this.currentOrganization.allowedLicenses.find(
          lic => lic.type === 'Casual'
        )
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
  },

  async mounted() {
    await this.getCasualsStartPage()
  },

  async beforeRouteLeave(to, from, next) {
    if (
      this.isInMiddleOfEditing &&
      to !== 'Home' &&
      !this.isSaveButtonClicked
    ) {
      const text =
        'You have unsaved changes.<br>Are you sure you want to leave this page without saving?'

      const thenFunc = () => {
        this.SET_USERS(this.freezedUsers)
        this.SET_IS_IN_MIDDLE_OF_EDITING(false)
        next()
      }

      const catchFunc = () => this.$emit('changeTab', 'tab-casual')

      await confirmDialog(
        this,
        text,
        'Leave Without Saving',
        'Cancel',
        thenFunc,
        catchFunc
      )

      return
    }
    //if the user quit the system
    else if (to === 'Home') {
      next()
    }
    //eleses
    next()
  },
}
</script>

<style scoped src="./CasualUsers.css"></style>
