<template>
  <div class="single-organization">
    <!--IF THERE IS ORGANIZATION-->
    <div class="organization-wrapper" v-if="currentOrganization && !loading">
      <div
        class="h-50 d-flex align-center justify-space-between"
        v-if="loggedUser.role === 'SuperAdmin'"
      >
        <div class="d-flex align-center">
          <v-img
            contain
            ref="logoImage"
            class="organization-logo"
            :src="organizationLogo"
            alt="Host logo"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <h2>{{ currentOrganization.name }}</h2>
          <span
            v-if="currentOrganization.status === 1"
            class="organization-status"
            >Host is blocked</span
          >
        </div>

        <Button text="Back" btnType="grey" :clickAction="backButtonClicked" />
      </div>

      <div class="tabs">
        <v-tabs v-model="tab" class="mb-2">
          <v-tab
            href="#tab-registered"
            @click.stop="tabClicked('registered/users')"
          >
            Registered Accounts</v-tab
          >
          <v-tab
            v-if="currentOrganization.casualUsersDefinitions.isActive"
            href="#tab-casual"
            @click.stop="tabClicked('casual-users')"
            >Casual accounts</v-tab
          >

          <v-tab
            v-if="isShowKiosks"
            href="#tab-kiosks"
            @click.stop.prevent="tabClicked('kiosks')"
            >Kiosks</v-tab
          >

          <v-tab href="#tab-settings" @click="tabClicked('settings')"
            >Settings</v-tab
          >
        </v-tabs>

        <router-view @changeTab="changeTab"></router-view>
      </div>
    </div>
    <!--IF LOADING-->
    <div v-else-if="loading">
      <!-- <v-progress-linear indeterminate color="cyan"></v-progress-linear> -->
    </div>

    <!--IF DIDNT FOUND THE HOST-->
    <div
      v-else-if="
        this.$route.params.organizationId !== 'new' &&
        !currentOrganization &&
        !loading
      "
    >
      Host is not found...
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { selectedHost } from '@/config'
import { alertDialog } from '@/utils'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import HeadingPanel from '@/components/BaseComponents/HeadingPanel/HeadingPanel.vue'

//Childrens
import OrganizationSettings from '@/components/SingleOrganization/OrganizationSettings/OrganizationSettings.vue'

export default {
  name: 'SingleOrganization',
  data() {
    return {
      tab: null,
      loading: false,
    }
  },
  components: {
    Button,
    OrganizationSettings,
    HeadingPanel,
  },
  computed: {
    ...mapGetters(['currentOrganization', 'loggedUser', 'users', 'policies']),
    organizationLogo() {
      const time = Date.now()
      return `${selectedHost}/api/images/${this.currentOrganization.organizationId}/logo?${time}`
    },
    isShowKiosks() {
      //if this is super admin
      if (!this.currentOrganization.organizationId) return false

      //check how much kiosks licenses the host have
      const kioskLicense = this.currentOrganization?.allowedLicenses.find(
        el => el.type === 'kiosks'
      )

      if (!kioskLicense) return false
      if (kioskLicense.numOfAllowed > 0) return true
      return false
    },
  },

  methods: {
    ...mapActions([
      'GET_SINGLE_ORGANIZATION',
      'GET_ROUTES',
      'GET_USERS_OF_THE_SINGLE_ORGANIZATION',
      'GET_POLICIES',
    ]),
    ...mapMutations(['SET_SINGLE_ORGANIZATION']),
    changeTab(tab) {
      this.tab = tab
    },

    backButtonClicked() {
      this.$router.push('/management/hosts')
    },
    tabClicked(tabName) {
      //if it is not the same place that the user is at
      if (
        this.$route.path ===
        `/management/hosts/${this.currentOrganization.organizationId}/${tabName}`
      )
        return

      //if the tab is for casuals and the host dosent policies for the casual
      if (tabName === 'casual-users') {
        const { policyIn, policyOut } =
          this.currentOrganization.casualUsersDefinitions
        if (!policyIn || !policyOut) {
          //if the user came from registered
          this.tab = 'settings'
          return alertDialog(
            this,
            'Policy missing for incoming and/or outgoing files from casual users. Add policies to activate casual user tab.'
          ).then(() => location.reload())
        }
      }

      //if the tab is for kiosks and the host dosent policies or SFTP users
      if (tabName === 'kiosks') {
        const isOrgHaveSftpUsers = this.users.some(
          iUser => iUser.license === 'SFTP'
        )

        if (!isOrgHaveSftpUsers) {
          return alertDialog(
            this,
            'Missing SFTP users that are required for kiosks. Add SFTP accounts to activate kiosks tab.'
          ).then(() => location.reload())
        }

        if (!this.policies.length) {
          return alertDialog(
            this,
            'Policy missing for kiosk outgoing files. Add policies to activate kiosks tab.'
          ).then(() => location.reload())
        }
      }

      //ok
      this.tab = tabName
      this.$router.push(
        `/management/hosts/${this.currentOrganization.organizationId}/${tabName}`
      )
    },
  },
  async created() {
    try {
      this.loading = true
      const { organizationId } = this.$route.params
      if (
        organizationId &&
        organizationId !== 'undefined' &&
        organizationId !== 'new'
      ) {
        if (this.loggedUser.role === 'SuperAdmin')
          await this.GET_ROUTES({ id: organizationId })
        await this.GET_SINGLE_ORGANIZATION(organizationId)
        await this.GET_POLICIES(organizationId)

        await this.GET_USERS_OF_THE_SINGLE_ORGANIZATION(organizationId)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
      //if this is a small screen - tell him that the host admin screens are not resposnive
      if (this.$vuetify.breakpoint.width < 768)
        alertDialog(
          this,
          'Your screen may be too small to display the host admin screens correctly'
        )
    }
  },
  beforeDestroy() {
    //when the super admin is no longer in organization
    if (this.loggedUser.role === 'SuperAdmin') this.SET_SINGLE_ORGANIZATION({})
  },
}
</script>

<style scoped src="./SingleOrganization.css"></style>
