<template>
  <div class="mx-auto" :id="isSuperSmallScreen && 'tabs-super-small-screen'">
    <!-- THE HEADER NOT FOR INDIVIDUAL-->
    <div v-show="loggedUser.role !== 'Individual'" class="mx-auto">
      <v-tabs
        v-model="localTab"
        :class="[(!isShowUploadingTab || !isShowDownloadingTab) && 'op-0']"
        height="40px"
        class="wrapper-1 pa-1 bg-white"
        active-class="black-border"
        hide-slider
      >
        <!--UPLOADING-->
        <v-tab v-if="isShowUploadingTab" href="#uploading">
          <div class="fs-1-25r" :class="[!isSuperSmallScreen && 'px-3']">
            {{ $t('Uploading') }}
          </div>
        </v-tab>

        <!--DOWNLOADING-->
        <v-tab v-if="isShowDownloadingTab" href="#downloading">
          <div :class="[!isSuperSmallScreen && 'px-3']" class="fs-1-25r">
            {{ computedDownloadingTitle }}
          </div>
        </v-tab>
      </v-tabs>

      <!--NOT THIS AND NOT THIS-->

      <v-dialog
        v-if="!isShowUploadingTab && !isShowDownloadingTab"
        v-model="isShowDialog"
        width="700"
        persistent
        :content-class="isDarkMode ? 'dark-mode' : ''"
      >
        <div
          class="wrapper-1 bg-white"
          :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
        >
          <v-card-title class="text-h5 bg-grey title-border">
            <b class="mx-auto">{{ $t('OOPS...') }}</b>
          </v-card-title>

          <div class="pa-2 fs-1-25r">
            {{
              $t(
                'looks like you are not part of any route but your host have the option for casual users. You can add casual users in your profile section'
              )
            }}.
          </div>

          <v-divider></v-divider>

          <div class="d-flex justify-end pa-2">
            <Button
              :text="$t('Go To Profile Section')"
              :clickAction="() => $router.push('/profile')"
              class="mx-1"
            ></Button>

            <Button
              :text="$t('Log Out')"
              btnType="grey"
              :clickAction="() => LOG_OUT()"
              class="mx-1"
            ></Button>
          </div>
        </div>
      </v-dialog>
    </div>

    <!--INDIVIDUAL SEE THE GAP-->
    <div v-show="loggedUser.role === 'Individual'" class="d-none"></div>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DownUpTabs',
  data() {
    return {
      localTab: null,
      isShowDialog: true,
    }
  },
  props: {
    tab: String,
    setTab: Function,
    isShowUploading: Boolean,
    isShowDownloading: Boolean,
  },
  watch: {
    localTab(n) {
      this.setTab(n)
    },
    tab(n) {
      this.localTab = n
    },
  },
  computed: {
    ...mapGetters(['loggedUser', 'accessibility', 'isDarkMode', 'language']),
    computedDownloadingTitle() {
      if (this.loggedUser.license === 'SFTP') return 'SFTP Viewer'
      return this.$t('Downloading')
    },
    isSuperSmallScreen() {
      if (this.$vuetify.breakpoint.width > 380) return false
      return true
    },
    isShowUploadingTab() {
      if (this.isShowUploading) return true
      if (
        this.loggedUser.isOrgHasCasuals &&
        this.loggedUser.licence === 'Website'
      )
        return true
      return false
    },
    isShowDownloadingTab() {
      if (this.isShowDownloading) return true
      if (
        this.loggedUser.isOrgHasCasuals &&
        this.loggedUser.licence === 'Website'
      )
        return true
      return false
    },
  },
  methods: {
    ...mapActions(['LOG_OUT']),
  },
  created() {
    this.localTab = this.tab
  },
  components: { Button },
}
</script>

<style>
#tabs-super-small-screen .v-tab {
  padding: 8px !important;
}
</style>
