import { validateEmail } from '@/utils'

export function checkCasualUser(vue) {
  //function to check if the casual user is valid
  vue.isSaveButtonDisabled = true
  vue.messageWhySaveButtonDisabled = ''

  // if there is no name
  if (!vue.newUser.email) {
    return (vue.messageWhySaveButtonDisabled = 'Email is required')
  }

  //if the email is not valid
  else if (!validateEmail(vue.newUser.email)) {
    return (vue.messageWhySaveButtonDisabled = 'Email must be valid')
  }

  //if email is taken
  else if (vue.usedEmails.includes(vue.newUser.email)) {
    return (vue.messageWhySaveButtonDisabled =
      'Casual or Registered account with a similar email already exists')
  }

  //if the email is at the archived section
  else if (vue.usedArchivedCasuals.includes(vue.newUser.email)) {
    return (vue.messageWhySaveButtonDisabled =
      'Archived user with that email already exists')
  }

  //if there is no first name
  else if (!vue.newUser.firstName) {
    return (vue.messageWhySaveButtonDisabled = 'First name is required')
  }
  //if there is no last name
  else if (!vue.newUser.lastName) {
    return (vue.messageWhySaveButtonDisabled = 'Last name is required')
  }
  // if there is no phone number or it is not valid
  else if (
    vue.newUser.phoneNumber.length &&
    vue.newUser.phoneNumber.length < 7 &&
    !vue.newUser.phoneNumber.includes('+')
  ) {
    return (vue.messageWhySaveButtonDisabled = 'Phone number must be valid')
  }
  vue.isSaveButtonDisabled = false
  vue.messageWhySaveButtonDisabled = ''
}
