<template>
  <!--IF THE IS REGULAR MODE -->
  <tr
    v-if="!isEditMode"
    :class="[currentOrganization.status !== 0 && 'low-op-disabeld']"
  >
    <td>{{ casualUser.name }}</td>
    <td>{{ casualUser.email }}</td>
    <td class="pos-relative">
      <div v-if="casualUser.phoneNumber">
        <div class="phone-input-hider"></div>
        <PhoneNumberInput
          :parentObject="casualUser"
          :isHideDropdown="true"
          :isHideMobileTitle="true"
          :isHideUnderline="true"
          class="mb-2"
        />
      </div>
    </td>
    <td class="text-center">
      {{ casualUser.boundedUsers.length }}
    </td>
    <td>{{ casualUser.addedBy.email }}</td>
    <td>{{ casualUser.casualUserId | generateFakeId }}</td>

    <!--CASUAL LICENSES -->
    <td>
      <div class="py-1">
        <v-checkbox
          v-model="casualUser.isAllowSend"
          disabled
          label="Send"
          class="pa-0 ma-0"
          dense
          hide-details
        >
        </v-checkbox>
        <v-checkbox
          v-model="casualUser.isAllowReceive"
          disabled
          label="Receive"
          class="pa-0 ma-0"
          dense
          hide-details
        >
        </v-checkbox>
      </div>
    </td>
    <!--BUTTONS FOR EDITING-->
    <td>
      <div class="d-flex justify-center">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="setUserInEditMode"
              :disabled="isEditDeleteActive"
              v-on="on"
              text
              icon
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit account</span>
        </v-tooltip>

        <!--ARCHIVE IF THE CASUAL USER IS NOT NEW-->
        <v-tooltip v-if="casualUser.casualUserId" top>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="archiveCasualUser"
              :disabled="isEditDeleteActive"
              v-on="on"
              text
              icon
            >
              <v-icon>{{ mdiAccountOff }}</v-icon>
            </v-btn>
          </template>
          <span>Archive account</span>
        </v-tooltip>

        <!--DELETE FROM LIST IF CASUAL USER IS NEW-->
        <v-tooltip v-else top>
          <template v-slot:activator="{ on }">
            <v-btn @click="deleteNewCasual(casualUser)" v-on="on" text icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Delete account</span>
        </v-tooltip>
      </div>
    </td>
  </tr>

  <!--IF THIS IS EDIT MODE -->
  <tr v-else class="casual-row">
    <!--CASUAL USER NAME-->
    <td>
      <v-text-field
        v-model.trim="editUserData.name"
        dense
        hide-details
      ></v-text-field>
    </td>

    <!-- CASUAL USER EMAIL-->
    <td>
      {{ editUserData.email }}
    </td>

    <!--CASUAL USER PHONE NUMBER-->
    <td>
      <PhoneNumberInput
        :parentObject="editUserData"
        :isHideDropdown="true"
        :isHideMobileTitle="true"
        class="mb-2"
      />
    </td>

    <td>
      <div class="d-flex">
        <span class="mx-2">
          {{ casualUser.boundedUsers.length }}
        </span>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon @click="isShowDialog = true" v-on="on" class="pointer"
              >mdi-dots-horizontal-circle-outline</v-icon
            >
          </template>
          <span>More</span>
        </v-tooltip>
      </div>

      <BoundedUsers
        :isShowDialog="isShowDialog"
        :casualUser="editUserData"
        :closeBoundedUsers="() => (isShowDialog = false)"
      />
    </td>

    <!--CASUAL CREATED BY-->
    <td>{{ casualUser.addedBy.email }}</td>

    <!--CASUAL USER ID-->
    <td>{{ casualUser.casualUserId | generateFakeId }}</td>

    <!--CASUAL LICENSES -->
    <td>
      <div class="py-1">
        <v-checkbox
          v-model="casualUser.isAllowSend"
          label="Send"
          class="pa-0 ma-0"
          dense
          hide-details
        >
        </v-checkbox>
        <v-checkbox
          v-model="casualUser.isAllowReceive"
          label="Receive"
          class="pa-0 ma-0"
          dense
          hide-details
        >
        </v-checkbox>
      </div>
    </td>

    <!--ACTIONS SAVE / CANCEL-->
    <td>
      <div class="d-flex">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="editCasualUser"
              :disabled="isSaveButtonDisabled"
              v-on="on"
              text
              icon
            >
              <v-icon>mdi-checkbox-marked-circle</v-icon>
            </v-btn>
          </template>
          <span>Save changes</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn @click="discardChanges" v-on="on" text icon>
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </template>
          <span>Discard changes</span>
        </v-tooltip>
      </div>
    </td>
  </tr>
</template>

<script>
import { convertID, confirmDialog } from '@/utils'
import { mapGetters, mapMutations } from 'vuex'
import { mdiAccountOff } from '@mdi/js'

//Base components
import PhoneNumberInput from '@/components/BaseComponents/PhoneNumberInput/PhoneNumberInput.vue'

//Childrens
import BoundedUsers from './Childrens/BoundedUsers/BoundedUsers.vue'

export default {
  name: 'CasualRow',
  data() {
    return {
      mdiAccountOff,
      isEditMode: false,
      freezedCasualUserData: {
        email: '',
        name: '',
        phoneNumber: '',
        casualUserId: 0,
        boundedUsers: [],
        isAllowSend: true,
        isAllowReceive: true,
      },
      isSaveButtonDisabled: false,
      editUserData: {
        email: '',
        name: '',
        phoneNumber: '',
        casualUserId: 0,
        boundedUsers: [],
        isAllowSend: true,
        isAllowReceive: true,
      },
      isShowDialog: false,
    }
  },

  components: { PhoneNumberInput, BoundedUsers },

  props: {
    casualUser: Object,
    editedUserId: Number,
    setEditedCasualUserId: Function,
    changes: Object,
    editCasualInActiveCasuals: Function,
    deleteNewCasual: Function,
  },

  watch: {
    editUserData: {
      handler: function (n) {
        if (JSON.stringify(n) !== JSON.stringify(this.freezedCasualUserData)) {
          this.SET_IS_IN_MIDDLE_OF_EDITING(true)
        } else this.SET_IS_IN_MIDDLE_OF_EDITING(false)

        //if the casual user dont have the option to send or recive - remove his bounded users
        if (!n.isAllowReceive && !n.isAllowSend && n.boundedUsers.length) {
          this.editUserData.boundedUsers = []

          const newUsers = this.users.map(user => {
            const newObj = { ...user }

            newObj.boundedCasuals = newObj.boundedCasuals.filter(
              casual => casual.email !== this.editUserData.email
            )

            return newObj
          })

          this.SET_USERS(newUsers)
        }
        this.checkEditCasualUser()
      },
      deep: true,
    },
  },
  filters: {
    generateFakeId(id) {
      const fakeID = convertID(id, 'C')
      return fakeID
    },
  },
  computed: {
    ...mapGetters([
      'loggedUser',
      'isInMiddleOfEditing',
      'users',
      'currentOrganization',
    ]),
    isEditDeleteActive() {
      if (this.currentOrganization.status !== 0) return true
      else if (!this.editedUserId) return false
      else if (this.editedUserId === this.casualUser.casualUserId) return false
      return true
    },
  },
  methods: {
    ...mapMutations([
      'SET_PROCESSING',
      'SET_IS_IN_MIDDLE_OF_EDITING',
      'SET_NOTIFICATION',
      'SET_USERS',
    ]),

    checkEditCasualUser() {
      this.isSaveButtonDisabled = true

      //if there is no change
      const casual = JSON.stringify(this.editUserData)
      const newCasual = JSON.stringify(this.freezedCasualUserData)

      if (casual === newCasual) {
        return (this.isSaveButtonDisabled = true)
      }

      //if there is no first name
      if (!this.editUserData.name) {
        return (this.isSaveButtonDisabled = true)
      }

      // if there is no phone number or it is not valid
      else if (
        this.editUserData.phoneNumber.length &&
        this.editUserData.phoneNumber.length < 7 &&
        !this.editUserData.phoneNumber.includes('+')
      ) {
        return (this.isSaveButtonDisabled = true)
      }

      this.isSaveButtonDisabled = false
    },
    setUserInEditMode() {
      this.freezedCasualUserData = JSON.parse(JSON.stringify(this.casualUser))
      this.setEditedCasualUserId(this.casualUser.casualUserId || Math.random())
      this.editUserData = this.casualUser
      this.isEditMode = true
    },
    discardChanges() {
      //discard the bounded users from the users vuex array

      //get the freezed ids
      const freezedChangedUserIdsArray =
        this.freezedCasualUserData.boundedUsers.map(el => el.userId)

      //we want to see what ids are the original ones

      const newUsersObj = this.users.map(regUser => {
        //if the user is not bounded
        if (!freezedChangedUserIdsArray.includes(regUser.userId)) {
          const newObj = {
            ...regUser,
          }
          newObj.boundedCasuals = newObj.boundedCasuals.filter(
            casual => casual.email !== this.casualUser.email
          )
          return newObj
        }

        //if the user was bounded
        else {
          const newObj = {
            ...regUser,
          }
          const { email, name } = this.casualUser
          const casual = { email, name }
          if (this.casualUser.userId) casual.userId = this.casualUser.userId

          //if the user already have the casual
          const isUserAlreadyHaveThisCasual = newObj.boundedCasuals.some(
            caUser => caUser.email === this.casualUser.email
          )
          if (!isUserAlreadyHaveThisCasual) newObj.boundedCasuals.push(casual)

          return newObj
        }
      })

      this.SET_USERS(newUsersObj)

      this.casualUser.name = this.freezedCasualUserData.name
      this.casualUser.email = this.freezedCasualUserData.email
      this.casualUser.phoneNumber = this.freezedCasualUserData.phoneNumber
      this.casualUser.casualUserId = this.freezedCasualUserData.casualUserId
      this.casualUser.boundedUsers = this.freezedCasualUserData.boundedUsers
      this.casualUser.isAllowReceive = this.freezedCasualUserData.isAllowReceive
      this.casualUser.isAllowSend = this.freezedCasualUserData.isAllowSend

      this.editUserData = {
        email: '',
        name: '',
        phoneNumber: '',
        casualUserId: 0,
        boundedUsers: [],
        isAllowReceive: true,
        isAllowSend: true,
      }

      this.setEditedCasualUserId(null)
      this.isEditMode = false
    },
    editCasualUser() {
      // if the user changes regular user
      this.changes.casuals.updatedCasuals =
        this.changes.casuals.updatedCasuals.filter(
          casual => casual.email !== this.editUserData.email
        )

      this.changes.casuals.updatedCasuals.push(this.editUserData)

      this.isEditMode = false
      this.setEditedCasualUserId(null)
    },
    archiveCasualUser() {
      let text = ''
      //if there are more than one casual users or if the bounded one is not this logged user
      if (
        this.casualUser.boundedUsers.length > 1 ||
        (this.casualUser.boundedUsers.length === 1 &&
          this.casualUser.boundedUsers[0].userId !== this.loggedUser.userId)
      )
        text = `This casual user has more than one bounded user.
                  If you archive him, the other users will not be able to transfer files with him.
                  Are you sure you want to continue?`
      //if the casual user bounded only to a one user
      else text = 'Are you sure you want to archive this casual user?'

      const thenFunc = () => {
        const newCasualObj = {
          ...this.casualUser,
          isArchived: true,
          addedBy: {
            userId: 0,
            email: '',
            name: '',
          },
          boundedUsers: [],
        }
        //add the changed casual to changes
        this.changes.casuals.updatedCasuals.push(newCasualObj)

        this.editCasualInActiveCasuals(newCasualObj)

        //remove from the users the bounded
        const newUsersObj = this.users.map(user => {
          const newUser = {
            ...user,
          }
          newUser.boundedCasuals = newUser.boundedCasuals.filter(
            casual => casual.email === this.casualUser.email
          )
          return newUser
        })

        this.SET_USERS(newUsersObj)
      }

      confirmDialog(this, text, 'Archive', 'Cancel', thenFunc)
    },
  },
}
</script>

