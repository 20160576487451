<template>
  <div class="management-content">
    <div
      v-if="isShowBlurScreen"
      @click="miniDrawer = true"
      class="blur-screen"
    ></div>
    <v-navigation-drawer
      :mini-variant="miniDrawer"
      :color="computedDrawerColor"
      dark
      width="240"
      :mini-variant-width="$vuetify.breakpoint.width < 1023 ? '6vw' : '56'"
      class="sidebar-nav"
      mobile-breakpoint="200"
      disable-resize-watcher
      :app="$vuetify.breakpoint.width < 1023 ? true : false"
    >
      <v-list>
        <!--HOST MANAGEMENT - FOR REGULAR ADMIN -->
        <v-list-item>
          <v-list-item-icon class="mr-2">
            <v-icon class="mini-drawer-toggle" @click="miniDrawer = !miniDrawer"
              >mdi-arrow-{{ miniDrawer ? 'right' : 'left' }}</v-icon
            >
          </v-list-item-icon>
        </v-list-item>

        <!--HOST MANAGEMENT - FOR REGULAR ADMIN -->
        <v-list-item
          v-if="loggedUser.role === 'Admin'"
          :to="`/management/hosts/${loggedUser.organizationId}/registered/users`"
          class="text-uppercase"
          link
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-account-tie</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Host Management</v-list-item-title>
        </v-list-item>

        <v-divider v-if="loggedUser.role === 'Admin'" class="my-4"></v-divider>

        <template v-if="loggedUser.role === 'SuperAdmin'">
          <!-- HOST LIST - FOR SUPER ADMIN-->
          <v-list-item to="/management/hosts" class="text-uppercase" link>
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-crown</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Hosts</v-list-item-title>
          </v-list-item>

          <!-- HOST LIST - FOR SUPER ADMIN-->
          <v-list-item to="/management/accounts" class="text-uppercase" link>
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Accounts</v-list-item-title>
          </v-list-item>
          <v-divider class="my-4"></v-divider>
        </template>

        <!--SYSTEM LOG -->
        <v-list-item to="/management/logs" class="text-uppercase" link>
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-list-item-icon>
          <v-list-item-title>system log</v-list-item-title>
        </v-list-item>

        <!--ANALYTICS-->
        <v-list-item v-if="false" to="/management/analytics" class="text-uppercase" link>
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-chart-bar</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Analytics</v-list-item-title>
        </v-list-item>

        <!--FILTERING LOG -->
        <v-list-item to="/management/history" class="text-uppercase" link>
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-format-list-checks</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Filtering log</v-list-item-title>
        </v-list-item>

        <!-- FILTERING POLICIES -->
        <v-list-item
          v-if="loggedUser.role === 'SuperAdmin'"
          to="/management/policies"
          class="text-uppercase"
          link
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-filter-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Filtering policies</v-list-item-title>
        </v-list-item>

        <v-divider class="my-4"></v-divider>

        <!-- GLOBAL SETTINGS FOR SUPER ADMIN-->
        <v-list-item
          v-if="loggedUser.role === 'SuperAdmin'"
          to="/management/globalSettings"
          class="text-uppercase"
          link
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-web</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Global settings</v-list-item-title>
        </v-list-item>

        <v-divider
          v-if="loggedUser.role === 'SuperAdmin'"
          class="my-4"
        ></v-divider>

        <!--SFTP INTERFACE TO SFTP HOST ADMIN (LIKE WEBSITE INTERFACE WITH DIFFRENT NAMING)-->
        <v-list-item
          v-if="isShowSFTPInterface"
          to="/sftp-interface"
          class="text-uppercase"
          link
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-arrange-send-backward</v-icon>
          </v-list-item-icon>
          <v-list-item-title>SFTP Viewer</v-list-item-title>
        </v-list-item>

        <!-- WEBSITE INTERFACE-->
        <v-list-item
          v-if="isShowWebsiteInterface"
          to="/website-interface"
          class="text-uppercase"
          link
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-arrange-send-backward</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Website Interface</v-list-item-title>
        </v-list-item>
      </v-list>

      <div v-if="!miniDrawer" class="version mb-1 fs-1-12r">
        <!--SHOW IF THE SYSTEM IS BUSY-->
        <BusyServer
          v-if="loggedUser.role === 'SuperAdmin' && false"
          :miniDrawer="miniDrawer"
        />

        <span> Version: 0.{{ CURRENT_VERSION }} </span>
      </div>
    </v-navigation-drawer>

    <!--ALL THE RIGHT SIDE OF THE PROGRAM-->
    <div
      class="content-wrapper"
      :class="[miniDrawer && 'content-wrapper-sidebar-close']"
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import metadata from '@/metadata.json'

//Childrens
import BusyServer from '@/components/SidebarManagement/BusyServer/BusyServer.vue'

export default {
  name: 'SidebarManagement',
  data() {
    return {
      miniDrawer: false,
      window,
    }
  },
  components: { BusyServer },
  computed: {
    ...mapGetters([
      'loggedUser',
      'themeSettings',
      'currentOrganization',
      'isLoggedIn',
      'isDarkMode',
    ]),

    computedDrawerColor() {
      if (this.isDarkMode) return `#000000`

      return (
        this.themeSettings.themeColor || this.currentOrganization.themeColor
      )
    },

    CURRENT_VERSION() {
      return metadata.buildRevision
    },
    isShowBlurScreen() {
      if (!this.miniDrawer && this.$vuetify.breakpoint.width < 1023) return true

      return false
    },
    isShowWebsiteInterface() {
      if (!this.loggedUser.userId) return false
      if (this.loggedUser.license === 'SFTP') return false
      if (this.loggedUser.role === 'SuperAdmin') return true
      else if (this.$store.state.routes.currentUserRoutes.length) return true
      else if (this.loggedUser.boundedCasuals.length) return true
      return false
    },

    isShowSFTPInterface() {
      if (this.loggedUser.license === 'SFTP') return true
      return false
    },
  },

  methods: {
    ...mapActions(['GET_LOGGED_USER_ROUTES']),
  },

  async created() {
    if (!this.loggedUser) this.$router.push('/')
    // const { role } = this.loggedUser
    // console.log(role)
    // if (role !== 'SuperAdmin' || role !== 'Admin') this.$router.push('/')

    try {
      if (this.$vuetify.breakpoint.width < 1023) this.miniDrawer = true
      if (this.loggedUser.role !== 'SuperAdmin')
        await this.GET_LOGGED_USER_ROUTES()
    } catch (error) {
      console.log(error)
    }
  },
}
</script>

<style scoped src="./SidebarManagement.css"></style>

<style src="./SidebarManagementUnScoped.css"></style>
