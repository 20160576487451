<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeAddCasual"
    width="500"
    class="wrapper-1 bg-white"
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <v-card class="wrapper-1">
      <v-card-title class="bg-grey mb-2">
        <span class="ma-auto"> Create New Casual User</span>
      </v-card-title>

      <v-card-text class="pa-2 overflow-auto">
        <!--CASUAL FIRST NAME-->
        <div class="d-flex my-2">
          <v-text-field
            v-model.trim="newUser.firstName"
            :disabled="currentOrganization.status !== 0"
            label="First Name"
            class="mr-2 required"
            dense
          ></v-text-field>

          <!--CASUAL LAST NAME-->
          <v-text-field
            v-model.trim="newUser.lastName"
            :disabled="currentOrganization.status !== 0"
            class="required"
            label="Last Name"
            dense
          ></v-text-field>
        </div>
        <!--CASUAL EMAIL -->
        <v-text-field
          v-model.trim="newUser.email"
          :disabled="currentOrganization.status !== 0"
          :rules="casualUserEmailRules"
          @input="cancelWhiteSpace"
          type="email"
          label="Username (email)"
          class="required"
          dense
          validate-on-blur
        ></v-text-field>

        <div class="d-flex my-2">
          <!--CASUAL PHONE NUMBER INPUT-->
          <PhoneNumberInput
            :disabled="currentOrganization.status !== 0"
            :parentObject="newUser"
            class="mx-1"
          />

          <!--CASUAL LICENSES-->
          <div class="py-1 mx-2">
            <v-checkbox
              v-model="newUser.isAllowSend"
              :disabled="currentOrganization.status !== 0"
              label="Allow send"
              class="pa-0 ma-0"
              dense
              hide-details
            >
            </v-checkbox>
            <v-checkbox
              v-model="newUser.isAllowReceive"
              :disabled="currentOrganization.status !== 0"
              label="Allow receive"
              class="pa-0 ma-0"
              dense
              hide-details
            >
            </v-checkbox>
          </div>
        </div>

        <!--BIND TO REGISTERED-->
        <div class="d-flex align-center my-2">
          <span class="mx-2">Registered user parent: </span
          ><v-text-field
            v-model="newCasualParent.email"
            disabled
            hide-details
            dense
            class="ma-0 mx-2 pa-0"
          >
          </v-text-field>
        </div>
      </v-card-text>

      <!--ACTIONS-->
      <v-divider class="my-1" />

      <!--FOOTER-->
      <div class="pa-2">
        <!--BUTTONS-->
        <div class="d-flex justify-end fill-width">
          <!--MESSAGE WHY SAVE BUTTON IS DISABLED-->
          <div class="h-18 red--text mx-2 text-end fs-1r font-weight-light">
            <Transition name="slide-fade">
              <span v-show="isShowDisabledMessage">
                {{ messageWhySaveButtonDisabled }}
              </span>
            </Transition>
          </div>

          <!--ADD CASUAL USER-->
          <div
            @mouseover="isShowDisabledMessage = true"
            @mouseleave="isShowDisabledMessage = false"
          >
            <Button
              text="Create"
              :clickAction="addNewCasualUserClicked"
              :disabled="isSaveButtonDisabled"
              width="100"
              class="mx-2"
            />
          </div>

          <!--CANCEL -->
          <Button
            :clickAction="closeAddCasual"
            text="Cancel"
            btnType="grey"
            width="100"
            class="mx-2"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { validateEmail } from '@/utils'
import { mapGetters } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import PhoneNumberInput from '@/components/BaseComponents/PhoneNumberInput/PhoneNumberInput.vue'

//Component files
import { checkCasualUser } from './AddCasual'

export default {
  name: 'AddCasual',
  data() {
    return {
      validateEmail,
      dialog: true,
      usedEmails: [],
      usedArchivedCasuals: [],
      messageWhySaveButtonDisabled: '',
      isShowDisabledMessage: false,
      isSaveButtonDisabled: true,
      newUser: {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        isAllowSend: true,
        isAllowReceive: true,
      },
    }
  },
  props: {
    newCasualParent: Object,
    closeAddCasual: Function,
    addNewCasualUser: Function,
    activeCasuals: Array,
    archivedCasuals: Array,
  },
  components: { Button, PhoneNumberInput },

  watch: {
    newUser: {
      handler: function (v) {
        checkCasualUser(this)
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['currentOrganization', 'users', 'isDarkMode']),
    casualUserEmailRules(v) {
      const vue = this
      return [
        v => !!validateEmail(v) || 'Email must be valid',
        v =>
          !vue.usedEmails.includes(v) ||
          'Casual or Registered account with a similar email already exists',
        v =>
          !vue.usedArchivedCasuals.includes(v) ||
          'Archived user with that email already exists',
      ]
    },
  },
  methods: {
    cancelWhiteSpace(e) {
      if (e) {
        this.newUser.email = this.newUser.email.replace(' ', '')
      }
    },

    addNewCasualUserClicked() {
      this.addNewCasualUser(this.newUser, this.newCasualParent)
      this.closeAddCasual()
    },
  },
  created() {
    const usedEmails = this.users.map(el => el.email)
    const usedActivedCasuals = this.activeCasuals.map(el => el.email)
    this.usedEmails = [...usedEmails, ...usedActivedCasuals]
    this.usedArchivedCasuals = this.archivedCasuals.map(el => el.email)
  },
}
</script>
