<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeActivateCasual"
    width="500"
    class="wrapper-1 bg-white"
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <v-card class="wrapper-1">
      <v-card-title class="bg-grey mb-2">
        <span class="ma-auto">Activate casual account </span>
      </v-card-title>

      <v-card-text class="pa-2">
        <!--BIND TO REGISTERED-->
        <v-select
          v-model="bindCasualToUser"
          :items="computedOrgUsers"
          item-text="email"
          label="Casual Account Parent:"
          return-object
          hide-details
          dense
          class="my-2 mx-2 pa-0"
        >
        </v-select>
      </v-card-text>

      <!--ACTIONS-->
      <v-divider class="my-1" />

      <!--BUTTONS-->
      <div class="d-flex pa-2 justify-end fill-width">
        <!--CANCEL -->
        <Button
          :clickAction="closeActivateCasual"
          text="Cancel"
          btnType="grey"
          width="100"
          class="mx-2"
        />

        <!--ACTIVATE CASUAL USER-->
        <Button
          text="Activate"
          :clickAction="activateCasualUserClicked"
          :disabled="!bindCasualToUser.userId"
          width="100"
          class="mx-2"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ActivateCasualDialog',
  data() {
    return {
      dialog: true,
      bindCasualToUser: {
        name: '',
        email: '',
        userId: 0,
      },
    }
  },
  props: {
    closeActivateCasual: Function,
    computedOrgUsers: Array,
    activateCasual: Function,
    casualToActivate: Object,
  },
  components: { Button },

  computed: {
    ...mapGetters(['isDarkMode']),
  },

  methods: {
    activateCasualUserClicked() {
      this.activateCasual(this.casualToActivate, this.bindCasualToUser)
      this.closeActivateCasual()
    },
  },
}
</script>
