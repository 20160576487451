<template>
  <!--UPLOADING DETAILS-->
  <div
    tabindex="0"
    class="wrapper-1 bg-white w-420 pos-relative"
    :class="
      isShowUploadingDetails ? 'upload-details-open' : 'upload-details-close'
    "
  >
    <!--TITLE-->
    <div
      @click="isShowUploadingDetails = !isShowUploadingDetails"
      class="d-flex pointer bg-grey pa-3"
      :class="[!isShowUploadingDetails ? 'wrapper-1 mb-2' : 'title-border']"
    >
      <v-icon
        large
        :aria-label="`${isShowUploadingDetails ? 'open' : 'close'} Settings`"
        >mdi-chevron-{{ isShowUploadingDetails ? 'down' : 'right' }}</v-icon
      >
      <h3 class="font-weight-medium fs-1-5r">{{ $t('Details') }}</h3>
    </div>

    <!--DIV ONLY FOR OVERFLOW -->
    <div
      :class="[
        $vuetify.breakpoint.height < 729 && 'overflow-small-screen-height',
      ]"
    >
      <!--CONTENT OF THE UPLOADING FIELD-->
      <Transition name="hide">
        <div v-show="isShowUploadingDetails" class="mt-4 px-2 pb-2">
          <!--UPLOADING FIELDS-->
          <UploadingDetailsFields
            :session="session"
            :userActiveSourceRoutes="userActiveSourceRoutes"
            :errors="{}"
            :lightSessionRestart="lightSessionRestart"
          />

          <!--BUTTONS TO UPLOAD FILE/FOLDER-->
          <div class="wrapper-1 my-2">
            <FileDirButtons
              :session="session"
              :lightSessionRestart="lightSessionRestart"
            />
          </div>

          <!--MESSAGE WHY DOWNLOAD BUTTON IS DISABLED-->
          <div class="red--text d-flex align-center" :class="computedHeight">
            <Transition name="slide-fade">
              <span
                v-if="isShowFilteringErrMessage"
                class="mx-auto fs-1-25r text-center"
              >
                {{ filteringErrMessage }}
              </span>
            </Transition>
          </div>
        </div>
      </Transition>

      <!--START-RESTART BUTTONS-->
      <StartRestartButtons
        :session="session"
        :filteringErrMessage="filteringErrMessage"
        :setIsShowFilteringErrMessage="v => (isShowFilteringErrMessage = v)"
        :startFilteringClicked="startFilteringClicked"
        :restartSessionClicked="restartSessionClicked"
        :setIsShowUploadingDetails="v => (isShowUploadingDetails = v)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Base components
import FileDirButtons from '@/components/WebsiteInterface/CommonComponents/FileDirButtons/FileDirButtons.vue'
import UploadingDetailsFields from '@/components/WebsiteInterface/CommonComponents/UploadingDetailsFields/UploadingDetailsFields.vue'

//Children
import StartRestartButtons from './Children/StartRestartButtons/StartRestartButtons.vue'

export default {
  name: 'UploadingDetails',
  data() {
    return {
      isShowFilteringErrMessage: false,
      isShowUploadingDetails: true,
    }
  },
  props: {
    session: Object,
    userActiveSourceRoutes: Array,
    filteringErrMessage: String,
    startFilteringClicked: Function,
    restartSessionClicked: Function,
    lightSessionRestart: Function,
  },
  watch: {
    isInMiddleOfFiltering(n) {
      if (n === true) this.isShowUploadingDetails = false
      else if (n === false) {
        this.isShowFilteringErrMessage = false
        this.isShowUploadingDetails = true
      }
    },
  },
  components: { UploadingDetailsFields, StartRestartButtons, FileDirButtons },

  computed: {
    ...mapGetters(['isInMiddleOfFiltering', 'accessibility']),
    computedHeight() {
      if (this.accessibility.fontSize === 1) return 'h-30'
      else if (this.accessibility.fontSize === 1) return 'h-50'
      else return 'h-80'
    },
  },
}
</script>

<style scoped src="./UploadingDetails.css"></style>
