<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeShownSession"
    width="450"
    class="wrapper-1 bg-white"
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <v-card class="wrapper-1">
      <v-card-title class="bg-grey fs-1-5r">
        <span class="ma-auto" tabindex="0">{{
          $t('Transference Information')
        }}</span>
      </v-card-title>

      <v-card-text
        class="pa-1 session-info-report-wrapper pa-2 fs-1-25r"
        :class="[
          accessibility.isStrongColors && 'strong-colors',
          language === 'he' ? 'dir-rtl' : 'dir-ltr',
        ]"
        tabindex="0"
      >
        <div class="fill-height d-flex flex-column overflow-auto">
          <!--START TIME-->
          <span v-if="shownSession.startTime" class="my-1"
            ><b>{{ $t('Started') }}:</b> {{ shownSession.startTime }}</span
          >

          <!--END TIME-->
          <span v-if="shownSession.endTime" class="my-1"
            ><b>{{ $t('Recived') }}:</b> {{ shownSession.endTime }}</span
          >

          <!--DURATION-->
          <span v-if="shownSession.duration" class="my-1"
            ><b>{{ $t('Duration') }}:</b>
            {{ shownSession.duration | parseTime }} (mm:ss)</span
          >

          <!--SIZE-->
          <span class="my-1"
            ><b>{{ $t('Size') }}:</b>
            {{ shownSession.size | bytesToSize }}</span
          >

          <!--ROUTE-->
          <span class="my-1"
            ><b>{{ $t('Route') }}:</b> {{ shownSession.route }}</span
          >

          <!--SUBJECT-->
          <span class="my-1"
            ><b>{{ $t(interactiveInterfaceFields.subjectFieldText) }}:</b>
            {{ shownSession.subject }}</span
          >

          <!--MESSAGE-->
          <span class="my-1"
            ><b>{{ $t(interactiveInterfaceFields.messageFieldText) }}:</b> {{ shownSession.message }}</span
          >

          <!--SENDER-->
          <span v-if="shownSession.senderEmail" class="my-1"
            ><b>{{ $t('Sender') }}:</b> {{ shownSession.senderEmail }}</span
          >

          <!--SENT TO REGISTERED-->
          <div v-if="isShowSentToRegistered" class="my-1">
            <b>{{ $t('Sent to registered accounts') }}:</b>

            <ul>
              <li v-for="user of shownSession.sentToRegistered">{{ user }}</li>
            </ul>
          </div>

          <!--SENT TO CASUALS-->
          <div v-if="isShowSentToCasuals" class="my-1">
            <!--IF THE USER DIDNT SENT TO CASUAL USERS-->
            <b>{{ $t('Sent to casual accounts') }}:</b>
            <ul>
              <li v-for="user of shownSession.sentToCasual">{{ user }}</li>
            </ul>
          </div>

          <template
            v-if="shownSession.reportSummary && loggedUser.isAllowReport"
          >
            <v-divider class="my-1" />
            <!--REPORT-->
            <div class="my-2 d-flex justify-space-between">
              <!--SUMMARY-->
              <div>
                <div class="mb-2 font-weight-bold">
                  {{ $t('Transference Results') }}
                </div>
                <Summary :statusResults="shownSession.reportSummary" />
              </div>

              <!-- <v-divider vertical class="mt-1"></v-divider> -->

              <!--FULL REPORT BUTTON-->
              <div
                v-if="false"
                class="d-flex flex-column align-center justify-center"
              >
                <!--SHOW FULL REPORT-->
                <Button
                  :text="
                    isShowFullReport
                      ? $t('Hide Filtering Report')
                      : $t('Show Filtering Report')
                  "
                  width="200"
                  btnType="grey"
                  :clickAction="fullReportClicked"
                  class="my-1"
                  btnSize="small"
                  height="25"
                />

                <!--DOWNLOAD REPORT-->
                <Button
                  :text="$t('Download Filtering Report')"
                  width="200"
                  btnType="grey"
                  height="25"
                  class="my-1"
                  btnSize="small"
                  :clickAction="downloadReportClicked"
                />
              </div>
            </div>
          </template>
          <!--REPORT ITSELF-->
          <div
            v-if="isShowFullReport"
            v-html="fullReport"
            class="wrapper-2 ma-2"
            style="max-height: 600px; overflow: auto; direction: ltr"
          ></div>
        </div>
      </v-card-text>

      <v-divider class="my-1" />

      <!--BUTTON-->
      <div class="d-flex pa-2 justify-end fill-width">
        <!--CANCEL-->
        <Button
          :clickAction="closeShownSession"
          :text="$t('Close')"
          btnType="grey"
          width="100"
          class="mx-2"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { api } from '@/config'
import { mapGetters, mapMutations } from 'vuex'
import {
  bytesToSize,
  generateLocalDateFromUTC0David,
  parseTime,
  alertDialog,
} from '@/utils'
import { DownloadReport } from '@/components/WebsiteInterface/Websiteinterface'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import Summary from '@/components/BaseComponents/Summary/Summary.vue'

export default {
  name: 'ShownSessionDialog',
  data() {
    return {
      dialog: true,
      fullReport: null,
      isShowFullReport: false,
    }
  },
  props: {
    closeShownSession: Function,
    shownSession: Object,
  },

  components: {
    Button,
    Summary,
  },

  filters: {
    bytesToSize,
    generateLocalDateFromUTC0David,
    parseTime,
  },

  computed: {
    ...mapGetters([
      'loggedUser',
      'accessibility',
      'language',
      'isDarkMode',
      'interactiveInterfaceFields',
    ]),
    isShowSentToCasuals() {
      if (this.loggedUser.role === 'SuperAdmin') return false
      //if there is no start time then it is recived and the user cannot see who else got the files
      else if (!this.shownSession.startTime) return false
      else if (this.loggedUser.isOrgHasCasuals) return true
      return true
    },
    isShowSentToRegistered() {
      if (this.loggedUser.role === 'SuperAdmin') return false
      //if there is no start time then it is recived and the user cannot see who else got the files
      else if (!this.shownSession.startTime) return false

      return true
    },
  },

  methods: {
    ...mapMutations(['SET_PROCESSING']),
    async fullReportClicked(isOpenShowReport = true) {
      //close the report
      if (this.isShowFullReport) return (this.isShowFullReport = false)

      try {
        this.SET_PROCESSING(true)

        //some api req to get report or some like that
        const res = await api.get(
          `users/report?operationId=${this.shownSession.sessionId}`
        )

        //if there is no report to show
        if (res.status === 204) {
          return alertDialog(this, 'Report for this session is not available')
        }

        if (res.status !== 200) throw Error

        //if everything is okay
        this.fullReport = res.data

        //if the user clicked show full report
        if (isOpenShowReport) this.isShowFullReport = true
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },

    async downloadReportClicked() {
      //if there is no report try to get it
      if (!this.fullReport) await this.fullReportClicked(false)

      //if there is report download it
      const reportObj = {
        summary: this.fullReport,
        date: this.shownSession.startTime,
      }
      if (this.fullReport) DownloadReport(reportObj)
    },
  },
}
</script>

<style scoped src="./ShownSessionDialog.css"></style>
