<template>
  <div
    :class="[isShowAdvancedSettings ? 'wrapper-2' : 'wrapper-1']"
    class="logs-header pa-2 bg-white pos-relative"
  >
    <!--FIRST PART-->
    <div class="d-flex justify-space-between">
      <!--MAIN SETTINGS-->
      <MainSettings
        :logsSettings="logsSettings"
        :search="search"
        :organizationsList="organizationsList"
        :setIsShowAdvancedSettings="setIsShowAdvancedSettings"
        :isShowAdvancedSettings="isShowAdvancedSettings"
      />
    </div>

    <!--SECOND PART - ADVANCED-->
    <AdvancedSettings
      v-show="isShowAdvancedSettings"
      :search="search"
      :organizationUsersList="organizationUsersList"
      :logsSettings="logsSettings"
      :logsTableHeaders="logsTableHeaders"
    />

    <!--BUTTON FOR SEARCH AND MESSAGE-->
    <div class="d-flex justify-center mt-2 search-buttons">
      <!--MESSAGE WHY BUTTON IS DISABLED-->

      <Transition name="slide-fade">
        <span
          v-show="isShowDisabledMessage && !search.startDate"
          class="red--text fs-16 my-auto mx-2"
        >
          Start date is required
        </span>
      </Transition>

      <!--SEARCH-->
      <div class="d-flex pos-relative">
        <div
          @mouseover="isShowDisabledMessage = true"
          @mouseleave="isShowDisabledMessage = false"
        >
          <Button
            text="Search"
            icon="mdi-magnify"
            width="120"
            class="ma-1 mr-9"
            :clickAction="searchLogsButtonClicked"
            :disabled="isSearchButtonDisabled"
          />
        </div>

        <OnlineHelp
          page="systemLogs"
          :section="isShowAdvancedSettings ? 'advancedSettings' : 'settings'"
          :title="
            isShowAdvancedSettings
              ? 'System Logs Advanced Settings'
              : 'System Logs Settings'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  getUTCdateFromLocalDateDavid,
  generateLocalDateFromUTC0NoMS,
} from '@/utils'
import { api } from '@/config'
import { mapActions, mapGetters, mapMutations } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Childrens
import MainSettings from './Children/MainSettings/MainSettings.vue'
import AdvancedSettings from './Children/AdvancedSettings/AdvancedSettings.vue'
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'LogsHeader',
  data() {
    return {
      organizationUsersList: [],
      organizationsList: [],
      isShowDisabledMessage: false,
    }
  },
  props: {
    search: Object,
    searchLogsButtonClicked: Function,
    logsTableHeaders: Object,
    logsSettings: Object,
    isShowAdvancedSettings: Boolean,
    setIsShowAdvancedSettings: Function,
  },
  components: { Button, MainSettings, AdvancedSettings, OnlineHelp },
  watch: {
    'search.organizationId': {
      handler: async function (n) {
        this.search.userEmail = null
        if (n === undefined) this.search.organizationId = null
        if (!n) return
        //get the users of the orgainzation that just changed
        try {
          this.SET_PROCESSING(true)

          let usersList = []
          //if the logged user is SUPER ADMIN

          if (this.loggedUser.role === 'SuperAdmin')
            usersList = this.organizationsList.find(
              el => el.organizationId === this.search.organizationId
            ).users
          //if the logged user is REGULAR ADMIN
          else {
            const res = await api.get(`users/getalllogusers`)
            if (res.status !== 200) throw Error
            usersList = res.data
          }

          this.organizationUsersList = usersList
            .map(el => `${el.userEmail} ${el.isCasual ? '(Casual)' : ''}`)
            .sort((a, b) => {
              if (a.toLowerCase() < b.toLowerCase()) return -1
              if (a.toLowerCase() > b.toLowerCase()) return 1
              return 0
            })
        } catch (e) {
          console.log(e)
        } finally {
          this.SET_PROCESSING(false)
        }
      },
      deep: true,
    },
    'search.userEmail': {
      handler: function (n) {
        if (n === undefined) this.search.userEmail = null
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['loggedUser', 'users']),
    isSearchButtonDisabled() {
      if (!this.search.startDate) return true
      else if (!this.search.endDate) return true
      //if the end date is before the start date
      const startDate = new Date(
        dayjs(this.search.startDate).format('MM/DD/YYYY HH:mm')
      )
      const endDate = new Date(
        dayjs(this.search.endDate).format('MM/DD/YYYY HH:mm')
      )
      if (startDate > endDate) return true
      return false
    },
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),
    ...mapActions(['GET_USERS_OF_THE_SINGLE_ORGANIZATION']),
  },
  async created() {
    if (this.loggedUser.role !== 'SuperAdmin')
      this.search.organizationId = this.loggedUser.organizationId
    try {
      this.SET_PROCESSING(true)
      this.search.endDate = dayjs().format('YYYY-MM-DD HH-mm')

      //get the orgazinations for super admin
      if (this.loggedUser.role === 'SuperAdmin') {
        const res = await api.get('organizations/getallorgnames')
        if (res.status !== 200) throw Error
        this.organizationsList = res.data
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.SET_PROCESSING(false)
    }
  },
}
</script>

<style scoped src="./LogsHeader.css"></style>

<style src="./LogsHeaderUnScoped.css"></style>
