<template>
  <div class="licenses-table-wrapper">
    <v-text-field
      v-if="computedUsers.length > 10"
      v-model="registerSearch"
      class="mw-200 ma-0 pa-0 mb-2"
      placeholder="Search registered account"
      hide-details
      clearable
    ></v-text-field>

    <v-data-table
      class="wrapper-1"
      :headers="licensesTableHeaders"
      :items="computedUsers"
      :search="registerSearch"
      :options="{ sortBy: ['email'] }"
      must-sort
      dense
    >
      <template v-slot:item="{ item }">
        <tr
          :class="[
            getUserClass(item),
            currentOrganization.status !== 0 && 'low-op-disabeld',
          ]"
        >
          <!--CASUAL EMAIL-->
          <td>{{ item.email }}</td>

          <!--LICNENSES-->
          <td>
            <div class="d-flex justify-center">
              <span class="f-16">{{ getNumOfUserCasuals(item) }} / </span>
              <v-text-field
                :value="item.numOfAllowedCasuals"
                :rules="computedCasualsRules(item)"
                @input="numOfAllowedCasualsChanged(item, $event)"
                @blur="blurOnChangeRegLicenses($event, item)"
                :disabled="currentOrganization.status !== 0"
                type="number"
                hide-details
                dense
                height="20px"
                class="centered-input mw-30 pa-0 ma-0"
              ></v-text-field>
            </div>
          </td>

          <!--ADD TO THIS USER CASUAL ACCOUNT-->
          <td>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="openAddCasual(item)"
                  v-show="isShowAddCasual(item)"
                  v-on="on"
                  >mdi-account-plus</v-icon
                >
              </template>
              <span>Add casual account</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div class="pos-relative license-online-help-wrapper">
      <OnlineHelp
        page="casualUsers"
        section="registeredAccounts"
        title="Registered Accounts"
      />
    </div>

    <!--ADD CASUAL DIALOG-->
    <AddCasual
      v-if="newCasualParent"
      :newCasualParent="newCasualParent"
      :closeAddCasual="() => (newCasualParent = null)"
      :addNewCasualUser="addNewCasualUser"
      :activeCasuals="activeCasuals"
      :archivedCasuals="archivedCasuals"
    />
  </div>
</template>

<script>
/*NEED REBUILD!!!*/
import { mapGetters, mapMutations } from 'vuex'

//Childrens
import AddCasual from './Childrens/AddCasual/AddCasual.vue'

//Component files
import { licensesTableHeaders } from './LicensesTable'
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'LicensesTable',
  data() {
    return {
      licensesTableHeaders,
      errorItem: [],
      newCasualParent: null,
      registerSearch: '',
    }
  },
  props: {
    changes: Object,
    freezedUsers: Array,
    casualsLicense: Object,
    activeCasuals: Array,
    archivedCasuals: Array,
    addNewCasualUser: Function,
  },
  components: { AddCasual, OnlineHelp },
  computed: {
    ...mapGetters(['users', 'currentOrganization']),
    computedUsers() {
      //return only the active users
      return this.users.filter(
        el =>
          el.status === 0 && (el.license === 'Website' || el.license === 'SFTP')
      )
    },
  },
  methods: {
    ...mapMutations(['SET_USERS']),
    isShowAddCasual(user) {
      //if there are no casual licenses
      if (user.numOfAllowedCasuals < 1) return false
      //caculate if the user can have more casual accounts
      let counter = 0
      this.activeCasuals.forEach(cas =>
        cas.addedBy.userId === user.userId ? counter++ : ''
      )
      if (counter >= user.numOfAllowedCasuals) return false
      return true
    },
    blurOnChangeRegLicenses(v, user) {
      if (v.target.value === '') {
        //find the user that changed and replace the old data with the new
        const newUsersList = this.users.map(stateUser => {
          if (stateUser.userId === user.userId)
            return {
              ...stateUser,
              numOfAllowedCasuals: 0,
            }
          return stateUser
        })
        this.SET_USERS(newUsersList)
      }
    },
    openAddCasual(registered) {
      const { email, name, userId } = registered
      this.newCasualParent = { email, name, userId }
    },
    //REBUILD ALL THIS METHODS
    getUserClass(user) {
      let counterOfUserCasuals = 0
      this.activeCasuals.forEach(casual =>
        casual.addedBy.userId === user.userId && !casual.isDeleted
          ? counterOfUserCasuals++
          : ''
      )
      if (user.numOfAllowedCasuals < counterOfUserCasuals) return 'red--text'
      return ''
    },
    getNumOfUserCasuals(user) {
      let numOfCasuals = 0
      this.activeCasuals.forEach(casual => {
        return casual.addedBy.userId === user.userId && !casual.isDeleted
          ? numOfCasuals++
          : ''
      })
      return numOfCasuals
    },
    computedCasualsRules(user) {
      let numOfCasuals = 0
      this.activeCasuals.forEach(casual =>
        casual.addedBy.userId === user.userId && !casual.isDeleted
          ? numOfCasuals++
          : ''
      )
      if (numOfCasuals > user.numOfAllowedCasuals) return ['error']
      else return []
    },
    numOfAllowedCasualsChanged(user, num) {
      const e = +num
      //check if there is a change
      const freezedNumOfAllowed = this.freezedUsers.find(
        el => el.userId === user.userId
      ).numOfAllowedCasuals
      if (freezedNumOfAllowed === e) {
        this.changes.registeredLicenses =
          this.changes.registeredLicenses.filter(
            el => el.userId !== user.userId
          )
        return
      }

      //find the user that changed and replace the old data with the new
      const newUsersList = this.users.map(stateUser => {
        if (stateUser.userId === user.userId)
          return {
            ...stateUser,
            numOfAllowedCasuals: e || 0,
          }
        return stateUser
      })
      this.SET_USERS(newUsersList)

      const obj = {
        userId: user.userId,
        numOfLicenses: e,
      }

      this.changes.registeredLicenses = this.changes.registeredLicenses.filter(
        el => el.userId !== user.userId
      )
      this.changes.registeredLicenses.push(obj)
    },
  },
}
</script>

<style scoped src="./LicensesTable.css"></style>
