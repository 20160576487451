<template>
  <v-data-table
    :headers="getRegularUserCasualUsersTableHeaders"
    :items="computedActiveCasualUsers"
    :options="{ sortBy: ['casualUserId'] }"
    class="wrapper-1 ma-2"
    :footer-props="{
      'items-per-page-text': $t('Rows per page') + ':',
      pageText: `{0}-{1} ${$t('of')} {2}`,
      'items-per-page-all-text': $t('All'),
    }"
  >
    <!--BOUND - NOT BOUND-->
    <template v-slot:item.isBounded="{ item }">
      <v-checkbox
        :input-value="caculateIsBound(item)"
        @change="isBoundChanged($event, item)"
        hide-details
        class="ma-0 pa-0 ml-2"
      ></v-checkbox>
    </template>

    <!--PHONE NUMBER-->
    <template v-slot:item.phoneNumber="{ item }">
      <div v-if="item.phoneNumber">
        <div class="pos-relative">
          <div class="phone-input-hider"></div>
          <PhoneNumberInput
            :parentObject="item"
            :isHideDropdown="true"
            :isHideMobileTitle="true"
            :isHideUnderline="true"
            class="mb-2"
          />
        </div>
      </div>
    </template>

    <!--CASUAL USER ID-->
    <template v-slot:item.casualUserId="{ item }">
      {{ convertID(item.casualUserId, 'C') }}
    </template>

    <!--DIRECTION-->
    <template v-slot:item.direction="{ item }">
      {{ getDirections(item) }}
    </template>

    <!--ICONS-->
    <template v-slot:item.icons="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text icon @click="() => setEditedCasual(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Edit') }}</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { convertID } from '@/utils'
import { mapGetters } from 'vuex'
import { regularUserCasualUsersTableHeaders } from './ActiveCasuals'
import PhoneNumberInput from '@/components/BaseComponents/PhoneNumberInput/PhoneNumberInput.vue'

export default {
  name: 'ActiveCasuals',
  props: {
    computedActiveCasualUsers: Array,
    isBoundChanged: Function,
    setEditedCasual: Function,
  },
  computed: {
    ...mapGetters(['loggedUser', 'language']),
    getRegularUserCasualUsersTableHeaders() {
      return regularUserCasualUsersTableHeaders(this)
    },
  },
  methods: {
    convertID,
    getDirections(casualUser) {
      if (casualUser.isAllowReceive && casualUser.isAllowSend)
        return this.$t(`Send & Recive`)
      else if (casualUser.isAllowReceive && !casualUser.isAllowSend)
        return this.$t('Recive')
      else if (!casualUser.isAllowReceive && casualUser.isAllowSend)
        return this.$t('Send')
      else return this.$t('None')
    },
    caculateIsBound(casualUser) {
      for (const regUser of casualUser.boundedUsers) {
        if (regUser.userId === this.loggedUser.userId) return true
      }
      return false
    },
  },
  components: { PhoneNumberInput },
}
</script>
