const emailDefinitionsSettingsKeys = ['emailProtocol', 'displaySenderName']

const msGraphEmailSettingsKeys = [
  'clientId',
  'tenantId',
  'clientSecret',
  'senderEmail',
]

const smtpEmailSettingsKeys = [
  'server',
  'senderEmail',
  'port',
  'login',
  'password',
  'encrypt',
]

function checkIfTestIsNeeded(vue, newVal, isBackup = false) {
  const emailDefinitions = isBackup
    ? newVal.backupEmailDefinitions
    : newVal.emailDefinitions
  const frozenDefinitions = isBackup
    ? vue.freezedOrganization.backupEmailDefinitions
    : vue.freezedOrganization.emailDefinitions
  const smtpTestKey = isBackup ? 'isBackupSmtpTest' : 'isSmtpTest'

  for (let set of emailDefinitionsSettingsKeys) {
    if (emailDefinitions[set] !== frozenDefinitions[set]) {
      vue[smtpTestKey] = false
      return
    }
  }

  for (let set of msGraphEmailSettingsKeys) {
    if (
      emailDefinitions.msGraphDefinitions[set] !==
      frozenDefinitions.msGraphDefinitions[set]
    ) {
      vue[smtpTestKey] = false
      return
    }
  }

  for (let set of smtpEmailSettingsKeys) {
    if (
      emailDefinitions.smtpDefinitions[set] !==
      frozenDefinitions.smtpDefinitions[set]
    ) {
      vue[smtpTestKey] = false
      return
    }
  }
}

export function checkIfSmtpTestIsNeeded(vue, newVal) {
  checkIfTestIsNeeded(vue, newVal)
}

export function checkIfBackupSmtpTestIsNeeded(vue, newVal) {
  checkIfTestIsNeeded(vue, newVal, true)
}

export const startobjHostSettings = {
  loading: false,
  isDataLoaded: false,
  message: '',
  isSettingsEqual: true,
  isSaveButtonDisabled: true,
  actionType: '',
  freezedOrganization: null,
  organization: {},
  isSmtpTest: true,
  isBackupSmtpTest: true,
  images: {
    logo: null,
    background: null,
  },
}
