<template>
  <div class="d-flex justify-space-between flex-wrap">
    <!--LEFT SIDE-->
    <div>
      <!--CASUALS HOST STATISTICS-->
      <h4 class="settings-h4 mx-2 mb-2 fs-1r">
        {{ $t('My Casual Licenses Usage') }}:
        {{ counterOfusersCasuals + '/' + loggedUser.numOfAllowedCasuals }}
      </h4>

      <!--BUTTON TO ADD NEW CASUAL-->
      <Button
        :clickAction="() => setIsShowAddCasualDialog(true)"
        :disabled="counterOfusersCasuals >= loggedUser.numOfAllowedCasuals"
        class=""
        :text="$t('Create New Casual User')"
        icon="mdi-account-plus"
      />
    </div>
    <!--RIGHT SIDE - SAVE & CANCEL-->
    <div class="pa-1 mt-3">
      <Button
        :clickAction="saveCasualUsersPage"
        :disabled="isSaveButtonDisabled"
        class="mx-2"
        :text="$t('Save')"
      />
      <Button
        :clickAction="cancelButtonClicked"
        class="mx-2"
        :text="$t('Cancel')"
        btnType="grey"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { confirmDialog } from '@/utils'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'CasualProfileHeader',
  props: {
    setIsShowAddCasualDialog: Function,
    counterOfusersCasuals: Number,
    cameFrom: String,
    saveCasualUsersPage: Function,
    changes: Object,
  },
  components: { Button },

  computed: {
    ...mapGetters(['loggedUser', 'isInMiddleOfEditing']),
    isSaveButtonDisabled() {
      //no changes ata ll
      if (
        JSON.stringify(this.changes) ===
        '{"casuals":{"deletedCasuals":[],"updatedCasuals":[]},"registeredLicenses":[]}'
      ) {
        this.SET_IS_IN_MIDDLE_OF_EDITING(false)

        return true
      }
      //if there are changes
      else {
        this.SET_IS_IN_MIDDLE_OF_EDITING(true)

        return false
      }
    },
  },

  methods: {
    ...mapMutations(['SET_IS_IN_MIDDLE_OF_EDITING']),
    cancelButtonClicked() {
      if (this.isInMiddleOfEditing) {
        const text =
          this.$t(
            'You have unsaved changes. Are you sure you want to leave this page without saving'
          ) + '?'
        const thenFunc = () => this.$router.push(this.cameFrom)

        confirmDialog(
          this,
          text,
          this.$t('Leave Without Saving'),
          this.$t('Cancel'),
          thenFunc
        )
      }

      //Nothing edited
      else this.$router.push(this.cameFrom)
    },
  },
}
</script>
