<template>
  <div class="pos-relative">
    <span
      :class="[
        'mobile-label',
        actionType === 'add' && 'required-field',
        isPhoneNumberActive && 'mobile-label-active',
        disabled && 'low-op-disabeld',
        isHideMobileTitle && 'op-0',
      ]"
    >
      {{ $t('Mobile') }}
    </span>
    <vue-tel-input
      v-model="parentObject.phoneNumber"
      validCharactersOnly
      disabledFetchingCountry
      :wrapperClasses="[
        isHideDropdown && 'phone-input-without-dropdown',
        'phone-wrapper',
      ]"
      :style="{ width: width }"
      :inputClasses="[
        isPhoneNumberActive ? 'phone-input-active' : 'phone-input',
        isHideUnderline && 'no-underline ',
      ]"
      :disabled="disabled"
      :inputOptions="inputOptions"
      mode="international"
      :placeholder="placeholder ? placeholder : 'Enter phone number'"
      @focus="() => (isPhoneNumberActive = true)"
      @blur="() => (isPhoneNumberActive = false)"
      defaultCountry="nn"
    ></vue-tel-input>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'

export default {
  name: 'PhoneNumberInput',
  data() {
    return {
      isPhoneNumberActive: false,
      inputOptions: {
        showDialCode: true,
      },
    }
  },
  props: {
    placeholder: String,
    parentObject: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    actionType: String,
    isHideDropdown: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '250px',
    },
    isHideMobileTitle: {
      type: Boolean,
      default: false,
    },
    isHideUnderline: {
      type: Boolean,
      default: false,
    },
  },
  components: { VueTelInput },
}
</script>

<style src="./PhoneNumberInput.css"></style>
