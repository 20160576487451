<template>
  <div>
    <div class="d-flex">
      <!--BUTTON TO OPEN / CLOSE ARCHIVED-->
      <Button
        :text="
          isArchivedOpen ? 'Hide Archived Casuals' : 'Show Archived Casuals'
        "
        width="230px"
        :icon="isArchivedOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        btnType="grey"
        :clickAction="() => (isArchivedOpen = !isArchivedOpen)"
        class="mb-1"
      />

      <div class="pos-relative ml-10">
        <OnlineHelp
          page="casualUsers"
          section="archivedCasuals"
          title="Archived Casual Accounts"
        />
      </div>
    </div>

    <Transition name="slide-fade">
      <div v-if="isArchivedOpen" class="my-2">
        <h4 class="settings-h4 my-2">Archived Casual Accounts</h4>
        <v-data-table
          class="wrapper-1"
          :items="archivedCasuals"
          :headers="archivedCasualsHeaders"
          :options="{ sortBy: ['casualUserId'] }"
          must-sort
        >
          <template v-slot:item="{ item }">
            <ArchicedCasualRow
              :casual="item"
              :setCasualToActivate="v => (casualToActivate = v)"
              :archivedCasuals="archivedCasuals"
              :activeCasuals="activeCasuals"
              :deleteCasual="deleteCasual"
            />
          </template>
        </v-data-table>
      </div>
    </Transition>

    <!--DIALOG TO ACTIVATE CASUAL-->
    <ActivateCasualDialog
      v-if="casualToActivate"
      :casualToActivate="casualToActivate"
      :closeActivateCasual="v => (casualToActivate = null)"
      :computedOrgUsers="computedOrgUsers"
      :activateCasual="activateCasual"
    />
  </div>
</template>

<script>
//Childrens
import ArchicedCasualRow from './Childrens/ArchivedCasualRow/ArchivedCasualRow.vue'
import ActivateCasualDialog from './Childrens/ActivateCasualDialog/ActivateCasualDialog.vue'

//Component files
import { archivedCasualsHeaders } from './ArchivedCasuals'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'ArchivedCasuals',
  data() {
    return {
      archivedCasualsHeaders,
      isArchivedOpen: false,
      casualToActivate: null,
    }
  },
  props: {
    archivedCasuals: Array,
    computedOrgUsers: Array,
    activeCasuals: Array,
    activateCasual: Function,
    changes: Object,
    deleteCasual: Function,
  },

  components: {
    ArchicedCasualRow,
    Button,
    ActivateCasualDialog,
    OnlineHelp,
  },
}
</script>
