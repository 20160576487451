<template>
  <v-dialog
    v-model="isShowDialog"
    @click:outside="closeBoundedUsers"
    width="500"
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <div class="wrapper-1 bg-white">
      <v-card-title class="text-h5 bg-grey title-border">
        <b>{{ casualUser.name }} bounded users</b>
      </v-card-title>

      <v-data-table
        :headers="boundedUsersHeader"
        :items="computedBoundedUsers"
        hide-default-footer
        class="wrapper-1 ma-2"
        :items-per-page="-1"
        must-sort
        :options="{ sortBy: ['email'] }"
      >
        <template v-slot:item.bound="{ item }">
          <v-simple-checkbox
            class="ml-2"
            :value="item.isBound"
            @input="userCheckboxClicked(item, $event)"
            :disabled="!casualUser.isAllowSend && !casualUser.isAllowReceive"
          ></v-simple-checkbox>
        </template>
      </v-data-table>

      <v-divider></v-divider>

      <div class="d-flex justify-end pa-2">
        <Button
          text="Close"
          btnType="grey"
          :clickAction="closeBoundedUsers"
        ></Button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Component files
import { boundedUsersHeader } from './BoundedUsers'

export default {
  data() {
    return {
      boundedUsers: [],
      boundedUsersHeader,
    }
  },
  name: 'BoundedUsers',
  props: {
    isShowDialog: Boolean,
    casualUser: Object,
    closeBoundedUsers: Function,
  },
  components: {
    Button,
  },
  watch: {
    users: {
      handler: function (n) {
        this.boundedUsers = n.map(user => {
          const newObj = {
            status: user.status,
            name: user.name,
            userId: user.userId,
            email: user.email,
          }

          //check if the casual is bpund
          newObj.isBound = user.boundedCasuals.some(casual => {
            return casual.email === this.casualUser.email
          })

          return newObj
        })
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['users', 'isDarkMode']),
    computedBoundedUsers() {
      return this.boundedUsers.filter(el => el.status === 0)
    },
  },

  methods: {
    ...mapMutations(['SET_USERS']),
    userCheckboxClicked(user, e) {
      //if the checkbox become true
      if (e) {
        //bound the user to the casual
        this.casualUser.boundedUsers.push({
          email: user.email,
          name: user.name,
          userId: user.userId,
        })

        //bound the casual to the user
        const newUsers = this.users.map(oldUser => {
          if (oldUser.userId === user.userId) {
            const newUser = {
              ...oldUser,
            }
            const { name, email, casualUserId } = this.casualUser
            const newCasual = {
              name,
              email,
              userId: casualUserId,
            }
            newUser.boundedCasuals.push(newCasual)
            return newUser
          }
          return oldUser
        })

        this.SET_USERS(newUsers)
      }

      //if the checkbox become false
      else {
        this.casualUser.boundedUsers = this.casualUser.boundedUsers.filter(
          regularUser => regularUser.userId !== user.userId
        )

        //unbound the casual from the user
        const newUsers = this.users.map(regularUser => {
          if (regularUser.userId === user.userId) {
            const newObj = {
              ...regularUser,
            }
            newObj.boundedCasuals = newObj.boundedCasuals.filter(
              casual => casual.userId !== this.casualUser.casualUserId
            )

            return newObj
          }
          return regularUser
        })
        this.SET_USERS(newUsers)
      }

      //add in the ui the change
      this.boundedUsers = this.boundedUsers.map(regularUser => {
        if (regularUser.userId === user.userId) {
          return {
            ...regularUser,
            isBound: e,
          }
        }
        return regularUser
      })
    },
  },
  mounted() {
    this.boundedUsers = this.users.map(user => {
      const newObj = {
        status: user.status,
        name: user.name,
        userId: user.userId,
        email: user.email,
      }

      //check if the casual is bpund
      newObj.isBound = user.boundedCasuals.some(casual => {
        return casual.email === this.casualUser.email
      })

      return newObj
    })
  },
}
</script>
