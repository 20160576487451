<template>
  <div
    v-if="isDataLoaded"
    class="organization-settings-wrapper pos-relative"
    :class="getOrgSettingsWrapperClass"
  >
    <SaveCancelButtons
      :message="message"
      :isSaveButtonDisabled="isSaveButtonDisabled"
      :changeOrganizationStatus="changeOrganizationStatus"
      :loading="loading"
      :organization="organization"
      :actionType="actionType"
      @changeTab="v => $emit('changeTab', v)"
    />
    <div class="pos-relative fill-height overflow-auto">
      <!--TAKE THE BUTTONS TO COMPONENT-->

      <!--EDIT DETAILS -->
      <EditDetails
        v-if="computedIsShowEditDetails"
        :parentObj="organization"
        editOf="organization"
        class="top-5"
      />

      <!--THEME SETTINGS-->
      <ThemeSettings
        :organization="organization"
        :status="organization.status"
        :actionType="actionType"
        :images="images"
        :class="[loggedUser.role === 'SuperAdmin' && 'mt-8']"
      />

      <!--CONCURRENT LICENSES-->
      <OrgDefinitions :organization="organization" :actionType="actionType" />

      <!--ADD POLICY - FOR SUPER ADMIN-->
      <AddPolicy
        v-if="loggedUser.role === 'SuperAdmin' && actionType === 'edit'"
        :organization="organization"
      />

      <!--POLICIES FOR REGULAR ADMIN-->
      <PolicyScreen v-if="loggedUser.role === 'Admin'" />

      <!--CASUAL USER SETTINGS-->
      <CasualUserSettings
        v-if="
          organization.casualUsersDefinitions &&
          organization.casualUsersDefinitions.isActive
        "
        :organization="organization"
        :loggedUser="loggedUser"
        :actionType="actionType"
      />

      <!--VOLUMES-->
      <Volumes :organization="organization" :actionType="actionType" />

      <!--ALERTS FOR ADMIN-->
      <AdminAlertSettings
        :notificationsSet="organization.notificationsSet"
        :status="organization.status"
      />

      <!--ALERTS FOR REGISTERED ACCOUNT-->
      <RegisteredUserAccountSettings
        :notificationsSet="organization.notificationsSet"
        :status="organization.status"
      />
      <!--IDLE TIME MESSAGES-->
      <!-- <idleTimeSettings
      :organization="organization"
      :status="organization.status"
    /> -->

      <!--FILE / DIR SETTINGS-->
      <FileDirOptions :organization="organization" />

      <!--PASSWORD REQUIRMENTS-->
      <PasswordRequirements
        :organization="organization"
        :actionType="actionType"
      />

      <!--LOGIN FAIL-->
      <LoginFailure :organization="organization" />

      <!--INTERACTIVE INTERFACE SETTINGS-->
      <UploadingScreenOptions :organization="organization" />

      <!--EMAIL SETTIGNS-->
      <EmailSettings
        :organization="organization"
        :actionType="actionType"
        :setIsCheckSmtpChange="smtpTestRan"
      />

      <!-- BACKUP EMAIL SETTINGS -->
      <EmailSettings
        :organization="organization"
        :actionType="actionType"
        :setIsCheckSmtpChange="smtpTestRan"
        :setIsCheckBackupSmtpChange="backupSmtpTestRan"
        :isBackupSettings="true"
      />
      <!--SMS SETTINGS-->
      <SMSSettings
        :smsSettings="organization"
        :status="organization.status"
        :actionType="actionType"
      />

      <!--DELETE THE HOST AND CHANGE STATUS-->
      <ChangeHostStatus
        v-if="actionType === 'edit' && loggedUser.role === 'SuperAdmin'"
        :organization="organization"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import isEqual from 'lodash/isEqual'
import { getEmptyOrganization } from '@/actions/organizations'
import { api, selectedHost } from '@/config'
import { getUTC0MSDateFromLocalDate, confirmDialog } from '@/utils'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import EditDetails from '@/components/BaseComponents/EditDetails/EditDetails.vue'

//Childrens
import OrgDefinitions from '@/components/SingleOrganization/OrganizationSettingsChildern/OrgDefinitions/OrgDefinitions.vue'
import ThemeSettings from '@/components/SingleOrganization/OrganizationSettingsChildern/ThemeSettings/ThemeSettings.vue'
import IdleTimeSettings from '@/components/SingleOrganization/OrganizationSettingsChildern/IdleTimeSettings/IdleTimeSettings.vue'
import SMSSettings from '@/components/SingleOrganization/OrganizationSettingsChildern/SMSSettings/SMSSettings.vue'
import AdminAlertSettings from '@/components/SingleOrganization/OrganizationSettingsChildern/AdminAlertSettings/AdminAlertSettings.vue'
import CasualUserSettings from '@/components/SingleOrganization/OrganizationSettingsChildern/CasualUserSettings/CasualUserSettings.vue'
import AddPolicy from '../OrganizationSettingsChildern/AddPolicy/AddPolicy.vue'
import ChangeHostStatus from '../OrganizationSettingsChildern/ChangeHostStatus/ChangeHostStatus.vue'
import SaveCancelButtons from './Childrens/SaveCancelButtons/SaveCancelButtons.vue'
import EmailSettings from '../OrganizationSettingsChildern/EmailSettings/EmailSettings.vue'
import PasswordRequirements from '../OrganizationSettingsChildern/PasswordRequirements/PasswordRequirements.vue'
import RegisteredUserAccountSettings from '../OrganizationSettingsChildern/RegisteredUserAccountSettings/RegisteredUserAccountSettings.vue'
import FileDirOptions from '../OrganizationSettingsChildern/FileDirOptions/FileDirOptions.vue'
import PolicyScreen from '../OrganizationSettingsChildern/PolicyScreen/PolicyScreen.vue'
import Volumes from '../OrganizationSettingsChildern/Volumes/Volumes.vue'
import LoginFailure from '../OrganizationSettingsChildern/LoginFailure/LoginFailure.vue'
import UploadingScreenOptions from '../OrganizationSettingsChildern/UploadingScreenOptions/UploadingScreenOptions.vue'

//Component file
import {
  checkIfSmtpTestIsNeeded,
  checkIfBackupSmtpTestIsNeeded,
  startobjHostSettings,
} from './OrganizationSettings'

export default {
  name: 'OrganizationSettings',
  data() {
    return {
      ...JSON.parse(JSON.stringify(startobjHostSettings)),
    }
  },
  components: {
    Button,
    OrgDefinitions,
    ThemeSettings,
    IdleTimeSettings,
    SMSSettings,
    AdminAlertSettings,
    CasualUserSettings,
    AddPolicy,
    ChangeHostStatus,
    SaveCancelButtons,
    EmailSettings,
    PasswordRequirements,
    EditDetails,
    RegisteredUserAccountSettings,
    FileDirOptions,
    PolicyScreen,
    Volumes,
    LoginFailure,
    UploadingScreenOptions,
  },

  watch: {
    organization: {
      handler: function (newVal) {
        if (this.actionType === 'edit' && this.freezedOrganization) {
          //if somethig changed in email settings then you have to run a test
          checkIfSmtpTestIsNeeded(this, newVal)
          checkIfBackupSmtpTestIsNeeded(this, newVal)
        }

        this.checkOrganization()
      },
      deep: true,
    },
    images: {
      handler: function (n) {
        this.checkOrganization()
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'currentOrganization',
      'loggedUser',
      'policies',
      'themeSettings',
    ]),
    getOrgSettingsWrapperClass() {
      if (
        this.loggedUser.role === 'SuperAdmin' &&
        !this.$route.path.includes('new')
      )
        return ['organization-settings-wrapper-super']
      return ''
    },
    computedIsShowEditDetails() {
      if (!this.organization.createUpdateDetails) return false
      //remove after add to emptyorganization
      else if (this.actionType === 'add') return false
      else if (this.loggedUser.role !== 'SuperAdmin') return false
      else if (this.organization.createUpdateDetails.createdByName) return true
      else if (this.organization.createUpdateDetails.updatedByName) return true
      return false
    },
  },

  methods: {
    ...mapActions([
      'GET_SINGLE_ORGANIZATION',
      'EDIT_ORGANIZATION',
      'ADD_ORGANIZATION',
      'GET_POLICIES',
      'AUTH',
    ]),
    ...mapMutations(['SET_PROCESSING', 'SET_IS_IN_MIDDLE_OF_EDITING']),
    smtpTestRan(val) {
      this.isSmtpTest = val
      this.checkOrganization()
    },

    backupSmtpTestRan(val) {
      this.isBackupSmtpTest = val
      this.checkOrganization()
    },

    checkOrganization() {
      // THIS FUNCTION CHECK BEFORE CILCK ON THE SAVE BUTTON
      this.isSaveButtonDisabled = true
      const freezedJson = JSON.stringify(this.freezedOrganization)
      const orgJson = JSON.stringify(this.organization)
      const isImagesChanged =
        this.images.logo !== null || this.images.background !== null

      if (!this.freezedOrganization && this.actionType === 'edit') return
      if (!this.organization.name) return
      // if the settings didnt changed
      if (freezedJson === orgJson && !isImagesChanged) {
        this.message = ''
        this.isSettingsEqual = true
        this.SET_IS_IN_MIDDLE_OF_EDITING(false)
        return
      }
      // if the settings do changed
      if (freezedJson !== orgJson) {
        this.message = ''
        this.isSettingsEqual = false
        if (!this.organization.backgroundImage) {
          this.SET_IS_IN_MIDDLE_OF_EDITING(true)
        }
      }

      //if the organization is blocked
      if (
        this.organization.status === 1 &&
        this.freezedOrganization.status === 1
      ) {
        this.message = 'Cannot edit blocked host'
        return
      }

      //if the organization is archived
      else if (
        this.organization.status === 2 &&
        this.freezedOrganization.status === 2
      ) {
        this.message = 'Cannot edit archived host'
        return
      }
      //THEME SETTINGS
      // if there is no name
      else if (
        this.organization.name === null ||
        this.organization.name === '' ||
        !this.organization.name.trim()
      ) {
        this.message = 'Host name is required'
        return
      }

      //if the suffix is too short
      else if (
        !this.organization.theme.suffix ||
        this.organization.theme.suffix.length < 3
      ) {
        this.message = 'Suffix must be at least 3 letters'
        return
      }

      //if the suffix is invalid
      else if (!/^[a-zA-Z0-9_.-]*$/.test(this.organization.theme.suffix)) {
        this.message = 'Suffix is invalid'
        return
      }

      //LICENSES
      //if there are no licenses
      else if (!this.organization.allowedLicenses?.length) {
        this.message = 'At least 1 License is required'
        return
      }

      //check if the input of licenses are less than in use.
      const allowedMoreThenInUseRegular =
        this.organization.allowedLicenses?.some(
          license => license.numOfAllowed < license.numOfInUse
        )
      if (allowedMoreThenInUseRegular) {
        this.message =
          'Minimum number of concurrent license must be equal or bigger than the usage'
        return
      }

      //if the casual users IN number is not valid
      if (
        !this.organization.casualUsersDefinitions.deleteCasualFilesByMsIn ||
        !Number.isInteger(
          this.organization.casualUsersDefinitions.deleteCasualFilesByMsIn
        )
      ) {
        this.message =
          'Insert a time, after which incoming files from casual users will be deleted'
        return
      }

      //if the delete after number IN is less than 0
      if (
        this.organization.casualUsersDefinitions.deleteCasualFilesByMsIn < 0 &&
        this.loggedUser.role !== 'SuperAdmin'
      ) {
        this.message =
          'Insert a time, after which incoming files from casual users will be deleted'
        return
      }

      //if the number of casual users deletion data IN is bigger than 7 days
      if (
        this.organization.casualUsersDefinitions.deleteCasualFilesByMsIn >
          604800000 &&
        this.loggedUser.role !== 'SuperAdmin' &&
        this.organization.casualUsersDefinitions.deleteCasualFilesByMsIn !==
          this.freezedOrganization.casualUsersDefinitions
            .deleteCasualFilesByMsIn
      ) {
        return (this.message =
          'Incoming casual users files life time cannot be bigger than 7 days.')
      }

      //if the casual users OUT number is not valid
      if (
        !this.organization.casualUsersDefinitions.deleteCasualFilesByMsOut ||
        !Number.isInteger(
          this.organization.casualUsersDefinitions.deleteCasualFilesByMsOut
        )
      ) {
        this.message =
          'Insert a time, after which outgoing files from casual users will be deleted'
        return
      }

      //if the delete after number Out is less than 0
      if (
        this.organization.casualUsersDefinitions.deleteCasualFilesByMsOut < 0 &&
        this.loggedUser.role !== 'SuperAdmin'
      ) {
        this.message =
          'Insert a time, after which incoming files from casual users will be deleted'
        return
      }

      //if the number of casual users deletion data OUT is bigger than 7 days
      if (
        this.organization.casualUsersDefinitions.deleteCasualFilesByMsOut >
          604800000 &&
        this.loggedUser.role !== 'SuperAdmin' &&
        this.organization.casualUsersDefinitions.deleteCasualFilesByMsOut !==
          this.freezedOrganization.casualUsersDefinitions
            .deleteCasualFilesByMsOut
      ) {
        return (this.message =
          'Outgoing casual users files life time cannot be bigger than 7 days')
      }

      //if there are casual users
      if (this.organization.casualUsersDefinitions.isActive) {
        //if there is casual accounts expantion but there are no incoming policies
        if (
          !this.organization.casualUsersDefinitions.policyIn &&
          this.actionType === 'edit'
        ) {
          this.message = 'Select a policy for incoming files from casual users'
          return
        }

        //if there are no outgoing policies for casual
        else if (
          !this.organization.casualUsersDefinitions.policyOut &&
          this.actionType === 'edit'
        ) {
          this.message = 'Select a policy for outgoing files from casual users'
          return
        }
      }

      //VOLUMES
      const { maxSingleFileSize, maxSizePerDay, maxSizePerMonth } =
        this.organization.sizeLimits

      //no values and active checkboxes
      if (maxSingleFileSize === '')
        return (this.message =
          'File size restriction per file is active and required')

      if (maxSizePerDay === '')
        return (this.message =
          'File size restriction per day is active and required')

      if (maxSizePerMonth === '')
        return (this.message =
          'File size restriction per month is active and required')

      //if the single file is bigger than daily
      if (maxSizePerDay && maxSizePerDay < maxSingleFileSize)
        return (this.message =
          'File size restriction per file cannot be bigger than file size restriction per day')
      //if the single file is bigger than monthly
      if (maxSizePerMonth && maxSizePerMonth < maxSingleFileSize)
        return (this.message =
          'File size restriction per file cannot be bigger than file size restriction per month')

      //if the daily is bigger than monthly
      if (maxSizePerMonth && maxSizePerMonth < maxSizePerDay)
        return (this.message =
          'File size restriction per day cannot be bigger than file size restriction per month')

      //==PASSWORD REQUIRMENTS
      if (
        this.organization.passwordRequirements.minLengthPassword < 6 ||
        !this.organization.passwordRequirements.minLengthPassword
      ) {
        return (this.message = 'Minimum password length is 6')
      }

      //if the password numebr is not valid
      else if (
        !Number.isInteger(
          this.organization.passwordRequirements.minLengthPassword
        )
      ) {
        return (this.message = 'Password minimum length is not valid')
      }

      //==EMAIL VENDORS
      //if there is no sender name in smtp
      if (!this.organization.emailDefinitions.displaySenderName) {
        this.message = 'SMTP sender display name is required'
        return
      }

      //if smtp is not full :
      if (this.organization.emailDefinitions.emailProtocol === 'SMTP') {
        if (!this.organization.emailDefinitions.smtpDefinitions.server) {
          this.message = 'SMTP server is required'
          return
        } else if (!this.organization.emailDefinitions.smtpDefinitions.port) {
          this.message = 'SMTP port is required'
          return
        } else if (
          !this.organization.emailDefinitions.smtpDefinitions.senderEmail
        ) {
          this.message = 'SMTP sending account is required'
          return
        } else if (
          !this.organization.emailDefinitions.smtpDefinitions.password
        ) {
          this.message = 'SMTP sending account password is required'
          return
        }
      }

      //if the ms graph is not full
      else if (
        this.organization.emailDefinitions.emailProtocol === 'Microsoft Graph'
      ) {
        if (!this.organization.emailDefinitions.msGraphDefinitions.clientId) {
          this.message = 'Microsoft Graph client ID is required'
          return
        } else if (
          !this.organization.emailDefinitions.msGraphDefinitions.tenantId
        ) {
          this.message = 'Microsoft Graph tenant ID is required'
          return
        } else if (
          !this.organization.emailDefinitions.msGraphDefinitions.clientSecret
        ) {
          this.message = 'Microsoft Graph secret is required'
          return
        } else if (
          !this.organization.emailDefinitions.msGraphDefinitions.senderEmail
        ) {
          this.message = 'Microsoft Graph "from" is required'
          return
        }
      }

      //if smtp settings changed and the admin didn't check :
      if (
        !this.isSmtpTest &&
        this.organization.emailDefinitions.emailProtocol !== 'Default'
      ) {
        this.message = 'SMTP settings were changed. Test before saving'
        return
      }

      // if there is no sender name in sms
      else if (!this.organization.smsDefinitions.sender) {
        this.message = 'SMS sender name is required'
        return
      }

      //==BACKUP EMAIL
      if (!this.organization.backupEmailDefinitions.displaySenderName) {
        this.message = 'Backup SMTP sender display name is required'
        return
      }

      //if smtp is not full :
      if (this.organization.backupEmailDefinitions.emailProtocol === 'SMTP') {
        if (!this.organization.backupEmailDefinitions.smtpDefinitions.server) {
          this.message = 'Backup SMTP server is required'
          return
        } else if (
          !this.organization.backupEmailDefinitions.smtpDefinitions.port
        ) {
          this.message = 'Backup SMTP port is required'
          return
        } else if (
          !this.organization.backupEmailDefinitions.smtpDefinitions.senderEmail
        ) {
          this.message = 'Backup SMTP sending account is required'
          return
        } else if (
          !this.organization.backupEmailDefinitions.smtpDefinitions.password
        ) {
          this.message = 'Backup SMTP sending account password is required'
          return
        }
      }

      //if the ms graph is not full
      else if (
        this.organization.backupEmailDefinitions.emailProtocol ===
        'Microsoft Graph'
      ) {
        if (
          !this.organization.backupEmailDefinitions.msGraphDefinitions.clientId
        ) {
          this.message = 'Backup Microsoft Graph client ID is required'
          return
        } else if (
          !this.organization.backupEmailDefinitions.msGraphDefinitions.tenantId
        ) {
          this.message = 'Backup Microsoft Graph tenant ID is required'
          return
        } else if (
          !this.organization.backupEmailDefinitions.msGraphDefinitions
            .clientSecret
        ) {
          this.message = 'Backup Microsoft Graph secret is required'
          return
        } else if (
          !this.organization.backupEmailDefinitions.msGraphDefinitions
            .senderEmail
        ) {
          this.message = 'Backup Microsoft Graph "from" is required'
          return
        }
      }

      //if backup smtp settings changed and the admin didn't check :
      if (
        !this.isBackupSmtpTest &&
        this.organization.backupEmailDefinitions.emailProtocol !== 'Default'
      ) {
        this.message = 'Backup SMTP settings were changed, test before saving'
        return
      }

      // if there is no sender name in sms
      else if (!this.organization.smsDefinitions.sender) {
        this.message = 'SMS sender name is required'
        return
      }

      //if the sms sender name length is bigger than 11
      else if (this.organization.smsDefinitions.sender.length > 11) {
        this.message =
          'SMS sender name length cannot be bigger than 11 characters'
        return
      }

      //==SMS
      //if the sms sender have something other that numbers and en letters
      else if (
        !/^[a-zA-Z0-9\s]*$/g.test(this.organization.smsDefinitions.sender)
      ) {
        this.message = 'SMS sender must contaion only letters and/or numbers'
        return
      }

      // twilio
      else if (this.organization.smsDefinitions.smsVendorEnum === 1) {
        if (!this.organization.smsDefinitions.authToken) {
          this.message = 'SMS authenticate token is required'
          return
        }

        if (!this.organization.smsDefinitions.accountSid) {
          this.message = 'SMS account SID is required'
          return
        }
      }

      //telnyx
      else if (this.organization.smsDefinitions.smsVendorEnum === 2) {
        if (!this.organization.smsDefinitions.profileId) {
          this.message = 'SMS profile id is required'
          return
        }

        if (!this.organization.smsDefinitions.apiKey) {
          this.message = 'SMS api key is required'
          return
        }
      }

      //if everything is fine
      this.isSaveButtonDisabled = false
      this.message = ''
      return
    },

    async changeOrganizationStatus() {
      if (
        this.actionType !== 'edit' ||
        this.organization.status === this.organization.status
      )
        this.goToSave()
      else {
        let status
        switch (this.organization.status) {
          case 0:
            status = 'activate'
            break
          case 1:
            status = 'block'
            break
          case 2:
            status = 'archive'
            break
          default:
            break
        }

        const text = `Are you sure you want to ${status} this host?`

        const thenFunc = async () => {
          this.goToSave()
        }
        const catchFunc = er => {
          if (er) {
            console.log(er)
          }
          this.loading = false
          return false
        }
        confirmDialog(this, text, 'Continue', 'Cancel', thenFunc, catchFunc)
      }
    },
    goToSave() {
      if (
        !isEqual(this.SMTPSettings, this.freezedSmtpSettings) ||
        !isEqual(this.smsSettings, this.freezedSmsSettings)
      ) {
        let smtpChanged = false
        let smsChanged = false
        if (!isEqual(this.SMTPSettings, this.freezedSmtpSettings))
          smtpChanged = true
        if (!isEqual(this.smsSettings, this.freezedSmsSettings))
          smsChanged = true

        const text = `You are about to change the following settings:<br>${
          smtpChanged ? '<br>SMTP settings' : ''
        }${
          smsChanged ? '<br>SMS vendor settings' : ''
        }<br><br>Are you sure you want to save?`

        const thenFunc = async () => {
          this.save()
        }
        const catchFunc = er => {
          this.loading = false
          if (er) {
            console.log(er)
          }
        }

        confirmDialog(this, text, 'Save', 'Cancel', thenFunc, catchFunc)
      } else {
        this.save()
      }
    },
    async save() {
      let newOrgId = null
      //delete the uneeded values of the email server

      // restart the the smtp data
      if (
        this.organization.emailDefinitions.emailProtocol !== 'SMTP' &&
        this.actionType === 'edit'
      ) {
        this.organization.emailDefinitions.smtpDefinitions.port = null
        this.organization.emailDefinitions.smtpDefinitions.encrypt = true
        this.organization.emailDefinitions.smtpDefinitions.senderEmail = null
        this.organization.emailDefinitions.smtpDefinitions.password = null
        this.organization.emailDefinitions.smtpDefinitions.server = null
      }

      //restart the graph data
      if (
        this.organization.emailDefinitions.emailProtocol !==
          'Microsoft Graph' &&
        this.actionType === 'edit'
      ) {
        this.organization.emailDefinitions.msGraphDefinitions.clientId = null
        this.organization.emailDefinitions.msGraphDefinitions.tenantId = null
        this.organization.emailDefinitions.msGraphDefinitions.clientSecret =
          null
        this.organization.emailDefinitions.msGraphDefinitions.senderEmail = null
      }

      // restart the the backup smtp data
      if (
        this.organization.backupEmailDefinitions.emailProtocol !== 'SMTP' &&
        this.actionType === 'edit'
      ) {
        this.organization.backupEmailDefinitions.smtpDefinitions.port = null
        this.organization.backupEmailDefinitions.smtpDefinitions.encrypt = true
        this.organization.backupEmailDefinitions.smtpDefinitions.senderEmail =
          null
        this.organization.backupEmailDefinitions.smtpDefinitions.password = null
        this.organization.backupEmailDefinitions.smtpDefinitions.server = null
      }

      //restart the graph data
      if (
        this.organization.backupEmailDefinitions.emailProtocol !==
          'Microsoft Graph' &&
        this.actionType === 'edit'
      ) {
        this.organization.backupEmailDefinitions.msGraphDefinitions.clientId =
          null
        this.organization.backupEmailDefinitions.msGraphDefinitions.tenantId =
          null
        this.organization.backupEmailDefinitions.msGraphDefinitions.clientSecret =
          null
        this.organization.backupEmailDefinitions.msGraphDefinitions.senderEmail =
          null
      }

      try {
        this.SET_PROCESSING(true)
        this.loading = true

        // if the value is less than 0 then make the files delete after unlimited
        if (
          this.organization.casualUsersDefinitions.deleteCasualFilesByMsIn < 0
        )
          this.organization.casualUsersDefinitions.deleteCasualFilesByMsIn = 9999999999999
        if (
          this.organization.casualUsersDefinitions.deleteCasualFilesByMsOut < 0
        )
          this.organization.casualUsersDefinitions.deleteCasualFilesByMsOut = 9999999999999

        //add new org
        if (this.actionType === 'add') {
          //add the created by
          if (!this.organization.createUpdateDetails)
            this.organization.createUpdateDetails = {} //remove when add to emptyroute
          this.organization.createUpdateDetails.createdByUserId =
            this.loggedUser.userId
          this.organization.createUpdateDetails.dateCreatedByMs =
            getUTC0MSDateFromLocalDate()

          newOrgId = await this.ADD_ORGANIZATION(this.organization)
          if (!newOrgId) throw Error
        }

        //edit organization
        else {
          //add the edited by
          this.organization.createUpdateDetails.updatedByUserId =
            this.loggedUser.userId
          this.organization.createUpdateDetails.dateUpdatedByMs =
            getUTC0MSDateFromLocalDate()

          const res = await this.EDIT_ORGANIZATION({
            loggedUserOrgId: this.loggedUser.organizationId,
            ...this.organization,
          })
          if (res.status !== 200) throw Error
          this.isSettingsEqual = true
          this.$emit('changeTab', 'tab-registered')
        }

        //if image deleted
        if (this.images.logo === 'delete') {
          const res = await api.delete(
            `/images/${this.currentOrganization.organizationId}/logo`
          )

          if (res.status !== 200) throw Error

          if (this.loggedUser.role !== 'SuperAdmin') {
            const time = Date.now()
            this.themeSettings.logoUrl = `${selectedHost}/api/images/0/logo?${time}`
          }
        }

        //if the image changed
        else if (this.images.logo) {
          const form = new FormData()
          form.append('file', this.images.logo)
          const res = await api.patch(
            `images/${newOrgId || this.organization.organizationId}/logo`,
            form
          )
          if (res.status !== 200) throw Error

          if (this.loggedUser.role !== 'SuperAdmin') {
            const time = Date.now()
            this.themeSettings.logoUrl = `${this.themeSettings.logoUrl}?${time}`
          }
        }

        //if backgorund deleted
        if (this.images.background === 'delete') {
          const res = await api.delete(
            `/images/${this.currentOrganization.organizationId}/background`
          )

          if (res.status !== 200) throw Error

          if (this.loggedUser.role !== 'SuperAdmin') {
            const time = Date.now()
            this.themeSettings.backgroundUrl = `${selectedHost}/api/images/0/background?${time}`
          }
        }
        //if the background changed
        else if (this.images.background) {
          const form = new FormData()
          form.append('file', this.images.background)
          const res = await api.patch(
            `images/${newOrgId || this.organization.organizationId}/background`,
            form
          )
          if (res.status !== 200) throw Error

          if (this.loggedUser.role !== 'SuperAdmin') {
            const time = Date.now()
            this.themeSettings.backgroundUrl = `${this.themeSettings.backgroundUrl}?${time}`
          }
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      } finally {
        this.SET_PROCESSING(false)
        this.SET_IS_IN_MIDDLE_OF_EDITING(false)

        if (this.loggedUser.role !== 'SuperAdmin') this.AUTH()
        //if this in new org then push to his main page
        if (this.actionType === 'add')
          this.$router.push(`/management/hosts/${newOrgId}/registered/users`)
        //if edit then stay on the same page
        else this.getStartpointOfHostSettings(newOrgId)
      }
    },

    async getStartpointOfHostSettings(newOrgId) {
      this.SET_PROCESSING(true)

      //restart the start page
      Object.keys(startobjHostSettings).forEach(key => {
        this[key] = JSON.parse(JSON.stringify(startobjHostSettings))[key]
      })

      let organizationId = null
      if (newOrgId) organizationId = newOrgId
      else if (this.$route.params.organizationId)
        organizationId = this.$route.params.organizationId
      else organizationId = 'new'

      if (organizationId && organizationId !== 'new')
        await this.GET_SINGLE_ORGANIZATION(organizationId)

      this.$emit('changeTab', 'tab-settings')

      if (organizationId === 'new') {
        this.actionType = 'add'
        this.organization = await getEmptyOrganization()
      } else {
        this.actionType = 'edit'
        this.organization = {
          ...this.currentOrganization,
        }

        if (this.loggedUser.role === 'SuperAdmin')
          await this.GET_POLICIES(this.organization.organizationId)

        if (this.organization.language === '') this.organization.language = 'en'
        this.freezedOrganization = JSON.parse(JSON.stringify(this.organization))
      }

      this.isDataLoaded = true
      this.SET_PROCESSING(false)
    },
  },

  async created() {
    await this.getStartpointOfHostSettings()
  },
  beforeRouteLeave(to, from, next) {
    try {
      if (this.organization.status !== 2) {
        if (
          !this.isSettingsEqual &&
          to.name !== 'Home' &&
          this.actionType === 'edit'
        ) {
          const text =
            'You have unsaved changes.<br>Are you sure you want to leave this page without saving?'
          const thenFunc = async () => {
            await this.GET_SINGLE_ORGANIZATION(
              this.currentOrganization.organizationId
            )
            next()
          }

          const catchFunc = () => {
            this.$emit('changeTab', 'tab-settings')
          }

          confirmDialog(
            this,
            text,
            'Leave Without Saving',
            'Cancel',
            thenFunc,
            catchFunc
          )
        } else {
          next()
        }
      } else {
        next()
      }
    } catch (error) {
      console.log(error)
    }
  },
}
</script>

<style scoped src="./OrganizationSettings.css"></style>
