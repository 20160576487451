<template>
  <div class="wrapper-2 source-or-target-item pa-3 mb-2 pos-relative fs-1-12r">
    <span class="mb-4"
      ><b>{{ userEndPoint.userEmail }}</b></span
    >

    <!--MESSAGES-->

    <span v-if="userEndPoint.userStatus === 1" class="red--text mx-2"
      >(Blocked account)</span
    >

    <v-divider class="my-2"></v-divider>

    <!--SHOW USER LICENSE-->
    <span class="label"><b>Interface: </b>{{ userEndPoint.license }}</span>

    <v-divider class="my-2"></v-divider>

    <div v-if="userEndPoint.license" class="ml-2">
      <!-- SOURCE ITEM -->
      <div v-if="type === 'source'">
        <SourceInteractive
          v-if="userEndPoint.license === 'Website'"
          :userEndPoint="userEndPoint"
          :routeStatus="routeStatus"
          :isEditDisabled="isEditDisabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
//Childrens
import SourceInteractive from './Childrens/SourceInteractive/SourceInteractive.vue'

export default {
  name: 'SourceOrTargetItem',
  data() {
    return {
      freezedActAs: '',
    }
  },
  props: {
    routeStatus: Number,
    type: String,
    userEndPoint: Object,
    actionType: String,
    isEditDisabled: Boolean,
  },
  components: {
    SourceInteractive,
  },

  created() {
    this.freezedActAs = this.userEndPoint.license

    this.userEndPoint.license = this.userEndPoint.license
  },
}
</script>

<style scoped src="./SourceOrTargetItem.css"></style>
