<template>
  <div>
    <!--BUTTON TO OPEN / CLOSE ARCHIVED-->
    <Button
      :text="
        isArchivedOpen
          ? $t('Hide Archived Casuals')
          : $t('Show Archived Casuals')
      "
      width="230px"
      :icon="isArchivedOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
      btnType="grey"
      class="mx-2"
      :clickAction="() => (isArchivedOpen = !isArchivedOpen)"
    />

    <!--ARCHIVED CASUALS-->
    <v-data-table
      v-if="isArchivedOpen"
      :headers="getArchivedCasualsProfileTableHeader"
      :items="archivedCasuals"
      :options="{ sortBy: ['casualUserId'] }"
      class="wrapper-1 ma-2"
      :footer-props="{
        'items-per-page-text': $t('Rows per page') + ':',
        pageText: `{0}-{1} ${$t('of')} {2}`,
        'items-per-page-all-text': $t('All'),
      }"
    >
      <!--CASUAL USER ID-->
      <template v-slot:item.casualUserId="{ item }">
        {{ convertID(item.casualUserId, 'C') }}
      </template>

      <!--PHONE NUMBER-->
      <template v-slot:item.phoneNumber="{ item }">
        <div v-if="item.phoneNumber" class="mx-auto">
          <div class="pos-relative">
            <div class="phone-input-hider"></div>
            <PhoneNumberInput
              :parentObject="item"
              :isHideDropdown="true"
              :isHideMobileTitle="true"
              :isHideUnderline="true"
              class="mb-2"
            />
          </div>
        </div>
      </template>

      <!--ICONS-->
      <template v-slot:item.icons="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn @click="() => activateCasual(item)" v-on="on" text icon>
              <v-icon>{{ mdiAccountReactivate }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('Activate') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mdiAccountReactivate } from '@mdi/js'
import { convertID } from '@/utils'
import { mapGetters } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Component files
import { archivedCasualsProfileTableHeader } from './ArchivedCasuals'
import PhoneNumberInput from '@/components/BaseComponents/PhoneNumberInput/PhoneNumberInput.vue'

export default {
  name: 'ArchivedCasuals',
  data() {
    return {
      mdiAccountReactivate,
      isArchivedOpen: false,
    }
  },
  components: { Button, PhoneNumberInput },
  computed: {
    ...mapGetters(['language']),
    getArchivedCasualsProfileTableHeader() {
      return archivedCasualsProfileTableHeader(this)
    },
  },
  props: {
    archivedCasuals: Array,
    activateCasual: Function,
  },
  methods: {
    convertID,
  },
}
</script>
