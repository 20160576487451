var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Button',{staticClass:"mx-2",attrs:{"text":_vm.isArchivedOpen
        ? _vm.$t('Hide Archived Casuals')
        : _vm.$t('Show Archived Casuals'),"width":"230px","icon":_vm.isArchivedOpen ? 'mdi-chevron-up' : 'mdi-chevron-down',"btnType":"grey","clickAction":() => (_vm.isArchivedOpen = !_vm.isArchivedOpen)}}),(_vm.isArchivedOpen)?_c('v-data-table',{staticClass:"wrapper-1 ma-2",attrs:{"headers":_vm.getArchivedCasualsProfileTableHeader,"items":_vm.archivedCasuals,"options":{ sortBy: ['casualUserId'] },"footer-props":{
      'items-per-page-text': _vm.$t('Rows per page') + ':',
      pageText: `{0}-{1} ${_vm.$t('of')} {2}`,
      'items-per-page-all-text': _vm.$t('All'),
    }},scopedSlots:_vm._u([{key:"item.casualUserId",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.convertID(item.casualUserId, 'C'))+" ")]}},{key:"item.phoneNumber",fn:function({ item }){return [(item.phoneNumber)?_c('div',{staticClass:"mx-auto"},[_c('div',{staticClass:"pos-relative"},[_c('div',{staticClass:"phone-input-hider"}),_c('PhoneNumberInput',{staticClass:"mb-2",attrs:{"parentObject":item,"isHideDropdown":true,"isHideMobileTitle":true,"isHideUnderline":true}})],1)]):_vm._e()]}},{key:"item.icons",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":() => _vm.activateCasual(item)}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiAccountReactivate))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Activate')))])])]}}],null,false,3052125437)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }