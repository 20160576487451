import { api } from '@/config'
import store from '@/store'
import { sortArrOfObjByField } from '@/utils'

export async function loginAsCasual(vue, guid) {
  try {
    store.commit('SET_PROCESSING', true)

    //ask the server if this casual user is require 2 step auth, if not get the user
    const res = await api.get(`auth/istfarequired?guid=${guid}`)
    if (res.status !== 200) throw Error

    //====USER CAME TO SEE FILES FROM EMAIL=====
    //if the user came to see the files from the email

    //weird but this is only work with the timeout
    setTimeout(() => {
      const { path } = vue.$route.query
      if (path) {
        vue.userCameFromEmailPath = formatFilePathUrl(path)
        vue.tab = 'downloading'
      }
      window.history.replaceState({}, '', location.pathname)
      if (!res.data.tfa) vue.isDataLoaded = true
    }, 50)

    //if the user is required for 2 step facotor
    if (res.data.tfa) {
      vue.casualUserGuid = guid
      vue.isShowTwoStepScreen = true
      return
    }

    api.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`
    const { token, user } = res.data

    //if the casual user have only the option to see the downloading
    vue.isShowUploading = user.isAllowSend

    //if the casual user have the option to see only uploading
    vue.isShowDownloading = user.isAllowReceive

    if (!vue.isShowUploading) vue.tab = 'downloading'

    //set the casual user as logged user
    store.commit('SET_USER', { ...user, token })
    store.dispatch('GET_INTERACTIVE_INTERFACE_FIELDS_DATA')
  } catch (e) {
    console.log(e)
  } finally {
    store.commit('SET_PROCESSING', false)
  }
}

export async function showRegisteredWebsiteInterface(vue) {
  try {
    store.commit('SET_PROCESSING', true)
    const {
      email,
      language,
      role,
      numOfAllowedCasuals,
      boundedCasuals,
      license,
    } = store.getters.loggedUser
    //=======LANGUAGE=======
    //get the user language and set it
    const defaultLanguage = language || 'en'
    vue.$i18n.i18next.changeLanguage(defaultLanguage)
    store.commit('SET_LANGUAGE', defaultLanguage)

    //if the user stored a language
    const storgedLanguage = localStorage.getItem('dot-engines-language')
    if (storgedLanguage) {
      vue.$i18n.i18next.changeLanguage(storgedLanguage)
      store.commit('SET_LANGUAGE', storgedLanguage)
    }

    //=======USER ROUTES AND TABS HE CAN SEE=======
    //get the logged user routes
    const resUserRoutes = await api.get(`routes/loggedUserRoutes`)
    if (resUserRoutes.status !== 200) throw Error

    //this is checking if to show both of the buttons:

    //this will check if the user has the option to be the SOURCE in route
    if (role !== 'SuperAdmin' && role !== 'Individual') {
      const newUserSourceRoutes = resUserRoutes.data.filter(el => {
        if (
          el.sourceEndPoints.some(el => el.userEmail === email) &&
          // if the route is not archived or blocked
          el.status === 0
        )
          return true
        return false
      })

      vue.userActiveSourceRoutes = sortArrOfObjByField(
        newUserSourceRoutes,
        'name'
      )

      //this will check if to show the user the DOWNLOADING section
      const downloadingRoutes = resUserRoutes.data.filter(el => {
        if (
          el.targetEndPoints.some(el => el.userEmail === email) &&
          // if the route is not archived or blocked
          el.status === 0
        )
          return true
        return false
      })

      //if the user is SFTP user
      if (license === 'SFTP') {
        vue.tab = 'downloading'
        vue.isShowUploading = false
      }

      //if the user is Registered
      else {
        //if the user dont have active UPLOAD routes and he dont have casuals
        if (
          !newUserSourceRoutes.length &&
          !numOfAllowedCasuals &&
          !boundedCasuals.length
        ) {
          vue.tab = 'downloading'
          vue.isShowUploading = false
        }

        //if the user dont have DOWNLOADING routes and dont have casuals
        if (
          !downloadingRoutes.length &&
          !numOfAllowedCasuals &&
          !boundedCasuals.length
        ) {
          vue.tab = 'uploading'
          vue.isShowDownloading = false
        }
      }

      //====USER CAME TO SEE FILES FROM EMAIL=====
      //if the user came to see the files from the email
      const { path } = vue.$route.query
      if (path) {
        vue.userCameFromEmailPath = formatFilePathUrl(path)
        vue.tab = 'downloading'
      }
      window.history.replaceState({}, '', location.pathname)
    }
  } catch (e) {
    console.log(e)
  } finally {
    store.commit('SET_PROCESSING', false)
  }
}

function formatFilePathUrl(url) {
  const parts = url.split('?')
  if (parts.length >= 3) {
    const firstPart = parts.slice(0, 2).join('?')
    const secondPart = parts.slice(2).join('?')
    const modifiedUrl = firstPart + 'Ⓢ' + secondPart
    return modifiedUrl.replace('\\', '/')
  } else {
    return url
  }
}
