<template>
  <div class="basic-information pa-2 mb-2">
    <h4 class="settings-h4">{{ computedUserTitle }}</h4>
    <div class="wrapper-2 d-flex flex-column pos-relative px-2 py-4">
      <span
        v-if="profile.isHasPassword === false && actionType === 'edit'"
        class="user-dont-have-pw-message mb-2 red--text font-weight-bold"
        >The user didn’t complete the registration yet</span
      >
      <div class="d-flex">
        <!--FULL NAME INPUT IF IT IS EDIT-->
        <v-text-field
          v-if="actionType === 'edit'"
          v-model="profile.name"
          :rules="[val => !!val.trim() || 'Name is required']"
          :disabled="isFieldDisabled"
          label="Name"
          class="mx-2"
          :class="[actionType === 'add' && 'required']"
          style="max-width: 150px"
          dense
        >
        </v-text-field>

        <!--FIRST NAME AND LAST NAME INPUT IF IT IS ADD-->
        <div v-else class="d-flex">
          <v-text-field
            v-model="fullName.firstName"
            :disabled="isFieldDisabled"
            label="First Name"
            :rules="[val => !!val.trim() || 'First name is required']"
            class="mx-2"
            :class="[actionType === 'add' && 'required']"
            style="max-width: 150px"
            dense
          ></v-text-field>
          <v-text-field
            v-model="fullName.lastName"
            :disabled="isFieldDisabled"
            label="Last Name"
            :rules="[val => !!val.trim() || 'Last name is required']"
            class="mx-2"
            :class="[actionType === 'add' && 'required']"
            style="max-width: 150px"
            dense
          ></v-text-field>
        </div>

        <!--EMAIL INPUT-->
        <v-text-field
          v-model.trim="profile.email"
          @blur="checkIfEmailTaken"
          @focus="$emit('set-is-email-uniqe', true)"
          @click="$emit('set-is-email-uniqe', true)"
          @input="cancelWhiteSpace"
          :rules="userEmailRules"
          :error="!isEmailUniqe"
          :disabled="isFieldDisabled || actionType === 'edit'"
          label="Email"
          :style="{
            'max-width':
              actionType === 'add' ? '270px' : `${profile.email.length * 9}px`,
          }"
          class="mx-2"
          validate-on-blur
          :class="[actionType === 'add' && 'required']"
          dense
        >
        </v-text-field>

        <!--PHONE NUMBER INPUT-->
        <PhoneNumberInput :parentObject="profile" :disabled="isFieldDisabled" />
      </div>

      <div class="d-flex justify-space-between align-center">
        <!--IS TWO STEP VERFICATION -->
        <v-checkbox
          v-if="isShow2FA"
          v-model="profile.isTwoStepVerification"
          :disabled="isFieldDisabled"
          class="mt-0"
          label="2-step verification for portal access"
          hide-details
        >
        </v-checkbox>
        <div v-else></div>

        <!--RESET PASSWORD  -->
        <Button
          v-if="actionType === 'edit'"
          class="mr-8"
          :disabled="isFieldDisabled"
          width="150"
          btnType="grey"
          :text="
            profile.numOfLogins === 0 ? 'Renew Password' : 'Reset Password'
          "
          :clickAction="resetUserPassword"
        />
      </div>
      <OnlineHelp page="singleUser" section="user" title="User" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { resendCredentials } from '@/actions/auth'
import { confirmDialog, validateEmail } from '@/utils'
import { api } from '@/config'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import PhoneNumberInput from '@/components/BaseComponents/PhoneNumberInput/PhoneNumberInput.vue'
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'BasicInformation',
  data() {
    return {
      isPhoneNumberActive: false,
    }
  },
  props: {
    profile: Object,
    actionType: String,
    fullName: Object,
    isEmailUniqe: Boolean,
    isFieldDisabled: Boolean,
  },
  emits: ['setLoading', 'set-is-email-uniqe'],
  components: { Button, PhoneNumberInput, OnlineHelp },
  watch: {
    'profile.isTwoStepVerification': function (n, o) {
      if (!n && o !== undefined) {
        this.profile.sendingMethodForTwoStepVerificationCode = 1
      }
    },
  },
  computed: {
    ...mapGetters(['currentOrganization']),
    isShow2FA() {
      if (this.profile.role === 'Individual') return false
      if (this.profile.license === 'API') return false
      return true
    },
    computedUserTitle() {
      if (this.profile.license === 'API') return 'Contact Person'
      else if (this.profile.license === 'SFTP') return 'User/Contact Person'
      return 'User'
    },
    userEmailRules() {
      return [
        v => !!v || 'Email is required',
        v => validateEmail(v && v.trim()) || 'Email must be valid',
        v => this.isEmailUniqe || 'Email already taken',
      ]
    },
  },
  methods: {
    ...mapMutations(['SET_NOTIFICATION']),
    async checkIfEmailTaken() {
      if (this.profile.email) {
        this.cancelWhiteSpace(this.profile.email)
        const { data } = await api.get(
          `users/checkifemailexists/${encodeURIComponent(this.profile.email)}`
        )
        //true on data means that the email is already exist
        this.$emit('set-is-email-uniqe', data)
      }
    },
    cancelWhiteSpace(e) {
      if (e && this.profile.email && this.actionType === 'add') {
        this.profile.email = this.profile.email.replace(' ', '')
      }
    },

    resetUserPassword() {
      const text = `Are you sure you want to reset the user's password?`

      const thenFunc = async () => {
        this.$emit('setLoading', true)
        await resendCredentials(this.profile.email)
        this.SET_NOTIFICATION({
          type: 'success',
          text: 'The password was reset successfully. An email that asks to select a new password was sent to the user.',
        })
        this.$emit('setLoading', false)
      }

      const catchFunc = () => this.$emit('setLoading', false)

      confirmDialog(this, text, 'Continue', 'Cancel', thenFunc, catchFunc)
    },
  },
}
</script>

<style src="./BasicInformationUnScoped.css"></style>
<style scoped src="./BasicInformation.css"></style>
