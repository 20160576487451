export function areArraysIdentical(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false // If the arrays have different lengths, they can't be identical
  }

  // Sort the arrays based on a unique identifier property of the objects (e.g., 'id')
  const sortedArr1 = arr1.sort((a, b) => a.userId - b.userId)
  const sortedArr2 = arr2.sort((a, b) => a.userId - b.userId)

  // Check if each corresponding pair of objects are equal
  for (let i = 0; i < sortedArr1.length; i++) {
    if (!isObjectEqual(sortedArr1[i], sortedArr2[i])) {
      return false // If any pair of objects is not equal, the arrays are not identical
    }
  }

  return true // All objects in the arrays are equal
}

// Helper function to check if two objects are equal
export function isObjectEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false // If the objects have different numbers of properties, they can't be equal
  }
  for (let key of keys1) {
    if (
      obj1[key] !== obj2[key] &&
      key !== 'boundedUsers' &&
      key !== 'addedBy'
    ) {
      return false // If any property value is not equal, the objects are not equal
    }
  }

  return true // All properties of the objects are equal
}

export const startCasualUsersProfileData = {
  allCasualUsers: [],
  archivedCasuals: [],
  activeCasuals: [],
  freezedCasuals: [],
  editedCasual: null,
  numOfAllowedCasuals: 0,
  counterOfusersCasuals: 0,
  isShowAddCasualDialog: false,
  isSaveButtonDisabled: true,
  isArchivedOpen: false,
  changes: {
    casuals: {
      deletedCasuals: [],
      updatedCasuals: [],
    },
    registeredLicenses: [],
  },
}
