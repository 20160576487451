export const regularUserCasualUsersTableHeaders = vue => [
  {
    text: vue.$t('Associate'),
    value: 'isBounded',
    align: 'center',
    class: `bg-table-heading ${
      vue.language === 'he' ? 'table-header-last' : 'table-header-first'
    }`,
    width: '80px',
    sortable: false,
  },
  {
    text: vue.$t('Username'),
    value: 'email',
    align: 'start',
    class: 'bg-table-heading',
    width: '200px',
  },
  {
    text: vue.$t('Name'),
    value: 'name',
    align: 'start',
    class: 'bg-table-heading',
    width: '100px',
  },
  {
    text: vue.$t('Mobile'),
    value: 'phoneNumber',
    align: 'center',
    sortable: false,
    width: '150px',
    class: 'bg-table-heading',
  },
  {
    text: vue.$t('Associated'),
    value: 'boundedUsers.length',
    align: 'center',
    width: '100px',
    class: 'bg-table-heading',
  },
  {
    text: vue.$t('Creator'),
    value: 'addedBy.email',
    align: 'center',
    width: '200px',
    class: 'bg-table-heading',
  },
  {
    text: vue.$t('S/N'),
    value: 'casualUserId',
    align: 'center',
    width: '60px',
    class: 'bg-table-heading',
  },
  {
    text: vue.$t('Direction'),
    sortable: false,
    value: 'direction',
    align: 'center',
    width: '100px',
    class: 'bg-table-heading',
  },
  {
    text: '',
    sortable: false,
    value: 'icons',
    align: 'center',
    width: '60px',
    class: `bg-table-heading ${
      vue.language === 'he' ? 'table-header-first' : 'table-header-last'
    }`,
  },
]
