<template>
  <v-tabs-items
    touchless
    v-model="tab"
    class="wrapper-1 mt-2 ma-auto"
    :class="[
      tab === 'uploading' && 'mw-400',
      tab === 'downloading' && 'fill-height',
    ]"
  >
    <!--THE UPLOADING SECTION-->
    <v-tab-item value="uploading">
      <UploadMobile :userActiveSourceRoutes="userActiveSourceRoutes" />
    </v-tab-item>

    <!-- THE DOWNLOADING SECTION -->
    <v-tab-item value="downloading" class="fill-height">
      <DownloadMobile
        :userCameFromEmailPath="userCameFromEmailPath"
        :setTab="setTab"
        :tab="tab"
      />
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
//Childrens
import DownloadMobile from './Childrens/DownloadMobile/DownloadMobile.vue'
import UploadMobile from './Childrens/UploadMobile/UploadMobile.vue'

export default {
  name: 'WebsiteInterfaceMobile',
  props: {
    userActiveSourceRoutes: Array,
    tab: String,
    userCameFromEmailPath: String,
    setTab: Function,
  },
  components: { UploadMobile, DownloadMobile },
}
</script>
