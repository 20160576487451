export const srartCasualUsersPageData = {
  casualsLicense: {},
  freezedUsers: [],
  isSaveCasualUsersButtonDisabled: true,
  isShowMessage: false,
  message: '',
  isSaveButtonClicked: false,
  allCasuals: [],
  activeCasuals: [],
  archivedCasuals: [],
  changes: {
    registeredLicenses: [],
    casuals: {
      updatedCasuals: [],
      deletedCasuals: [],
    },
  },
}
