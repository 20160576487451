<template>
  <div>
    <h4 class="light-text mt-1">Directory Structure</h4>

    <!--PATH-->
    <div class="d-flex justify-space-between">
      <v-checkbox
        v-model="route.foldersHierarchy.isPath"
        :disabled="isEditDisabled"
        label="Prepend path:"
        dense
        hide-details
        class="ma-0 pa-0"
        style="min-width: 140px"
        height="24px"
      ></v-checkbox>
      <v-text-field
        v-model="route.foldersHierarchy.path"
        :disabled="!route.foldersHierarchy.isPath || isEditDisabled"
        :rules="pathRules"
        height="18px"
        class="path-input ma-0 pa-0 mx-2"
        style="margin-top: 3px !important"
        maxlength="50"
      ></v-text-field>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon dense v-on="on">mdi-information-outline</v-icon>
        </template>
        <span v-html="forbiddenChars"> </span>
      </v-tooltip>
    </div>

    <!--SENDER EMAIL-->
    <v-checkbox
      v-model="route.foldersHierarchy.isSenderEmail"
      :disabled="isEditDisabled"
      label="Sender email"
      dense
      class="ma-0 pa-0"
      hide-details
      height="20px"
    ></v-checkbox>

    <!--IS SESSION-->
    <v-checkbox
      v-model="route.foldersHierarchy.isSession"
      :disabled="isEditDisabled"
      dense
      class="pa-0 ma-0 mt-1"
      hide-details
      label="Session (Timestamp)"
      height="20px"
    ></v-checkbox>
  </div>
</template>

<script>
import { isFolderNameValid } from '@/utils'

export default {
  name: 'DirectoryStructure',
  data() {
    return {
      forbiddenChars: `Forbidden charts: > , < , : , " , | , ? , * <br/> Use '\\' to create folder hierarchy: A\\B`,
    }
  },
  props: {
    isEditDisabled: Boolean,
    route: Object,
  },
  computed: {
    pathRules(value) {
      return [
        value => !!value || value === '' || 'Required',
        value => isFolderNameValid(value) || 'Path is not valid',
      ]
    },
  },
}
</script>

<style scoped src="./DirectoryStructure.css"></style>
