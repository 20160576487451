<template>
  <v-tab-item
    value="uploading"
    @dragenter.prevent="isDrag = true"
    @dragleave.prevent="isDrag = false"
    @dragover.prevent="isDrag = true"
    @drop.prevent="fileDropped"
    class="fill-height"
  >
    <!--UPLOADING DETAILS POS-ABOSLUTE!-->
    <UploadingDetails
      :session="session"
      :userActiveSourceRoutes="userActiveSourceRoutes"
      :startTabletFiltering="startTabletFiltering"
      :statusResults="statusResults"
      :restartSessionClicked="restartSessionClicked"
      :errors="errors"
      :lightSessionRestart="lightSessionRestart"
    />

    <!--RUNNING SESSION DETAILS POS-ABOSLUTE!-->
    <SessionDetails
      v-if="levelOfUploading === 'table'"
      :stopSessionClicked="stopSessionClicked"
      :session="session"
      :sessionFiles="sessionFiles"
      :sessionSize="sessionSize"
      :curFileUploaded="curFileUploaded"
      :filteringProgress="filteringProgress"
      :timer="timer"
    />

    <!--DROP FILES -->
    <DropFiles
      v-if="levelOfUploading === 'drop'"
      :isDrag="isDrag"
      :session="session"
      type="tablet"
    />

    <!--FILES TABLE IF THERE ARE FILES-->
    <UploadFilesTable
      v-if="levelOfUploading === 'table'"
      :session="session"
      :curFileUploaded="curFileUploaded"
      type="tablet"
    />
    <!--ENDING REPORT-->
    <UploadReportDialog
      v-if="levelOfUploading === 'report'"
      :restartSessionClicked="restartSessionClicked"
      :statusResults="statusResults"
    />
  </v-tab-item>
</template>

<script>
import { mapGetters } from 'vuex'
import { api } from '@/config'

//Childrens
import UploadingDetails from './Childrens/UploadingDetails/UploadingDetails.vue'
import SessionDetails from './Childrens/SessionDetails/SessionDetails.vue'

//Base components
import DropFiles from '@/components/WebsiteInterface/CommonComponents/DropFiles/DropFiles.vue'
import Report from '@/components/WebsiteInterface/CommonComponents/Report/Report.vue'
import UploadFilesTable from '@/components/WebsiteInterface/CommonComponents/UploadFilesTable/UploadFilesTable.vue'

//Filtering functions
import {
  startFiltering,
  deleteIndividualFiles,
  cancelUploading,
  addDroppedFileOrDirectory,
} from '@/components/WebsiteInterface/uploadingFunctions'

//Helpers to the session
import { startObjForUpload } from '@/components/WebsiteInterface/Websiteinterface'
import UploadReportDialog from '@/components/WebsiteInterface/CommonComponents/UploadReportDialog/UploadReportDialog.vue'

export default {
  name: 'Upload',
  data() {
    return {
      ...JSON.parse(JSON.stringify(startObjForUpload)),
    }
  },
  props: {
    userActiveSourceRoutes: Array,
  },
  components: {
    UploadingDetails,
    DropFiles,
    Report,
    SessionDetails,
    UploadFilesTable,
    UploadReportDialog,
  },

  computed: {
    ...mapGetters(['isInMiddleOfFiltering', 'loggedUser']),
    levelOfUploading() {
      //if not filtering AND there are no files AND there is no report then show drop files
      if (
        !this.isInMiddleOfFiltering &&
        !this.session.filesData.length &&
        !this.statusResults.session.operationId
      )
        return 'drop'

      //if there are files and in middle of filtering
      if (this.session.filesData.length) return 'table'

      //if there is session report
      if (this.statusResults.session.operationId) return 'report'
    },
  },
  methods: {
    async startTabletFiltering() {
      let biggestFileSize = 0
      this.session.filesData.forEach(el =>
        el.file.numbSize > biggestFileSize
          ? (biggestFileSize = el.file.numbSize)
          : null
      )

      const isSessionApprovedRes = await api.get(
        `filter/sizepermissions?total=${this.session.totalSize}&biggest=${biggestFileSize}`
      )
      if (isSessionApprovedRes.status !== 200) return

      startFiltering(this, 'tablet')
    },

    fileDropped(e) {
      if (this.isInMiddleOfFiltering) return
      addDroppedFileOrDirectory(this, e)
    },

    async restartSessionClicked() {
      //if the user is individual and the session wansnt restarted
      if (
        this.loggedUser.role === 'Individual' &&
        !this.session.filesData.length
      )
        await deleteIndividualFiles(
          this.session.operationId,
          this.loggedUser.token
        )
      //this will restart the session
      Object.keys(startObjForUpload).forEach(key => {
        this[key] = JSON.parse(JSON.stringify(startObjForUpload))[key]
      })
    },

    lightSessionRestart() {
      //this will run when a user did a session and
      //then changed the route, casuals, subject etc...
      if (!this.session.operationId) return

      this.session.htmlReport = {
        summary: '',
        date: '',
      }
      this.session.operationId = ''

      this.statusResults = {
        intact: 0,
        modified: 0,
        rejected: 0,
        total: 0,
        session: {},
        partial: 0,
      }
    },

    stopSessionClicked() {
      cancelUploading(this)
    },
  },
}
</script>
