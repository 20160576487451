<template>
  <div class="my-6">
    <div class="d-flex align-end">
      <h4 class="settings-h4 mb-2">All Casual Accounts</h4>

      <div class="pos-relative ml-10">
        <OnlineHelp
          page="casualUsers"
          section="casualTable"
          title="All Casual Accounts"
        />
      </div>
    </div>

    <v-data-table
      class="wrapper-1"
      :items="activeCasuals"
      :headers="HostCasualUsersTableHeaders"
      :options="{ sortBy: ['casualUserId'] }"
      must-sort
    >
      <template v-slot:body="{ items }">
        <tbody>
          <CasualRow
            v-for="casual in items"
            :key="casual.email"
            :casualUser="casual"
            :editedUserId="editedUserId"
            :setEditedCasualUserId="val => (editedUserId = val)"
            :editCasualInActiveCasuals="editCasualInActiveCasuals"
            :deleteNewCasual="deleteNewCasual"
            :changes="changes"
          />
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

//Childrens
import CasualRow from './Childrens/CasualRow/CasualRow.vue'
import InputRow from './Childrens/InputRow/InputRow.vue'

//Components files
import { HostCasualUsersTableHeaders } from './AllCasualsTable'
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'AllCasualsTable',
  data() {
    return {
      HostCasualUsersTableHeaders,
      editedUserId: null,
    }
  },

  props: {
    changes: Object,
    casualsLicense: Object,
    activeCasuals: Array,
    archivedCasuals: Array,
    editCasualInActiveCasuals: Function,
    deleteNewCasual: Function,
  },

  components: {
    CasualRow,
    InputRow,
    OnlineHelp,
  },
  computed: {
    ...mapGetters(['currentOrganization']),
    isOrganizationHaveEnogthCasualLicenses() {
      if (this.activeCasuals.length + 1 > this.casualsLicense.numOfAllowed)
        return false
      return true
    },
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),
    addCasualToTable(user) {
      this.activeCasuals.push(user)
    },
  },
}
</script>
