export const boundedUsersHeader = [
  {
    text: 'Associate',
    value: 'bound',
    sortable: false,
    align: 'start',
    class: 'bg-table-heading table-header-first',
    width: '40px',
  },
  {
    text: 'Username',
    value: 'email',
    align: 'start',
    class: 'bg-table-heading',
    width: '60%',
  },
  {
    text: 'name',
    value: 'name',
    align: 'start',
    class: 'bg-table-heading table-header-last',
    width: '30%',
  },
]
