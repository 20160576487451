<template>
  <tr>
    <td>{{ casual.name }}</td>
    <td>{{ casual.email }}</td>

    <td class="pos-relative">
      <div v-if="casual.phoneNumber">
        <div class="phone-input-hider"></div>
        <PhoneNumberInput
          :parentObject="casual"
          :isHideDropdown="true"
          :isHideMobileTitle="true"
          :isHideUnderline="true"
        />
      </div>
    </td>
    
    
    <td><!--BOUNDED--></td>
    <td>
      <!--PARENT-->
    </td>

    <td>{{ casual.casualUserId | generateFakeId }}</td>

    <td><!--LICENSE--></td>
    <td class="pos-relative d-flex align-center justify-space-evenly">

      <!--ACTIVE CASUAL-->
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="setCasualToActivate(casual)" text icon>
          <v-icon>{{ mdiAccountReactivate }}</v-icon>
        </v-btn>
        </template>
        <span>Activate account</span>
      </v-tooltip>

      <!--DELETE CASUAL FOR REAL-->
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="deleteCasual(casual)" text icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Delete account</span>
      </v-tooltip>
    </td>
  </tr>
</template>

<script>
import { convertID } from '@/utils'
import { mapGetters } from 'vuex'
import { mdiAccountReactivate } from '@mdi/js'
import PhoneNumberInput from '@/components/BaseComponents/PhoneNumberInput/PhoneNumberInput.vue'

export default {
    name: 'ArchivedCasualRow',
    data() {
        return {
            mdiAccountReactivate,
            isShowDisabledMessage: false,
            messageWhySaveButtonDisabled: '',
        };
    },
    props: {
        casual: Object,
        archivedCasuals: Array,
        activeCasuals: Array,
        setCasualToActivate: Function,
        deleteCasual: Function,
    },
    filters: {
        generateFakeId(id) {
            const fakeID = convertID(id, 'C');
            return fakeID;
        },
    },
    computed: {
        ...mapGetters(['users', 'currentOrganization']),
    },
    components: { PhoneNumberInput }
}
</script>

<style scoped src="./ArchivedCasualRow.css"></style>
