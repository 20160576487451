<template>
  <div
    class="wrapper-1 ma-1 pa-2 fs-1-12r"
    :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
  >
    <!--START TIME-->

    <div v-if="search.direction === 0" class="my-1">
      <b>{{ $t('Start time') }}: </b>{{ session.startTime }}
    </div>

    <!--RECIVE TIME-->
    <div v-else class="my-1">
      <b>{{ $t('Recived time') }}: </b>{{ session.endTime }}
    </div>

    <!--SUBJECT-->
    <div class="my-1">
      <b>{{ $t(interactiveInterfaceFields.subjectFieldText) }}: </b>
      {{ session.subject }}
    </div>

    <!--REPORT SUMMARY-->
    <div v-if="loggedUser.isAllowReport" class="my-1 d-flex flex-wrap">
      <b>{{ $t('Summary') }}: </b>
      <div class="d-flex">
        <div v-for="result of summaryBuilder" class="d-flex mx-1 w-52">
          <!--IMAGE-->
          <img :src="result.img" class="mx-1 h-20" />
          <!--NUMBER-->
          <span class="mx-1"
            ><b>{{ session.reportSummary[result.type] }}</b></span
          >
        </div>
      </div>
    </div>

    <!--MORE BUTTON-->
    <div class="d-flex justify-end">
      <v-icon @click="setShownSession(session)" large
        >mdi-dots-horizontal-circle-outline</v-icon
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import intact from '@/assets/intact.svg'
import modified from '@/assets/modified.svg'
import partial from '@/assets/partial.svg'
import blocked from '@/assets/blocked.svg'

const summaryBuilder = [
  { type: 'intact', img: intact, label: 'Intact' },
  { type: 'modified', img: modified, label: 'Modified' },
  { type: 'partial', img: partial, label: 'Partial' },
  { type: 'rejected', img: blocked, label: 'Blocked' },
]

export default {
  name: 'SingleSessionCard',
  data() {
    return {
      summaryBuilder,
    }
  },
  props: {
    session: Object,
    search: Object,
    setShownSession: Function,
  },
  computed: {
    ...mapGetters(['loggedUser', 'language', 'interactiveInterfaceFields']),
  },
}
</script>
