<template>
  <div
    class="casuals-profile-wrapper"
    :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
  >
    <!--CASUAL HEADER-->
    <CasualProfileHeader
      :setIsShowAddCasualDialog="v => (isShowAddCasualDialog = v)"
      :counterOfusersCasuals="counterOfusersCasuals"
      :cameFrom="cameFrom"
      :changes="changes"
      :saveCasualUsersPage="saveCasualUsersPage"
    />

    <!--ACTIVE CASUALS - DESKTOP-->
    <ActiveCasuals
      v-if="isDesktop"
      :computedActiveCasualUsers="computedActiveCasualUsers"
      :isBoundChanged="isBoundChanged"
      :setEditedCasual="v => (editedCasual = v)"
    />

    <!--ACTIVE CASUALS - MOBILE-->
    <div v-else>
      <CasualCardMobile
        v-for="casual of computedActiveCasualUsers"
        :key="casual.email"
        :casual="casual"
        :isBoundChanged="isBoundChanged"
        :setEditedCasual="v => (editedCasual = v)"
        type="active"
      />
    </div>

    <v-divider class="my-4"></v-divider>

    <!--ARCHIVED CASUALS DESKTOP-->
    <ArchiveCasuals
      v-if="isDesktop"
      :archivedCasuals="archivedCasuals"
      :activateCasual="activateCasual"
    />

    <!--ARCHIVE CASUALS MOBILE-->
    <div v-else>
      <Button
        :text="
          isArchivedOpen ? 'Hide Archived Casuals' : 'Show Archived Casuals'
        "
        width="230px"
        :icon="isArchivedOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        btnType="grey"
        class="mx-2"
        :clickAction="() => (isArchivedOpen = !isArchivedOpen)"
      />

      <div v-if="isArchivedOpen">
        <CasualCardMobile
          v-for="casual of archivedCasuals"
          :key="casual.email"
          :casual="casual"
          :isBoundChanged="() => null"
          :setEditedCasual="v => null"
          type="archived"
          :activateCasual="activateCasual"
        />
      </div>
    </div>

    <!--ADD / EDIT CASUAL DIALOG-->
    <AddEditCasualDialog
      v-if="isShowAddCasualDialog || editedCasual"
      :closeAddEditCasual="closeAddEditCasual"
      :addNewCasualUser="addNewCasualUser"
      :archivedCasuals="archivedCasuals"
      :editedCasual="editedCasual"
      :editCasualUser="editCasualUser"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { api } from '@/config'
import { alertDialog, confirmDialog } from '@/utils'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Childrens
import ActiveCasuals from './Children/ActiveCasuals/ActiveCasuals.vue'
import AddEditCasualDialog from './Children/AddEditCasualDialog/AddEditCasualDialog.vue'
import ArchiveCasuals from './Children/ArchivedCasuals/ArchivedCasuals.vue'
import CasualProfileHeader from './Children/CasualProfileHeader/CasualProfileHeader.vue'
import CasualCardMobile from './Children/CasualCardMobile/CasualCardMobile.vue'

//Component helpers
import {
  startCasualUsersProfileData,
  areArraysIdentical,
  isObjectEqual,
} from './CasualUsersProfile'

export default {
  name: 'CasualUsersProfile',
  data() {
    return {
      ...JSON.parse(JSON.stringify(startCasualUsersProfileData)),
    }
  },

  props: { cameFrom: String },

  components: {
    CasualProfileHeader,
    ActiveCasuals,
    AddEditCasualDialog,
    ArchiveCasuals,
    CasualCardMobile,
    Button,
  },

  computed: {
    ...mapGetters(['loggedUser', 'isInMiddleOfEditing', 'language']),
    isDesktop() {
      if (this.$vuetify.breakpoint.width > 850) return true
      return false
    },
    computedActiveCasualUsers() {
      let counterOfusersCasuals = 0
      const newArr = this.activeCasuals.map(el => {
        if (el.addedBy.email === this.loggedUser.email) counterOfusersCasuals++

        return el
      })

      this.counterOfusersCasuals = counterOfusersCasuals
      return newArr
    },
  },

  methods: {
    ...mapMutations([
      'SET_PROCESSING',
      'SET_USER',
      'SET_NOTIFICATION',
      'SET_IS_IN_MIDDLE_OF_EDITING',
    ]),
    closeAddEditCasual() {
      this.editedCasual = null
      this.isShowAddCasualDialog = false
    },

    //ADD NEW CASUAL===========
    addNewCasualUser(newCasual) {
      newCasual.name = newCasual.firstName + ' ' + newCasual.lastName
      this.activeCasuals.push(newCasual)
      this.changes.casuals.updatedCasuals.push(newCasual)
      this.isShowAddCasualDialog = false
    },

    //EDIT CASUAL==============
    editCasualUser(editedCasual) {
      //if the casual user was archived - remove from active and put on archived arr
      if (editedCasual.isArchived) {
        this.activeCasuals = this.activeCasuals.filter(
          el => el.email !== editedCasual.email
        )
        this.archivedCasuals.push(editedCasual)
      }

      //else - only change in active casuals array
      else {
        this.activeCasuals = this.activeCasuals.map(el => {
          //new casual
          if (el.casualUserId === editedCasual.casualUserId) return editedCasual

          return el
        })
      }

      //change in changes object
      this.changes.casuals.updatedCasuals =
        this.changes.casuals.updatedCasuals.filter(
          el => el.casualUserId !== editedCasual.casualUserId
        )

      this.changes.casuals.updatedCasuals.push(editedCasual)

      //check if the casual new value is the same as the old one
      //and if so remove from changes array

      const oldVal = this.freezedCasuals.find(
        el => el.email === editedCasual.email
      )
      if (oldVal) {
        const isIdentical = isObjectEqual(oldVal, editedCasual)
        //if the objects are identical remove from changes
        if (isIdentical) {
          this.changes.casuals.updatedCasuals =
            this.changes.casuals.updatedCasuals.filter(
              el => el.email !== editedCasual.email
            )
        }
      }

      //close dialog
      this.editedCasual = null
    },
    //BOUND CHANGED============
    isBoundChanged(evt, casualUser) {
      let editedCasualUser = casualUser
      //find the user in changes
      const changedUser = this.changes.casuals.updatedCasuals.find(
        el => el.email === casualUser.email
      )
      if (changedUser) {
        //set the user as the edited one
        editedCasualUser = changedUser

        //remove from the changes arr
        this.changes.casuals.updatedCasuals =
          this.changes.casuals.updatedCasuals.filter(
            el => el.email !== casualUser.email
          )
      }

      //if the user was bound
      if (evt === true) {
        const loggedUserObj = {
          email: this.loggedUser.email,
          name: this.loggedUser.name,
          userId: this.loggedUser.userId,
        }
        editedCasualUser.boundedUsers.push(loggedUserObj)
      }

      //if the user was unbound
      else {
        editedCasualUser.boundedUsers = editedCasualUser.boundedUsers.filter(
          el => el.userId !== this.loggedUser.userId
        )
      }

      //add the new casual user to changes
      this.changes.casuals.updatedCasuals.push(editedCasualUser)

      //check if the casual new value is the same as the old one
      //and if so remove from changes array

      const oldVal = this.freezedCasuals.find(
        el => el.email === casualUser.email
      )

      if (oldVal) {
        const isIdentical = areArraysIdentical(
          oldVal.boundedUsers,
          casualUser.boundedUsers
        )

        //if the arrays are identical remove from changes
        if (isIdentical) {
          this.changes.casuals.updatedCasuals =
            this.changes.casuals.updatedCasuals.filter(
              el => el.email !== casualUser.email
            )
        }
      }
    },
    //ACTIVAE CASUAL===========
    async activateCasual(casualUser) {
      //if user dosent have enuogh licenses
      if (this.counterOfusersCasuals >= this.loggedUser.numOfAllowedCasuals)
        return alertDialog(
          this,
          this.$t(
            'You do not have sufficient casual license usage to activate this user as a casual user'
          ),
          this.$t('OK')
        )

      const text =
        this.$t('Are you sure you want to activate this casual user') + '?'

      const thenFunc = () => {
        const loggedUserObj = {
          email: this.loggedUser.email,
          name: this.loggedUser.name,
          userId: this.loggedUser.userId,
        }

        //add the logged user as the only bounded
        casualUser.boundedUsers.push(loggedUserObj)

        //add the logged user as the parent
        casualUser.addedBy = loggedUserObj

        //make active
        casualUser.isArchived = false

        //add to changes object
        this.changes.casuals.updatedCasuals.push(casualUser)

        //remove from archived list and add to active list
        this.archivedCasuals = this.archivedCasuals.filter(
          el => el.email !== casualUser.email
        )

        this.activeCasuals.push(casualUser)
      }

      await confirmDialog(
        this,
        text,
        this.$t('Activate'),
        this.$t('Cancel'),
        thenFunc
      )
    },

    //START DATA FOR CASUALS===
    async getStartDataOfCasuals() {
      try {
        this.SET_PROCESSING(true)

        //restart the start page
        Object.keys(startCasualUsersProfileData).forEach(key => {
          this[key] = JSON.parse(JSON.stringify(startCasualUsersProfileData))[
            key
          ]
        })

        const res = await api.get(
          `users/casual/${this.loggedUser.organizationId}`
        )
        if (res.status !== 200) throw Error

        //set all casual users
        this.allCasualUsers = res.data.casualUsers

        //set archived casuals and active ones
        res.data.casualUsers.forEach(casual => {
          if (casual.isArchived) return this.archivedCasuals.push(casual)
          return this.activeCasuals.push(casual)
        })

        //num of allowed casuals of the org
        this.numOfAllowedCasuals = res.data.numOfAllowed
        this.freezedCasuals = JSON.parse(JSON.stringify(this.allCasualUsers))
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },

    //SAVE CASUALS PAGE===
    async saveCasualUsersPage() {
      try {
        this.SET_PROCESSING(true)

        //check if the added users is not deleted
        const deletedEmails = []
        this.changes.casuals.deletedCasuals =
          this.changes.casuals.deletedCasuals
            .map(el => {
              if (typeof el === 'string') {
                deletedEmails.push(el)
                return undefined
              }
              return el
            })
            .filter(el => el !== undefined)

        const res = await api.post(
          `users/updateCasuals/${this.loggedUser.organizationId}`,
          this.changes
        )

        if (res.status !== 200) throw Error

        //get the new user from db
        const user = await api.get(`users/${this.loggedUser.userId}`)
        if (user.status !== 200) throw Error
        this.SET_USER(user.data)

        this.SET_NOTIFICATION({
          type: 'success',
          text: 'Casual users list was updated successfully',
        })

        this.SET_IS_IN_MIDDLE_OF_EDITING(false)
        this.getStartDataOfCasuals()
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
  },
  async created() {
    this.getStartDataOfCasuals()
  },

  async beforeRouteLeave(to, from, next) {
    if (this.isInMiddleOfEditing && to !== 'Home') {
      const text =
        this.$t(
          'You have unsaved changes. Are you sure you want to leave this page without saving'
        ) + '?'
      const thenFunc = () => {
        this.SET_IS_IN_MIDDLE_OF_EDITING(false)
        next()
      }
      const catchFunc = () => this.$emit('changeTab', 'tab-casual')

      return await confirmDialog(
        this,
        text,
        this.$t('Leave Without Saving'),
        this.$t('Cancel'),
        thenFunc,
        catchFunc
      )
    }
    //if the user quit the system
    else if (to === 'Home') {
      next()
    }
    //eleses
    next()
  },
}
</script>

<style src="./CasualUsersProfile.css" scoped></style>
