import { render, staticRenderFns } from "./Suffix.vue?vue&type=template&id=6021ef3c&scoped=true&"
import script from "./Suffix.vue?vue&type=script&lang=js&"
export * from "./Suffix.vue?vue&type=script&lang=js&"
import style0 from "../ThemeSettingsChildren.css?vue&type=style&index=0&id=6021ef3c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6021ef3c",
  null
  
)

export default component.exports