<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeClicked"
    width="500"
    class="wrapper-1 bg-white"
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <v-card
      class="wrapper-1 fs-1-25r"
      :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
    >
      <v-card-title class="bg-grey mb-2 fs-1-5r">
        <span class="ma-auto">{{
          type === 'new' ? $t('Create New Casual User') : $t('Edit Casual User')
        }}</span>
      </v-card-title>

      <v-card-text class="pa-2 overflow-auto">
        <!--CASUAL FIRST NAME ON ADD-->
        <div v-if="type === 'new'" class="d-flex my-2">
          <v-text-field
            v-model.trim="shownCasual.firstName"
            :label="$t('First Name')"
            class="mr-2 required"
            dense
          ></v-text-field>

          <!--CASUAL LAST NAME ON ADD-->
          <v-text-field
            v-model.trim="shownCasual.lastName"
            class="required"
            :label="$t('Last Name')"
            dense
          ></v-text-field>
        </div>

        <!--CASUAL FULL NAME ON EDIT-->
        <v-text-field
          v-else
          v-model.trim="shownCasual.name"
          class="required my-2"
          :label="$t('Name')"
          dense
        ></v-text-field>

        <!--CASUAL EMAIL -->
        <v-text-field
          v-model.trim="shownCasual.email"
          :rules="casualUserEmailRules"
          type="email"
          :label="$t('Username (Email)')"
          class="required"
          :disabled="type === 'edit'"
          dense
          validate-on-blur
        ></v-text-field>

        <div class="d-flex flex-wrap">
          <!--CASUAL PHONE NUMBER INPUT-->
          <PhoneNumberInput
            :parentObject="shownCasual"
            class="ma-2"
            :placeholder="$t('Enter phone number')"
          />

          <!--CASUAL LICENSES-->
          <div class="py-1 ma-2">
            <v-checkbox
              v-model="shownCasual.isAllowSend"
              :label="$t('Allow send')"
              class="pa-0 ma-0"
              dense
              hide-details
            >
            </v-checkbox>
            <v-checkbox
              v-model="shownCasual.isAllowReceive"
              :label="$t('Allow receive')"
              class="pa-0 ma-0"
              dense
              hide-details
            >
            </v-checkbox>
          </div>
        </div>

        <!--BIND TO REGISTERED-->
        <div class="d-flex align-center my-2">
          <span class="mx-2">{{ $t('Registered user creator') }}: </span
          ><v-text-field
            v-model="shownCasual.addedBy.email"
            disabled
            hide-details
            dense
            class="ma-0 mx-2 pa-0"
          >
          </v-text-field>
        </div>
      </v-card-text>

      <div class="d-flex justify-end">
        <Button
          v-if="isShowArchiveCasualUserButton"
          :text="$t('Archive Casual User')"
          class="ma-2"
          btnType="grey"
          btnSize="small"
          :icon="mdiAccountOff"
          :clickAction="archiveCasualAccount"
        />
      </div>

      <!--ACTIONS-->
      <v-divider class="my-1" />

      <!--FOOTER-->
      <div class="pa-2">
        <!--BUTTONS-->
        <div class="d-flex justify-end fill-width">
          <!--MESSAGE WHY SAVE BUTTON IS DISABLED-->
          <div class="h-18 red--text mx-2 text-end fs-1r font-weight-light">
            <Transition name="slide-fade">
              <span v-show="isShowDisabledMessage">
                {{ messageWhySaveButtonDisabled }}
              </span>
            </Transition>
          </div>

          <!--ADD / SAVE CASUAL USER-->
          <div
            @mouseover="isShowDisabledMessage = true"
            @mouseleave="isShowDisabledMessage = false"
          >
            <!--CREATE ON ADD CASUAL-->
            <Button
              v-if="type === 'new'"
              :text="$t('Create')"
              :clickAction="() => addNewCasualUser(shownCasual)"
              :disabled="isSaveButtonDisabled"
              width="100"
              class="mx-2"
            />

            <!--SAVE ON EDIT CASUAL-->
            <Button
              v-else
              :text="$t('Save')"
              :clickAction="() => editCasualUser(shownCasual)"
              :disabled="isSaveButtonDisabled"
              width="100"
              class="mx-2"
            />
          </div>

          <!--CANCEL -->
          <Button
            :clickAction="closeClicked"
            :text="$t('Cancel')"
            btnType="grey"
            width="100"
            class="mx-2"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { confirmDialog, validateEmail } from '@/utils'
import { mapGetters } from 'vuex'
import { mdiAccountOff } from '@mdi/js'

//Base Components
import Button from '@/components/BaseComponents/Button/Button.vue'
import PhoneNumberInput from '@/components/BaseComponents/PhoneNumberInput/PhoneNumberInput.vue'

export default {
  name: 'AddCasualDialog',
  data() {
    return {
      mdiAccountOff,
      dialog: true,
      isEdit: false,
      isSaveButtonDisabled: false,
      messageWhySaveButtonDisabled: '',
      isShowDisabledMessage: false,
      usedArchivedCasuals: [],
      shownCasual: {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        isAllowReceive: true,
        isAllowSend: true,
        organizationId: 0,
        boundedUsers: [],
        addedBy: {},
      },
      type: null,
      freezedCasual: {},
    }
  },
  props: {
    editedCasual: Object,
    closeAddEditCasual: Function,
    addNewCasualUser: Function,
    archivedCasuals: Array,
    editCasualUser: Function,
  },
  watch: {
    shownCasual: {
      handler: function (n) {
        this.checkCasualUser()
      },
      deep: true,
      immediate: true,
    },
  },
  components: { PhoneNumberInput, Button },
  computed: {
    ...mapGetters(['loggedUser', 'isDarkMode', 'language']),
    casualUserEmailRules(v) {
      const vue = this
      return [
        v => !!validateEmail(v) || 'Email (Username) must be valid',
        v =>
          !vue.usedArchivedCasuals.includes(v) ||
          'Archived user with that email (Username) already exists',
      ]
    },
    isShowArchiveCasualUserButton() {
      if (this.type === 'new') return false
      if (this.shownCasual.addedBy.userId === this.loggedUser.userId)
        return true
      return false
    },
  },
  methods: {
    archiveCasualAccount() {
      const thenFunc = async () => {
        const newObj = {
          ...this.shownCasual,
          isArchived: true,
          isActive: false,
          addedBy: {
            email: '',
            userId: 0,
            name: '',
          },
          boundedUsers: [],
        }

        this.editCasualUser(newObj)
      }

      let text = ''
      //if there are more than one casual users or if the bounded one is not this logged user
      if (
        this.shownCasual.boundedUsers.length > 1 ||
        (this.shownCasual.boundedUsers.length === 1 &&
          this.shownCasual.boundedUsers[0].userId !== this.loggedUser.userId)
      )
        text = this.$t(`This casual user has more than one bounded user. If you archive him, the other users will not be able to transfer files with him. Are you sure you want to continue`) +'?'
      //if the casual user bounded only to a one user
      else
        text =
          this.$t('Are you sure you want to archive this casual user') + '?'

      confirmDialog(this, text, this.$t('Archive'), this.$t('Cancel'), thenFunc)
    },
    closeClicked() {
      //if the user is editing ask if he is sure he want to save the changes
      if (this.isEdit) {
        const thenFunc = async () => this.closeAddEditCasual()

        const text =
          this.$t(
            'You have unsaved changes. Are you sure you want to leave this page without saving'
          ) + '?'
        confirmDialog(
          this,
          text,
          this.$t('Leave Without Saving'),
          this.$t('Cancel'),
          thenFunc
        )
      }
      //
      else this.closeAddEditCasual()
    },
    checkCasualUser() {
      //function to check if the casual user is valid
      this.isSaveButtonDisabled = true
      if (!this.type) return

      const freezedJSON = JSON.stringify(this.freezedCasual)
      const casualJSON = JSON.stringify(this.shownCasual)

      //if there are no changes
      if (freezedJSON === casualJSON) {
        this.isEdit = false
        return
      }
      //if there are changes
      else {
        this.isEdit = true
      }

      this.messageWhySaveButtonDisabled = ''

      //in NEW first name + last name
      if (this.type === 'new') {
        //if there is no first name
        if (!this.shownCasual.firstName) {
          return (this.messageWhySaveButtonDisabled = this.$t(
            'First name is required'
          ))
        }
        //if there is no last name
        else if (!this.shownCasual.lastName) {
          return (this.messageWhySaveButtonDisabled = this.$t(
            'Last name is required'
          ))
        }
      }

      //in EDIT - full name
      else {
        if (!this.shownCasual.name) {
          return (this.messageWhySaveButtonDisabled =
            this.$t('Name is required'))
        }
      }

      // if there is no email
      if (!this.shownCasual.email) {
        return (this.messageWhySaveButtonDisabled = this.$t(
          'Email (Username) is required'
        ))
      }
      //if the email is not valid
      else if (!validateEmail(this.shownCasual.email)) {
        return (this.messageWhySaveButtonDisabled = this.$t(
          'Email (Username) must be valid'
        ))
      }

      // if there is no phone number or it is not valid
      if (
        this.shownCasual.phoneNumber.length &&
        this.shownCasual.phoneNumber.length < 7 &&
        !this.shownCasual.phoneNumber.includes('+')
      ) {
        return (this.messageWhySaveButtonDisabled = this.$t(
          'Phone number must be valid'
        ))
      }
      this.isSaveButtonDisabled = false
      this.messageWhySaveButtonDisabled = ''
    },
  },
  created() {
    //if there is edited user then the type is edit
    if (this.editedCasual) {
      this.type = 'edit'
      this.shownCasual = { ...this.editedCasual }
      this.freezedCasual = JSON.parse(JSON.stringify(this.editedCasual))
    }

    //if there is no edit user then it is new
    else {
      const loggedUser = {
        email: this.loggedUser.email,
        userId: this.loggedUser.userId,
        name: this.loggedUser.name,
      }
      this.shownCasual.boundedUsers.push(loggedUser)
      this.shownCasual.addedBy = loggedUser
      this.shownCasual.organizationId = this.loggedUser.organizationId

      this.freezedCasual = JSON.parse(JSON.stringify(this.shownCasual))

      this.usedArchivedCasuals = this.archivedCasuals.map(el => el.email)

      this.type = 'new'
    }
  },
}
</script>
