var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper-1 uploading-details ma-2 bg-white",class:[
    _vm.isShowUploadingDetails
      ? 'uploading-details-open'
      : 'uploading-details-close',

    _vm.isShowUploadingDetails && _vm.session.filesData.length && 'wrapper-hard',
  ]},[_c('UploadingDetailsHeader',{attrs:{"isShowUploadingDetails":_vm.isShowUploadingDetails,"toggleIsShowUpDetails":v => (_vm.isShowUploadingDetails = !_vm.isShowUploadingDetails)}}),_c('Transition',{attrs:{"name":"hide"}},[(_vm.isShowUploadingDetails)?_c('div',{staticClass:"my-2 pa-2"},[_c('UploadingDetailsFields',{attrs:{"session":_vm.session,"errors":_vm.errors,"userActiveSourceRoutes":_vm.userActiveSourceRoutes,"lightSessionRestart":_vm.lightSessionRestart}}),_c('div',{staticClass:"wrapper-1 pa-2 my-2 mb-10"},[_c('FileDirButtons',{attrs:{"session":_vm.session,"lightSessionRestart":_vm.lightSessionRestart}})],1)],1):_vm._e()]),(_vm.isShowStartButton)?_c('div',{staticClass:"start-restart-button",class:[_vm.language === 'he' && 'start-restart-button-rtl'],on:{"click":_vm.startFilteringButtonClicked}},[_c('Button',{attrs:{"text":_vm.$t('Start'),"clickAction":_vm.startFilteringButtonClicked,"disabled":!!_vm.filteringErrMessage,"width":"150"}})],1):_vm._e(),(_vm.isShowRestartButton)?_c('Button',{staticClass:"start-restart-button",attrs:{"clickAction":_vm.restartSessionClicked,"text":_vm.$t('Additional Upload'),"icon":"mdi-cached","width":"180"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }