<template>
  <div class="pa-2">
    <h4 class="settings-h4">Casual Accounts</h4>
    <div class="wrapper-2 pa-4 mb-4 pos-relative">
      <!--REQUIE TWO STEP AUTH-->
      <v-checkbox
        v-model="organization.casualUsersDefinitions.isTwoStep"
        :disabled="
          !organization.casualUsersDefinitions.isActive ||
          organization.status !== 0
        "
        class="pa-0 ma-0 mb-2 mw-200"
        label="Email verification"
        hide-details
      ></v-checkbox>

      <CasualLanguage :organization="organization" />

      <!--DURATION - FILES LIFE TIME -->
      <CasualFilesLifeTime :organization="organization" />

      <!--POLICIES -->
      <span
        class="light-text"
        :class="[
          (!organization.casualUsersDefinitions.isActive ||
            organization.status !== 0) &&
            'low-op-disabeld',
        ]"
      >
        Policies:
      </span>
      <div class="d-flex mt-2">
        <!--POLICY FOR FILES GET IN -->
        <v-select
          v-model="organization.casualUsersDefinitions.policyIn"
          :items="computedPolicies"
          :disabled="
            !organization.casualUsersDefinitions.isActive ||
            organization.status !== 0
          "
          class="px-0 mx-3 py-0"
          label="Incoming Files"
          style="max-width: 200px"
          hide-details
        >
        </v-select>
        <!--POLICY FOR FILES GET OUT -->
        <v-select
          v-model="organization.casualUsersDefinitions.policyOut"
          :items="computedPolicies"
          :disabled="
            !organization.casualUsersDefinitions.isActive ||
            organization.status !== 0
          "
          class="px-0 mx-3 py-0"
          label="Outgoing Files"
          style="max-width: 200px"
          hide-details
        >
        </v-select>
      </div>

      <OnlineHelp
        page="settings"
        section="casualSettings"
        title="Casual Accounts"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

//Base components
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'
import CasualFilesLifeTime from './Childrens/CasualFilesLifeTime/CasualFilesLifeTime.vue'
import CasualLanguage from './Childrens/CasualLanguage/CasualLanguage.vue'

export default {
  name: 'CasualUserSettings',
  props: {
    organization: Object,
    loggedUser: Object,
    actionType: String,
  },
  computed: {
    ...mapGetters(['policies']),
    computedPolicies() {
      if (this.actionType === 'edit') return this.policies
      return []
    },
  },
  methods: {
    ...mapActions(['GET_POLICIES']),
  },
  async created() {
    await this.GET_POLICIES(this.organization.organizationId)
  },
  components: { OnlineHelp, CasualFilesLifeTime, CasualLanguage },
}
</script>
