<template>
  <div class="wrapper-1 pa-2 mt-2">
    <h4 class="settings-h4">Advanced Settings</h4>
    <!--FIRST ROW-->
    <div class="d-flex align-center mt-2 mb-4">
      <!--SEARCH BY USERS IN THIS ORGANIZATION-->
      <v-autocomplete
        v-model="search.userEmail"
        :items="organizationUsersList"
        :disabled="!organizationUsersList.length"
        clearable
        label="Account"
        class="mw-400 mx-2"
        dense
        hide-details
      >
        <template v-slot:item="{ item }"
          ><span class="f-16">{{ item }}</span></template
        >
      </v-autocomplete>

      <!-- FREE TEXT -->
      <v-text-field
        v-model.trim="search.freeText"
        label="Free Text"
        class="mw-400 mx-2"
        dense
        hide-details
      ></v-text-field>

      <!-- OPERATION ID SEARCH -->
      <v-text-field
        v-model.trim="search.operationId"
        label="Session S/N"
        class="mw-400 mx-2"
        dense
        hide-details
      ></v-text-field>
    </div>

    <!--SECOND ROW-->
    <div class="d-flex mt-2">
      <!--CHECKBOXS FOR WEIRD SITUATIONS-->
      <div class="wrapper-1 px-2 py-1 mx-2">
        <span class="label f-14">Search in logs for:</span>
        <!--MANAGEMENT LOGS-->

        <div :class="[loggedUser.role === 'SuperAdmin' && 'd-flex']">
          <div class="mx-1">
            <v-checkbox
              v-model="search.isManagement"
              label="Management"
              class="ma-0 pa-0"
              hide-details
            ></v-checkbox>
            <!--OPERATIONS LOGS-->
            <v-checkbox
              v-model="search.isOperations"
              label="Operation"
              class="ma-0 pa-0"
              hide-details
            ></v-checkbox>
          </div>
          <div class="mx-1">
            <!--INCLUDE SUCCESSFUL LOGIN LOGS-->
            <v-checkbox
              v-model="search.isSuccessAuthLogs"
              label="Success login"
              class="ma-0 pa-0"
              hide-details
            >
            </v-checkbox>

            <!--INCLUDE HACKING ATTTEMPTS-->
            <v-checkbox
              v-if="loggedUser.role === 'SuperAdmin'"
              v-model="search.isIncludeHackingAttempts"
              label="Failure login"
              class="ma-0 pa-0"
              hide-details
            >
            </v-checkbox>
          </div>
        </div>
      </div>

      <!--DATE FORMATS-->
      <div class="wrapper-1 py-1 px-2 mx-2">
        <span class="label f-14">Time format:</span>
        <v-radio-group
          v-model="logsSettings.dateFormat"
          class="pa-0 ma-0"
          hide-details
        >
          <v-radio value="Local" label="Local" class="pa-0 ma-0 my-1"></v-radio>
          <v-radio value="UTC" label="UTC" class="pa-0 ma-0 my-1"></v-radio>
        </v-radio-group>
      </div>

      <!--TABLE HEADERS-->
      <div class="wrapper-1 px-2 py-1 mx-2 w-370 h-110">
        <span class="label f-14">Table headers:</span>
        <div class="d-flex flex-wrap">
          <v-checkbox
            v-for="log of computedTableHeaders"
            v-model="logsTableHeaders[log.model]"
            :key="log.model"
            :label="log.label"
            class="ma-0 pa-0"
            style="width: 170px"
            hide-details
          ></v-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdvancedSettings',

  data() {
    return {
      tableHeadersSuper: [
        { model: 'checkbox', label: 'Checkbox marker' },
        { model: 'message', label: 'Message' },
        { model: 'date', label: 'Date' },
        { model: 'id', label: 'log S/N' },
        { model: 'host', label: 'Host' },
        { model: 'moreDataOfLog', label: 'More about' },
        { model: 'account', label: 'Sender account' },
      ],
      tableHeaders: [
        { model: 'checkbox', label: 'Checkbox marker' },
        { model: 'message', label: 'Message' },
        { model: 'date', label: 'Date' },
        { model: 'id', label: 'log S/N' },
        { model: 'account', label: 'Sender account' },
        { model: 'moreDataOfLog', label: 'More about' },
      ],
    }
  },
  props: {
    search: Object,
    organizationUsersList: Array,
    logsSettings: Object,
    logsTableHeaders: Object,
  },

  computed: {
    ...mapGetters(['loggedUser']),
    computedTableHeaders() {
      if (this.loggedUser.role === 'SuperAdmin') return this.tableHeadersSuper
      return this.tableHeaders
    },
  },
}
</script>
