<template>
  <div class="pa-2 mb-2">
    <h4 class="settings-h4">Casual Accounts</h4>
    <div class="wrapper-2 px-2 py-2 pos-relative">
      <!--SHOW HOW MUCH-->
      <div class="d-flex justify-space-between align-center">
        <div class="d-flex light-text">
          <span>Allow managing</span>
          <v-text-field
            v-model="profile.numOfAllowedCasuals"
            :disabled="isFieldDisabled"
            :error="isNumOfAllowedError"
            class="pa-0 ma-0 px-2 mw-50 centered-input"
            hide-details
            type="number"
            dense
            height="20"
          ></v-text-field>
          casual accounts

          <span
            class="f-12 mx-2"
            :class="[
              numOfHostCasualUsersTotalLicenes > casualLicense.numOfAllowed &&
                'red--text',
            ]"
            >(Host licenses usage:
            {{
              numOfHostCasualUsersTotalLicenes +
              '/' +
              casualLicense.numOfAllowed
            }})</span
          >
        </div>

        <!--BUTTON TO SHOW CASUAL USERS TABLE-->
        <Button
          :text="
            isCasualUsersTableOpen ? 'Hide Casuals Table' : 'Show Casuals Table'
          "
          width="230px"
          :icon="isCasualUsersTableOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          btnType="grey"
          class="mx-2 mr-8"
          :clickAction="
            () => (isCasualUsersTableOpen = !isCasualUsersTableOpen)
          "
        />
      </div>

      <!--TABLE TO SHOW THE USER'S CASUAL USERS -->
      <Transition name="slide-fade">
        <v-data-table
          v-show="isCasualUsersTableOpen"
          :headers="regularUserCasualUsersTableHeaders"
          :items="casualUsers"
          class="wrapper-2 my-2 mb-8"
          :options="{ sortBy: ['casualUserId'] }"
          must-sort
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:item.isBounded="{ item }">
            <v-simple-checkbox
              :disabled="isFieldDisabled"
              :value="item.isBounded"
              @input="boundedCasualsUsersCheckboxClicked(item, $event)"
              class="mt-1"
            >
            </v-simple-checkbox>
          </template>

          <template v-slot:item.casualUserId="{ item }">{{
            item.userId | makeID
          }}</template>
        </v-data-table>
      </Transition>
      <OnlineHelp page="singleUser" section="casual" title="Casual Accounts" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { api } from '@/config'
import { convertID } from '@/utils'
import { mapGetters, mapMutations } from 'vuex'

//Components files
import { regularUserCasualUsersTableHeaders } from './CasualUsers'
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'CasualUsers',
  data() {
    return {
      regularUserCasualUsersTableHeaders,
      casualUsers: [],
      isNumOfAllowedError: false,
      isCasualUsersTableOpen: false,
    }
  },
  props: {
    profile: Object,
    isFieldDisabled: Boolean,
    casualLicense: Object,
    numOfHostCasualUsersTotalLicenes: Number,
  },
  watch: {
    'profile.numOfAllowedCasuals': {
      handler: function (n) {
        this.isNumOfAllowedError = false
        //if there are casual licenses to the user
        if (n > 0) {
          if (
            n === '' ||
            this.numOfHostCasualUsersTotalLicenes >
              this.casualLicense.numOfAllowed
          )
            this.isNumOfAllowedError = true
          return
        }
      },
      deep: true,
    },
  },
  filters: {
    makeID(val) {
      return convertID(val, 'C')
    },
  },
  computed: {
    ...mapGetters(['currentOrganization']),
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),
    async boundedCasualsUsersCheckboxClicked(user, e) {
      //change in the ui
      this.casualUsers = this.casualUsers.map(casual => {
        if (casual.userId === user.userId) {
          const newCasual = {
            ...casual,
            isBounded: e,
          }
          const { name, email, userId } = this.profile
          //if the added the casual to the reg user
          if (e)
            newCasual.boundedUsers = [
              ...casual.boundedUsers,
              { name, email, userId },
            ]
          //if the admin removed the casual from reg user
          else {
            newCasual.boundedUsers = newCasual.boundedUsers.filter(
              regUser => regUser.userId !== this.profile.userId
            )
          }
          return newCasual
        }
        return casual
      })
      //if the user added a casual to registered
      if (e) {
        this.profile.boundedCasuals.push({
          name: user.name,
          email: user.email,
          userId: user.userId,
        })
      }
      //if the user removed casual from registered
      else {
        this.profile.boundedCasuals = this.profile.boundedCasuals.filter(
          casual => casual.userId !== user.userId
        )
      }
    },
  },
  async created() {
    try {
      const { organizationId } = this.$route.params
      const res = await api.get(
        `users/casual/${
          this.currentOrganization.organizationId ||
          organizationId ||
          this.loggedUser.organizationId
        }`
      )
      if (res.status !== 200) throw Error
      this.casualUsers = res.data.casualUsers
        .map(casual => {
          const {
            name,
            casualUserId,
            email,
            boundedUsers,
            addedBy,
            isArchived,
          } = casual
          if (isArchived) return undefined
          return {
            name,
            boundedUsers,
            userId: casualUserId,
            addedBy,
            email,
            isBounded: this.profile.boundedCasuals.some(
              el => casual.casualUserId === el.userId
            ),
          }
        })
        .filter(el => el !== undefined)
    } catch (e) {
      console.log(e)
    } finally {
    }
  },
  components: { Button, OnlineHelp },
}
</script>
