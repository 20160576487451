import Axios from 'axios'
import store from './store'
import { i18n } from './main'
import { convertToLocalTime, extractAttemptsLeft } from './utils'

export const hostList = {
  devStaging: 'https://cyber-leader.com',
  testStaging: 'https://bestcdr.com',
  prodStaging: 'https://dotengines.com',
  davidLocal: 'http://localhost:8080',
  relative: '',
}

export const selectedHost = hostList.relative

export const api = Axios.create({
  baseURL: `${selectedHost}/api`,
  validateStatus: status => true,
  timeout: 360000,
})
api.interceptors.response.use(
  response => {
    //canceling the error message for the 404 if the use doesn't have any files to download
    if (
      response.status === 404 &&
      response.request.responseURL.includes('/filter/directory')
    )
      return response
    if (response.status >= 400) {
      let message = decodeURIComponent(response.headers['reason-phrase'])

      if (response.status === 401 && message.includes('Wrong password')) {
        const attemptsLeft = extractAttemptsLeft(message)
        message =
          i18n.t('Wrong password. Attempts left') + ': ' + attemptsLeft + '.'
      }

      // if the user is blocked, show a different message
      if (
        response.status === 401 &&
        message === 'User is temporarily blocked'
      ) {
        const localTime = convertToLocalTime(response.data.unblockingDate)

        message =
          i18n.t(
            'This user is under temporary block. The block will be lifted at'
          ) +
          ' ' +
          localTime
      }
      if (message) store.commit('SET_SERVER_ERROR_NOTIFICATION', message)
    }
    return response
  },
  error => {
    // whatever you want to do with the error
    throw error
  }
)
