<template>
  <div class="wrapper-1 w-50pr pa-3 mb-5 mr-1 ">
    <div class="d-flex justify-space-between pos-relative">
      <h3 class="ma-0 pa-0 mb-1 fs-1-25r">Senders ({{route.sourceEndPoints.length}})</h3>
      <div>
        <OnlineHelp page="routes" section="sourceEndPoints" title="Senders" />
      </div>
    </div>
    <v-divider class="mb-1" />
    <div class="d-flex mb-3">
      <!--INPUT FOR USERS EMAIL AND NAME-->
      <v-autocomplete
        v-model="route.sourceEndPoints"
        :items="computedSourceUsers"
        :disabled="isEditDisabled"
        :item-disabled="item => item.userStatus === 1"
        :class="[actionType === 'add' && 'required']"
        :menu-props="customeMenuProps"
        multiple
        placeholder="Search"
        item-text="userEmail"
        return-object
        hide-details
      >
        <template v-slot:selection><span></span></template>

        <template v-slot:prepend-inner>
          <v-icon class="mt-1" dense>mdi-magnify</v-icon></template
        >
      </v-autocomplete>
    </div>
    <!--SHOW THE SOURCE END POINTS -->
    <div v-if="route.sourceEndPoints && route.sourceEndPoints.length">
      <div
        v-for="userEndPoint in route.sourceEndPoints"
        :key="userEndPoint.userId + 's'"
      >
        <SourceOrTargetItem
          type="source"
          :userEndPoint="userEndPoint"
          :routeStatus="route.status"
          :actionType="actionType"
          :isEditDisabled="isEditDisabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
//"Base component" - this component is also shown in source end point
import SourceOrTargetItem from '../SourceOrTargetItem/SourceOrTargetItem.vue'

//Base components
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'SourceEndPoints',
  props: {
    route: Object,
    computedUsers: Array,
    isEditDisabled: Boolean,
    actionType: String,
    freezedRoute: Object,
  },
  components: { SourceOrTargetItem, OnlineHelp },

  computed: {
    customeMenuProps() {
      const { height } = this.$vuetify.breakpoint
      if (height <= 750) return {}
      else if (height > 750 && height < 900) return { maxHeight: '400px' }
      return { maxHeight: '500px' }
    },
    computedSourceUsers() {
      const sources = JSON.parse(JSON.stringify(this.computedUsers))
      return sources
        .filter(el => el.license)
        .sort((a, b) => {
          if (a.userEmail.toLowerCase() < b.userEmail.toLowerCase()) return -1
          if (a.userEmail.toLowerCase() > b.userEmail.toLowerCase()) return 1
          return 0
        })
    },
  },
}
</script>
