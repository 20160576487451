<template>
  <div class="wrapper-1 pa-2 ma-1 fs-1-25r">
    <div :class="[isSmallScreen ? '' : 'd-flex justify-space-between']">
      <div :class="[isSmallScreen ? '' : 'w-50pr', language === 'he' ? 'dir-rtl' : 'dir-ltr'] " >
        <!--ASSOCIATE-->
        <v-checkbox
          v-if="type === 'active'"
          :input-value="caculateIsBound(casual)"
          label="Associate"
          @change="isBoundChanged($event, casual)"
          hide-details
          class="ma-0 pa-0 w-100"
        >
          <template v-slot:label>
            <span class="black--text">{{ $t('Associate') }}</span></template
          >
        </v-checkbox>

        <!--USERNAME-->
        <div class="my-1"><b>{{ $t('Username') }}:</b> {{ casual.email }}</div>

        <!--NAME-->
        <div class="my-1"><b>{{ $t('Name') }}:</b> {{ casual.name }}</div>

        <!--MOBILE-->
        <div class="d-flex align-center my-1 pos-relative">
          <b>{{ $t('Mobile') }}:</b>
          <div class="phone-input-hider"></div>
          <PhoneNumberInput
            v-if="casual.phoneNumber"
            :parentObject="casual"
            :isHideDropdown="true"
            :isHideMobileTitle="true"
            :isHideUnderline="true"
            class="mb-2"
          />
        </div>
      </div>

      <v-divider v-if="!isSmallScreen" vertical class="mx-2" />

      <div :class="[isSmallScreen ? '' : 'w-50pr', language === 'he' ? 'dir-rtl' : 'dir-ltr']"  >
        <!--ASSOCIATED-->
        <div v-if="type === 'active'" class="my-1">
          <b>{{ $t('Associated') }}:</b> {{ casual.boundedUsers.length }}
        </div>

        <!--CREATOR-->
        <div v-if="type === 'active'" class="my-1">
          <b>{{ $t('Creator') }}:</b> {{ casual.addedBy.email }}
        </div>

        <!--ID-->
        <div class="my-1">
          <b>ID:</b> {{ convertID(casual.casualUserId, 'C') }}
        </div>

        <!--DIRECTION-->
        <div v-if="type === 'active'" class="my-1">
          <b>{{ $t('Direction') }}:</b> {{ directions }}
        </div>
      </div>
    </div>

    <v-divider class="my-2"></v-divider>

    <!--BUTTONS-->
    <div class="d-flex justify-end">
      <!--FOR ARCHIVE - ACTIVATE USER -->
      <Button
        v-if="type === 'archived'"
        :clickAction="() => activateCasual(casual)"
        :icon="mdiAccountReactivate"
        :text="$t('Activate')"
        btnType="grey"
      />

      <!--FOR ACTIVE - EDIT USER -->
      <Button
        v-else
        :clickAction="() => setEditedCasual(casual)"
        :text="$t('Edit')"
        icon="mdi-pencil"
        btnType="grey"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { convertID } from '@/utils'
import { mdiAccountReactivate } from '@mdi/js'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import PhoneNumberInput from '@/components/BaseComponents/PhoneNumberInput/PhoneNumberInput.vue'

export default {
  name: 'CasualCardMobile',
  data() {
    return { mdiAccountReactivate }
  },
  props: {
    casual: Object,
    isBoundChanged: Function,
    setEditedCasual: Function,
    activateCasual: Function,
    type: String,
  },
  components: { Button, PhoneNumberInput },

  computed: {
    ...mapGetters(['loggedUser', 'language']),
    directions() {
      if (this.casual.isAllowReceive && this.casual.isAllowSend)
        return this.$t(`Send & Recive`)
      else if (this.casual.isAllowReceive && !this.casual.isAllowSend)
        return  this.$t('Recive')
      else if (!this.casual.isAllowReceive && this.casual.isAllowSend)
        return  this.$t('Send')
      else return  this.$t('None')
    },
    isSmallScreen() {
      if (this.$vuetify.breakpoint.width < 600) return true
      return false
    },
  },
  methods: {
    convertID,
    caculateIsBound(casualUser) {
      for (const regUser of casualUser.boundedUsers) {
        if (regUser.userId === this.loggedUser.userId) return true
      }
      return false
    },
  },
}
</script>
