<template>
  <div id="casual-users" :class="[type !== 'mobile' && 'pos-relative']">
    <v-autocomplete
      v-model="session.selectedCasualUsers"
      :attach="type !== 'mobile' && '#casual-users'"
      @change="lightSessionRestart"
      :items="casualUsersList"
      :disabled="isInMiddleOfFiltering"
      :rules="[v => !!v || 'At least one recipient is required']"
      :error="errors.isCasError"
      label="Casual Recipients"
      multiple
      outlined
      dense
    >
      <!--MAKE THE SELECTION -->
      <template v-slot:selection="{ index }">
        <span v-if="index === 0" class="light-text mx-1">
          <span
            v-if="
              session.selectedCasualUsers.length === 1 &&
              casualUsersList.length === 1
            "
            class="fs-1r"
          >
            {{ session.selectedCasualUsers[0] }}
          </span>
          <span
            v-else-if="
              session.selectedCasualUsers.length === casualUsersList.length
            "
            class="fs-1r"
          >
            {{ $t('All selected') }} ({{ session.selectedCasualUsers.length }})
          </span>
          <span v-else class="fs-1r mx-1">
            {{ session.selectedCasualUsers.length }} {{ $t('of') }}
            {{ casualUsersList.length }} {{ $t('selected') }}
          </span>
        </span>
      </template>

      <!--TO SELECT ALL-->
      <template v-slot:prepend-item>
        <v-list-item @mousedown.prevent @click="selectAllOrSomeUserClicked">
          <v-list-item-action>
            <v-icon
              style="margin-left: 2px !important"
              :color="session.selectedCasualUsers.length > 0 ? 'primary' : ''"
            >
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('Select all') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CasualRecipients',
  data() {
    return {
      casualUsersList: [],
    }
  },
  props: {
    session: Object,
    errors: Object,
    lightSessionRestart: Function,
    type: String,
  },

  computed: {
    ...mapGetters(['loggedUser', 'isInMiddleOfFiltering']),
    //FOR SELECT SOME AND ALL
    isAllCasualUsersSelected() {
      return (
        this.session.selectedCasualUsers.length === this.casualUsersList.length
      )
    },
    someUsersSelected() {
      return (
        this.session.selectedCasualUsers.length > 0 && !this.isAllUsersSelected
      )
    },
    icon() {
      if (this.isAllUsersSelected) return 'mdi-close-box'
      if (this.someUsersSelected) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },

  methods: {
    selectAllOrSomeUserClicked() {
      this.$nextTick(() => {
        if (this.isAllCasualUsersSelected) {
          this.session.selectedCasualUsers = []
        } else {
          this.session.selectedCasualUsers = this.casualUsersList.slice()
        }
      })
    },
  },

  mounted() {
    this.casualUsersList = this.loggedUser.boundedCasuals
      .map(el => el.email)
      .sort((a, b) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1
        if (a.toLowerCase() > b.toLowerCase()) return 1
        return 0
      })
  },
}
</script>
