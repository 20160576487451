<template>
  <tr class="input-row">
    <!--USER EMAIL -->
    <td>
      <v-text-field
        v-model.trim="newUser.email"
        :disabled="currentOrganization.status !== 0"
        :rules="[v => !!validateEmail(v) || 'Email must be valid']"
        @input="cancelWhiteSpace"
        type="email"
        label="Email"
        class="required"
        dense
        validate-on-blur
      ></v-text-field>
    </td>

    <!--USER NAME-->
    <td>
      <!--USER FIRST NAME-->
      <div class="d-flex">
        <v-text-field
          v-model.trim="newUser.firstName"
          :disabled="currentOrganization.status !== 0"
          label="First Name"
          class="mr-2 required"
          dense
        ></v-text-field>

        <!--USER LAST NAME-->
        <v-text-field
          v-model.trim="newUser.lastName"
          :disabled="currentOrganization.status !== 0"
          class="required"
          label="Last Name"
          dense
        ></v-text-field>
      </div>
    </td>

    <!--PHONE NUMBER INPUT-->
    <td>
      <div class="d-flex justify-center align-center">
        <PhoneNumberInput
          :disabled="currentOrganization.status !== 0"
          :parentObject="newUser"
          :isHideDropdown="true"
          class="mb-2"
        />
      </div>
    </td>
    <td></td>
    <td>
      <v-select
        v-model="bindCasualToUser"
        :disabled="currentOrganization.status !== 0"
        :items="computedOrgUsers"
        hide-details
        item-text="email"
        dense
        height="20px"
        return-object
      >
      </v-select>
    </td>
    <td></td>
    <!--CASUAL LICENSES -->
    <td>
      <div class="py-1">
        <v-checkbox
          v-model="newUser.isAllowSend"
          label="Send"
          class="pa-0 ma-0"
          dense
          hide-details
        >
        </v-checkbox>
        <v-checkbox
          v-model="newUser.isAllowReceive"
          label="Receive"
          class="pa-0 ma-0"
          dense
          hide-details
        >
        </v-checkbox>
      </div>
    </td>

    <!--BUTTON TO ADD AND MESSAGE-->
    <td class="pos-relative">
      <div
        @mouseover="isShowDisabledMessage = true"
        @mouseleave="isShowDisabledMessage = false"
        class="d-flex justify-center"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="addNewUser"
              :disabled="isSaveButtonDisabled"
              x-large
              v-on="on"
              text
              icon
            >
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </template>
          <span>Add account</span>
        </v-tooltip>
      </div>
      <Transition name="slide-fade">
        <span
          v-if="isShowDisabledMessage && isNewUserAddedTimeout"
          class="red--text disabled-message fs-1r"
          >{{ messageWhySaveButtonDisabled }}</span
        >
      </Transition>
    </td>
  </tr>
</template>

<script>
import { alertDialog, validateEmail } from '@/utils'
import { mapMutations, mapGetters } from 'vuex'

//Base components
import PhoneNumberInput from '@/components/BaseComponents/PhoneNumberInput/PhoneNumberInput.vue'

export default {
  name: 'InputRow',
  data() {
    return {
      validateEmail,
      isShowDisabledMessage: false,
      isSaveButtonDisabled: false,
      messageWhySaveButtonDisabled: '',
      isNewUserAddedTimeout: true, //stupid var to cancel the showed message when user hover
      bindCasualToUser: {
        userId: 0,
        name: '',
        email: '',
      },
      newUser: {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        isAllowSend: true,
        isAllowReceive: true,
      },
    }
  },
  props: {
    activeCasuals: Array,
    archivedCasuals: Array,
    changes: Object,
    addCasualToTable: Function,
    computedOrgUsers: Array,
    isOrganizationHaveEnogthCasualLicenses: Boolean,
  },
  components: { PhoneNumberInput },
  watch: {
    newUser: {
      handler: function (n) {
        //if the user edited
        if (n.email || n.firstName || n.lastName || n.phoneNumber) {
          this.SET_IS_IN_MIDDLE_OF_EDITING(true)
        } else {
          const baseChangesObj = `{"registeredLicenses":[],"casuals":{"updatedCasuals":[],"deletedCasuals":[]}}`
          if (this.changes == baseChangesObj)
            this.SET_IS_IN_MIDDLE_OF_EDITING(false)
        }
        this.checkCasualUser()
      },
      deep: true,
      immediate: true,
    },
    isNewUserAddedTimeout(n) {
      if (n === false) {
        setTimeout(() => {
          this.isNewUserAddedTimeout = true
        }, 1000)
      }
    },
    bindCasualToUser: {
      handler: function (n) {
        this.checkCasualUser()
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['currentOrganization', 'loggedUser', 'users']),
  },
  methods: {
    ...mapMutations(['SET_IS_IN_MIDDLE_OF_EDITING', 'SET_USERS']),
    cancelWhiteSpace(e) {
      if (e) {
        this.newUser.email = this.newUser.email.replace(' ', '')
      }
    },

    async addNewUser() {
      // check if the email is not used by the active casuals
      const usedEmails = []
      let message = false
      this.users.forEach(regular => {
        usedEmails.push(regular.email)
        if (this.activeCasuals.length) {
          this.activeCasuals.forEach(casual => {
            usedEmails.push(casual.email)
          })
        }
      })

      if (usedEmails.includes(this.newUser.email))
        message =
          'Casual or Registered account with a similar email already exists'

      //if the casual user is in the archived casuals
      if (this.archivedCasuals.some(cas => cas.email === this.newUser.email))
        message = 'Arcived user with that email already exists'

      if (message) return alertDialog(this, message)

      const newUser = {
        email: this.newUser.email,
        phoneNumber: this.newUser.phoneNumber,
        name: this.newUser.firstName + ' ' + this.newUser.lastName,
        organizationId: this.currentOrganization.organizationId,
        boundedUsers: [],
        isAllowSend: this.newUser.isAllowSend,
        isAllowReceive: this.newUser.isAllowReceive,
        isArchived: false,
      }

      //add the registered by selected
      newUser.addedBy = this.bindCasualToUser

      //bound the user to the casual
      newUser.boundedUsers.push(this.bindCasualToUser)

      const newUsers = this.users.map(user => {
        if (user.userId === newUser.addedBy.userId) {
          const { name, email } = newUser
          const newObj = {
            ...user,
          }
          newObj.boundedCasuals = [...newObj.boundedCasuals, { name, email }]
          return newObj
        }
        return user
      })
      this.SET_USERS(newUsers)
      this.changes.casuals.updatedCasuals.push(newUser)
      this.addCasualToTable(newUser)
      this.newUser = {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        isAllowSend: true,
        isAllowReceive: true,
      }

      if (this.loggedUser.role !== 'SuperAdmin') {
        const { email, userId, name } = this.loggedUser

        this.bindCasualToUser = {
          email,
          userId,
          name,
        }
      } else {
        this.bindCasualToUser = {
          email: '',
          userId: 0,
          name: '',
        }
      }
      this.isNewUserAddedTimeout = false
    },

    checkCasualUser() {
      //function to check if the casual user is valid
      this.isSaveButtonDisabled = true
      this.messageWhySaveButtonDisabled = ''

      // if there is no name
      if (!this.newUser.email) {
        return (this.messageWhySaveButtonDisabled = 'Email is required')
      }

      //if the email is not valid
      else if (!validateEmail(this.newUser.email)) {
        return (this.messageWhySaveButtonDisabled = 'Email must be valid')
      }
      //if there is no first name
      else if (!this.newUser.firstName) {
        return (this.messageWhySaveButtonDisabled = 'First name is required')
      }
      //if there is no last name
      else if (!this.newUser.lastName) {
        return (this.messageWhySaveButtonDisabled = 'Last name is required')
      }
      // if there is no phone number or it is not valid
      else if (
        this.newUser.phoneNumber.length &&
        this.newUser.phoneNumber.length < 7 &&
        !this.newUser.phoneNumber.includes('+')
      ) {
        return (this.messageWhySaveButtonDisabled =
          'Phone number must be valid')
      }

      //if there is no casual cerated by
      if (!this.bindCasualToUser.userId) {
        return (this.messageWhySaveButtonDisabled = 'The demanding is missing')
      }

      //if the user that bounded don't have enough licenses :
      const selectedUser = this.users.find(
        user => user.userId === this.bindCasualToUser.userId
      )

      let counterUserCasuals = 0
      this.activeCasuals.forEach(casual =>
        casual.addedBy.userId === selectedUser.userId
          ? counterUserCasuals++
          : ''
      )

      if (counterUserCasuals + 1 > +selectedUser.numOfAllowedCasuals) {
        return (this.messageWhySaveButtonDisabled = `Account dosen't have enough licenses`)
      }

      this.isSaveButtonDisabled = false
      this.messageWhySaveButtonDisabled = ''
    },
  },
}
</script>

<style scoped src="./InputRow.css"></style>

<style src="./InputRowUnScoped.css"></style>
