export const HostCasualUsersTableHeaders = [
  {
    text: 'Name',
    value: 'name',
    align: 'start',
    class: 'bg-table-heading table-header-first',
    width: '220px',
  },
  {
    text: 'Username',
    value: 'email',
    align: 'start',
    class: 'bg-table-heading',
    width: '200px',
  },
  {
    text: 'Mobile',
    value: 'phoneNumber',
    align: 'center',
    sortable: false,
    width: '260px',
    class: 'bg-table-heading',
  },

  {
    text: 'Associated',
    // value: '',
    align: 'center',
    sortable: false,
    width: '70px',
    class: 'bg-table-heading',
  },
  {
    text: 'Creator',
    value: 'addedBy.email',
    align: 'center',
    width: '200px',
    class: 'bg-table-heading',
  },
  {
    text: 'S/N',
    value: 'casualUserId',
    align: 'center',
    class: 'bg-table-heading',
    width: '60px',
  },
  {
    text: 'License',
    value: '',
    align: 'center',
    width: '100px',
    class: 'bg-table-heading',
  },
  {
    text: '',
    value: 'actions',
    align: 'center',
    sortable: false,
    width: '100px',
    class: 'bg-table-heading table-header-last',
  },
]
